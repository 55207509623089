import { combineReducers } from "redux";

import user from "./user/reducers";
import general from "./general/reducers";
import account from "./account/reducers";
import apikey from "./apikey/reducers";
import register from "./register/reducers";
import billing from "./billing/reducers";
import transfer from "./transfer/reducers";
import deposit from "./deposit/reducers";
import myclient from "./myclient/reducers";
import supplier from "./supplier/reducers";
import transaction from "./transaction/reducers";
import paybill from "./paybill/reducers";
import nf from "./nf/reducers";
import employee from "./employee/reducers";
import invite from "./invite/reducers";
import pix from "./pix/reducers";
import company from "./company/reducers";
import crypto from "./crypto/reducers";
import invoice from './invoice/reducers'

const reducers = combineReducers({
  user,
  general,
  account,
  apikey,
  register,
  billing,
  transfer,
  myclient,
  deposit,
  supplier,
  transaction,
  paybill,
  nf,
  employee,
  pix,
  invite,
  company,
  crypto,
  invoice
});

export default reducers;
