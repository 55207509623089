import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { InputMask } from "../../Inputs";
import { formatToDecimal, formatToReal, formatToRealDolar } from "../../../utils/format";
import { Formik } from "formik";
import * as Yup from "yup";

import Carousel from "react-elastic-carousel";
import { ContainerPage, ContainerPagination } from "../ClientCreate/styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { ContainerButtons, Form, SelectArea } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import DisplayInfos from "../../DisplayInfo";
import * as CryptoActions from "../../../store/modules/crypto/actions";
import ProgressBar from "react-bootstrap/ProgressBar";
import Alert from "react-bootstrap/Alert";
import { pt } from 'yup-locale-pt';
import * as GeneralActions from "../../../store/modules/general/actions";
import { useFormik } from 'formik';
Yup.setLocale(pt);


const buyValidSchema = Yup.object().shape({
  // amount: Yup.number().required().positive(),
  // total: Yup.number().required().positive(),
});



const initialErrors = {
  amount: "-",
  currency: "-",
};

const renderPagination = ({ pages, activePage, onClick }, props, success) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page;
        return (
          <li
            className={`dot ${isActivePage ? "selected" : ""} ${success ? "success" : ""
              }`}
            key={page}
          />
        );
      })}
    </ContainerPagination>
  );
};

const diplayValues = [
  { key: "balance_before", name: "Saldo atual", value: "", position: "row" },
  {
    key: "quantity_formated",
    name: "Quantidade",
    value: "",
    position: "row",
  },
  { key: "price", name: "Preço", value: "", position: "row" },
  {
    key: "total",
    name: "Total",
    value: "",
    position: "row",
  },
  { key: "balance_after", name: "Saldo após", value: "", position: "row" },
];

const ModalBuy = ({ visible, onClose, currency, price, time, counter }) => {
  const balance = useSelector((state) => state.account.balance);
  const [position, setPosition] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [send, setSend] = useState(false);
  const [progress, setProgress] = useState(0);
  const [variant, setVariant] = useState("info");
  const [balanceInvalid, setBalanceInvalid] = useState(false)
  const [status, setStatus] = useState(1);
  const [percentageStep, setPercentageStep] = useState(0);

  var carousel = null;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { page, per_page } = useSelector((state) => state.crypto.orders);

  const initialValues = {
    amount: "0",
    currency: currency,
    price: formatToRealDolar(parseFloat(price)),
  };


  let formik = useFormik({
    initialValues: {
      amount: "0",
      currency: currency,
      price: (typeof price === 'number' ? formatToDecimal(price) : ''),
    },
  });


  const handleSubmitForm = (values) => {
    setSend(true);
    dispatch(
      CryptoActions.BuyRequest({
        amount: parseFloat(values.amount),
        currency: currency,
        price: values.price,
        type: values.type,
        callback: (data) => {
          if (data.status == "error") {
            setVariant("danger");
            setError(true);
          }
          if (data.status == "done") {
            dispatch(
              CryptoActions.LisOrders({ per_page: per_page, page: page })
            );
            setVariant("success");
            setSuccess(true);
          }
          setProgress(100);
        },
      })
    );
  };

  useEffect(() => {
    setPosition(0);
    setSuccess(false);
    setError(false);
    setProgress(0);
    setVariant("info");
    setSend(false);
    setBalanceInvalid(false)
  }, [time]);

  useEffect(() => {
    if (status == 0){
      formik.setFieldValue("price", formatToRealDolar(parseFloat(price)))
      let priceFloat = parseFloat(price)
      let total = parseFloat(formik.values.amount) * priceFloat;
      formik.setFieldTouched("total", true);
      formik.setFieldValue("total", formatToReal(total));
      formik.setFieldTouched("balance_after", true);
      formik.setFieldValue(
        "balance_after",
        formatToReal(parseFloat(balance) - total)
      );
      formik.setFieldTouched("balance_before", true);
      formik.setFieldValue(
        "balance_before",
        formatToReal(parseFloat(balance))
      );  

    }
   
  }, [price]);

  const handleCancel = () => {
    onClose();
  };

  const constDefineTotal = (props, e) => {
    let amount = parseFloat(e.target.value)
    let total = status === 1 ? parseFloat(amount) * parseFloat(price) : parseFloat(amount) * parseFloat(props.values.price.replace(/[\.^\R$\ ^\/]/gi, '')
      .replace(',', '.'))
    setBalanceInvalid(parseFloat(total) > parseFloat(balance))

  }

  const handleNextPagePosition = (carousel) => {
    carousel.goTo(position + 1);
    setPosition(position + 1);
  };

  const handleNextPage = (formik, handleSubmitForm, carousel) => {
    switch (position) {
      case 0:

        formik.validateForm().then(validation => formik.setTouched(validation))
        buyValidSchema
          .isValid({ amount: formik.values.amount })
          .then((valid) => {
            if (valid) {
              let priceFloat = parseFloat(
                formik.values.price.replace(/[\.^\R$\ ^\/]/gi, "").replace(",", ".")
              );
              let total = parseFloat(formik.values.amount).toFixed(2) * priceFloat;
              formik.setFieldTouched("total", true);
              formik.setFieldValue("total", formatToReal(total));
              formik.setFieldTouched("balance_after", true);
              // formik.setFieldValue(
              //   "balance_after",
              //   formatToReal(balance - total.replace(/[\.^\R$\ ^\/]/gi, "")
              //   .replace(",", ".")));
              formik.setFieldValue(
                "balance_after",
                formatToReal(parseFloat(balance) - total)
              );
              formik.setFieldTouched("balance_before", true);
              formik.setFieldValue(
                "balance_before",
                formatToReal(parseFloat(balance))
              );
              formik.setFieldTouched("quantity_formated", true);
              formik.setFieldValue(
                "quantity_formated",
                `${parseFloat(formik.values.amount).toFixed(2)} ${currency}`
              );
              if (total > 0) {
                handleNextPagePosition(carousel);
              }
              return
            }
            dispatch(GeneralActions.setError({ error: "Dados inválidos" }));
          })


        break;
      case 1:
        handleSubmitForm(formik.values);
        handleNextPagePosition(carousel);
        break;
      case 2:
        handleNextPagePosition(carousel);
        break;
      default:
        break;
    }
  };

  function radioHandler(status, formik) {
    switch (status) {
      case 1:
        formik.setFieldValue("price", formatToReal(parseFloat(price)));
        break;
    }
    setStatus(status);
  }

  const percentageHandleChange = (e) => {
    setPercentageStep(Math.ceil(e.target.value / 25) * 25);
  };

  return (
    <Modal show={visible} onHide={onClose} size="md" centered>
      <>
        <Modal.Header closeButton>
          <Modal.Title>Comprar {currency}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Carousel
              showArrows={false}
              enableSwipe={false}
              ref={(ref) => (carousel = ref)}
              renderPagination={(renderPropos) => {
                return renderPagination(renderPropos, formik, success);
              }}
            >
              <ContainerPage>
                <Form>
                  <fieldset>
                    <legend>Valor da ordem</legend>
                    <SelectArea>
                      <div className="item">
                        <input
                          type="radio"
                          id="market"
                          name="type"
                          value="market"
                          checked={status === 1}
                          onClick={(e) => radioHandler(1, formik)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label for="market">Preço de mercado</label>
                      </div>

                      <div className="item">
                        <input
                          type="radio"
                          id="limit"
                          name="type"
                          value="limit"
                          checked={status === 2}
                          onClick={(e) => radioHandler(2, formik)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label for="limit">Seu preço</label>
                      </div>
                    </SelectArea>
                  </fieldset>
                </Form>

                  {status === 1 && (
                      <>
                        <div className="field">
                          <label>Preço</label>
                          <InputMask
                            type="text"
                            name="price"
                            placeholder="preço"
                            disabled={status === 1}
                            format="decimal"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          ></InputMask>
                          {status === 1 && (
                            <span>
                              Cotação valida até{" "}
                              <strong>{counter} segundos</strong>
                            </span>
                          )}
                          <span>
                            {formik.touched.price && formik.errors.price}
                          </span>
                        </div>
                        <br></br>
                        {percentageStep === 0 && (
                          <div className="field">
                            <label>Quantidade</label>
                            <InputMask
                              autocomplete="off"
                              format="crypto"
                              type="string"
                              name="amount"
                              placeholder="Quantidade"
                              value={formik.values.amount}
                              onChange={(e) => {
                                formik.handleChange(e);
                                constDefineTotal(formik, e);
                              }}
                              onBlur={formik.handleBlur}
                            ></InputMask>
                            <span>
                              {formik.touched.amount && formik.errors.amount}
                            </span>
                          </div>
                        )}
                        {percentageStep !== 0 && (
                          <div className="field">
                            <label>Quantidade</label>
                            <InputMask
                              autocomplete="off"
                              format="crypto"
                              type="string"
                              name="amount"
                              placeholder="Quantidade"
                              value={
                                (formik.values.amount =
                                  (balance * percentageStep) / 100 / price)
                              }
                              onChange={(e) => {
                                formik.handleChange(e);
                                constDefineTotal(formik, e);
                              }}
                              onBlur={formik.handleBlur}
                            ></InputMask>
                            <span>
                              {formik.touched.amount && formik.errors.amount}
                            </span>
                          </div>
                        )}
                        <ContainerPage>
                          <div className="slidecontainer">
                            <p>
                              Size: <span id="value">{percentageStep}%</span>
                            </p>
                            <input
                              type="range"
                              min="0"
                              max="100"
                              value={percentageStep}
                              className="slider"
                              onChange={percentageHandleChange}
                            />
                            <span className="max value">100%</span>
                          </div>
                        </ContainerPage>
                        {percentageStep !== 0 && (
                          <>
                            <label>Total</label>
                            <input
                              type="text"
                              name="total"
                              placeholder="Total"
                              value={
                                (formik.values.total = formatToReal(
                                  (balance * percentageStep) / 100
                                ))
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </>
                        )}
                        {percentageStep === 0 && (
                          <>
                            <label>Total</label>
                            <input
                              type="text"
                              name="total"
                              placeholder="Total"
                              value={
                                (formik.values.total =
                                  status === 1
                                    ? formatToReal(
                                        parseFloat(formik.values.amount) *
                                          parseFloat(price)
                                      )
                                    : formatToReal(
                                        parseFloat(formik.values.amount) *
                                          parseFloat(
                                            formik.values.price
                                              .replace(/[\.^\R$\ ^\/]/gi, "")
                                              .replace(",", ".")
                                          )
                                      ))
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </>
                        )}
                        {balanceInvalid && (
                          <>
                            <br></br>
                            <Alert key={"danger"} variant={"danger"}>
                              Você não tem saldo para essa operação
                            </Alert>
                          </>
                        )}
                        <span>{formik.touched.total && formik.errors.total}</span>
                        Saldo atual:{formatToReal(balance)}
                      </>
                    )}
                    {status === 2 && (
                      <>
                        <div className="field">
                          <label>Preço</label>
                          <InputMask
                            type="text"
                            name="price"
                            placeholder="preço"
                            format="decimal"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          ></InputMask>

                          {status === 1 && (
                            <span>
                              Cotação valida até{" "}
                              <strong>{counter} segundos</strong>
                            </span>
                          )}
                          <span>
                            {formik.touched.price && formik.errors.price}
                          </span>
                        </div>

                        <br></br>

                        <div className="field">
                          <label>Quantidade</label>
                          <InputMask
                            autocomplete="off"
                            format="crypto"
                            type="string"
                            name="amount"
                            placeholder="Quantidade"
                            value={
                              (formik.values.amount =
                                percentageStep === 0
                                  ? formik.values.amount
                                  : (balance * percentageStep) /
                                    100 /
                                    parseFloat(
                                      formik.values.price
                                        .replace(/[\.^\R$\ ^\/]/gi, "")
                                        .replace(",", ".")
                                    ))
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                              constDefineTotal(formik, e);
                            }}
                            onBlur={formik.handleBlur}
                          >
                          </InputMask>
                          <span>
                            {formik.touched.amount && formik.errors.amount}
                          </span>
                        </div>
                        
                          <ContainerPage>
                            <div className="slidecontainer">
                              <p>
                                Size: <span id="value">{percentageStep}%</span>
                              </p>
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={percentageStep}
                                className="slider"
                                onChange={percentageHandleChange}
                              />
                              <span className="max value">100%</span>
                            </div>
                          </ContainerPage>

                          {percentageStep !== 0 && (
                            <>
                              <label>Total</label>
                              <input
                                type="text"
                                name="total"
                                placeholder="Total"
                                value={
                                  (formik.values.total = formatToReal(
                                    (balance * percentageStep) / 100
                                  ))
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </>
                          )}

                    {percentageStep === 0 && (
                            <>
                              <label>Total</label>
                              <input
                                type="text"
                                name="total"
                                placeholder="Total"
                                value={
                                  (formik.values.total =
                                    status === 1
                                      ? formatToReal(
                                          parseFloat(formik.values.amount) *
                                            parseFloat(price)
                                        )
                                      : formatToReal(
                                          parseFloat(formik.values.amount) *
                                            parseFloat(
                                              formik.values.price
                                                .replace(/[\.^\R$\ ^\/]/gi, "")
                                                .replace(",", ".")
                                            )
                                        ))
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </>
                          )}

                  {balanceInvalid && (
                            <>
                              <br></br>
                              <Alert key={"danger"} variant={"danger"}>
                                Você não tem saldo para essa operação
                              </Alert>
                            </>
                          )}
                          <span>
                            {formik.touched.total && formik.errors.total}
                          </span>
                          {/* Saldo atual:{formatToReal(balance)} */}
                        {balanceInvalid && (
                          <>
                            <br></br>
                            <Alert key={"danger"} variant={"danger"}>
                              Você não tem saldo para essa operação
                            </Alert>
                          </>
                        )}
                        <span>{formik.touched.total && formik.errors.total}</span>
                        Saldo atual:{formatToReal(balance)}
                      </>
                    )}
              </ContainerPage>

              {/* display */}
                  <ContainerPage>
                    <div className="field">
                      <DisplayInfos
                        items={diplayValues.map((item) => {
                          item.value = formik.values[item.key];
                          return item;
                        })}
                      />
                    </div>
                  </ContainerPage>

                  {/* variant  */}
              <ContainerPage>
                {variant == "info" && "Estamos processando sua transação"}
                <ProgressBar
                  variant={variant}
                  now={progress}
                  label={`${progress}%`}
                />
                {variant == "danger" &&
                  "Houve um erro, favor tentar novamente."}
                {status === 1
                  ? variant == "success" &&
                  "Sua transação foi recebida e está sendo processada."
                  : variant === "success" &&
                  "Ordens com o seu preço ficarão pendentes até serem executadas"}
              </ContainerPage>
            </Carousel>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <ContainerButtons>
            <ButtonDefault
              title="Fechar"
              background={theme.color.white}
              color={theme.color.blackButton}
              width="170px;"
              onClick={handleCancel}
            />
            {!send && (
              <ButtonDefault
                title="Próximo"
                background={theme.color.blackButton}
                color={theme.color.white}
                width="170px;"
                onClick={() => handleNextPage(formik, handleSubmitForm, carousel)}
                disabled={balanceInvalid}
              />
            )}
          </ContainerButtons>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default ModalBuy;
