import { all, takeLatest, fork } from "redux-saga/effects";

import user from "./user/sagas";
import account from "./account/sagas";
import apikey from "./apikey/sagas";
import register from "./register/sagas";
import billing from "./billing/sagas";
import transfer from "./transfer/sagas";
import deposit from "./deposit/sagas";
import myclient from "./myclient/sagas";
import supplier from "./supplier/sagas";
import transaction from "./transaction/sagas";
import paybill from "./paybill/sagas";
import nf from "./nf/sagas";
import employee from "./employee/sagas";
import pix from "./pix/sagas";
import general from "./general/sagas";
import invite from "./invite/sagas";
import company from "./company/sagas";
import crypto from "./crypto/sagas";
import invoice from './invoice/sagas'

export default function* rootSaga() {
  yield all([
    user,
    account,
    apikey,
    register,
    billing,
    transfer,
    myclient,
    deposit,
    supplier,
    transaction,
    paybill,
    nf,
    employee,
    pix,
    general,
    invite,
    company,
    crypto,
    invoice
  ]);
}
