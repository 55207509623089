import React, { useState, useEffect, CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, ContainerPage, ContainerPagination } from './styles'
import { ButtonDefault } from '../../Buttons'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as UserActions from '../../../store/modules/user/actions'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead'
import { InputMask } from '../../Inputs'
import { cpfIsValid } from '../../../utils/check'
import Carousel from 'react-elastic-carousel'
import apiCep from '../../../services/apiCEP.js'
import * as GeneralActions from '../../../store/modules/general/actions'

import DisplayInfos from '../../DisplayInfo'

const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success,
) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${
              success ? 'success' : ''
            }`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props)
            }}
          />
        )
      })}
    </ContainerPagination>
  )
}

const ClientSchema = Yup.object().shape({
  password: Yup.string().required('campo obrigatório'),
  new_password: Yup.string().required('campo obrigatório'),
  new_password_confirm: Yup.string()
    .required('campo obrigatório')
    .test('passwords-match', 'As senhas devem corresponder', function (value) {
      return this.parent.new_password === value
    }),
})

const initialValues = {
  password: '',
  new_password: '',
  new_password_confirm: '',
}

const initialErrors = {
  password: '-',
  new_password: '-',
  new_password_confirm: '-',
}

const diplayValues = [
  { key: 'password', name: 'Senha', value: '', position: 'row', type:"password" },
  { key: 'new_password', name: 'Nova senha', value: '', position: 'row',type:"password" },
  {
    key: 'new_password_confirm',
    name: 'Confirmação nova senha',
    value: '',
    position: 'row',
    type:"password"
  },
]

const MyClientCreate = ({ navigation }) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [position, setPosition] = useState(0)

  var carousel = null

  const [success, setSuccess] = useState(false)


  useEffect(() => {}, [])

  const handleCancel = () => {
    history.push('/home')
  }

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }
  const handleNextPage = (props) => {
    switch (position) {
      case 0:
        const { password, new_password, new_password_confirm } = props.values
        props.validateForm().then((validation) => props.setTouched(validation))
        ClientSchema.isValid({
          password,
          new_password,
          new_password_confirm,
        }).then((valid) => {
          console.log(valid)
          if (valid) {
            carousel.goTo(position + 1)
            setPosition(position + 1)
            return
          }
          dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
        })
        break
      case 1:
        handleSubmitForm(props.values)
        break
      default:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        break
    }
  }

  const handleSubmitForm = (values) => {
    dispatch(
      UserActions.ChangePassword({
        password:values.password,
        new_password:values.new_password,
        callback: () => {
          setSuccess(true)
        },
      }),
    )
  }

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">senha alterada com sucesso</div>
          </div>
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={ClientSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            <Carousel
              showArrows={false}
              ref={(ref) => (carousel = ref)}
              renderPagination={(renderPropos) => {
                return renderPagination(
                  renderPropos,
                  props,
                  handlePrevPage,
                  success,
                )
              }}
            >
              <ContainerPage>
                <div className="field">
                  <input
                    type="password"
                    name="password"
                    placeholder="Senha Atual"
                    value={props.values.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.password && props.errors.password}</span>
                </div>
                <div className="field">
                  <input
                    type="password"
                    name="new_password"
                    placeholder="Nova senha"
                    value={props.values.new_password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.new_password && props.errors.new_password}
                  </span>
                </div>
                <div className="field">
                  <input
                    type="password"
                    name="new_password_confirm"
                    placeholder="Confirmar nova senha"
                    value={props.values.new_password_confirm}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.new_password_confirm &&
                      props.errors.new_password_confirm}
                  </span>
                </div>
              </ContainerPage>

              <ContainerPage>
                {position == 1 && (
                  <DisplayInfos
                    items={diplayValues.map((item) => {
                      item.value = props.values[item.key]
                      return item
                    })}
                  />
                )}
              </ContainerPage>
            </Carousel>
            <div className="container-buttons">
              <div>
                <ButtonDefault
                  title="Próximo"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  onClick={() => handleNextPage(props, handleSubmitForm)}
                />
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  )
}

export default MyClientCreate
