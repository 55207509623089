import produce from "immer";

import { saveToken } from "../../../services/auth";

const INITIAL_STATE = {
  loading: true,
  token: "",
  username: "",
  password: "",
  data: {},
  logged: false,
  user: {
    id: 1,
    email: "",
    company_id: 0,
    active: false,
    confirm_email: false,
    name: "",
    document: "",
    created_in: 0,
    updated_in: 0,
    user_master: true,
    notification_token: "",
    step_register: 0,
    is_employee: false,
    block_in: 0,
  },
  partner: {
    id: 0,
    register_active: false,
    email: "",
    token: "",
    nivel: 0,
    active: true,
    send_email_confirm: false,
    name: "",
    phone: "",
    document: "",
    person_type: "",
    kyc_check: 0,
    createdAt: "",
    updatedAt: "",
    legal_representative_document_number: "",
    legal_representative_register_name: "",
    legal_representative_mother_name: "",
    legal_representative_birth_date: "",
    legal_representative_email: "",
    phone_country_code: "",
    phone_number: "",
    business_type: "",
  },
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case `@user/SET_TOKEN`:
      return produce(state, (draft) => {
        draft.token = action.payload.token;
      });
    case "@user/SET_LOGGED":
      return produce(state, (draft) => {
        draft.logged = action.payload.logged;
      });
    case "@user/LOGOUT":
      return produce(state, (draft) => {
        draft.token = "";
        draft.data = {};
        draft.logged = false;
      });
    case "@user/LOGIN_SUCCESS":
      return produce(state, (draft) => {
        draft.token = action.payload.token;
        draft.partner = action.payload.partner;
        draft.data = action.payload.user;
        draft.loading = false;
        draft.logged = true;
        saveToken(action.payload.token);
      });
    case "@user/GET_INFO_SUCCESS":
      return produce(state, (draft) => {
        draft.partner = action.payload.partner;
      });
    default:
      return state;
  }
}
