import React from 'react'
import {
  Container,
  ContainerLabel,
  ItemLabel,
  ItemTotal,
  Progress,
  ProgressContent,
} from './styles'
import PropTypes from 'prop-types'
import { formatToReal } from '../../utils/format'


const cleanAmount = (amount) =>
  parseFloat(amount.replace(/[\.^\R$\ ^\/]/gi, "").replace(",","."));

function Limit({ volume, balance, percent, className, amount }) {
  if (amount == '') {
    amount = 'R$ 0'
  }
  return (
    <Container className={className}>
      <ContainerLabel>
        <ItemLabel>Saldo:</ItemLabel>
        <ItemTotal> {formatToReal(balance - cleanAmount(amount))}</ItemTotal>
      </ContainerLabel>
      <Progress>
        <ProgressContent
          percent={percent}
          style={{
            width: `${((balance - cleanAmount(amount)) * 100) / volume - 1}%`,
          }}
        ></ProgressContent>
      </Progress>
    </Container>
  )
}

Limit.propTypes = {
  volume: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
}

export default Limit
