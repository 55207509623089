import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ApikeyActions from "../../store/modules/apikey/actions";
import * as GeneralActions from "../../store/modules/general/actions";

import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";
import { Container, KeyToken } from "./styles";
import moment from "moment";
import { setOnClipboard } from "../../utils/clipboard";

export default function ApiKey() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { keys, page, pages, total } = useSelector((state) => state.apikey);
  useEffect(() => {
    dispatch(ApikeyActions.apikeysRequest({ page: 1, per_page: 5 }));
  }, [dispatch]);

  const copyToken = (text) => {
    setOnClipboard(text);
    dispatch(GeneralActions.setMessage({ message: "Copiado" }));
  };

  const handleNewPress = () => {
    history.push("/api/novo");
  };

  const handleDelete = (b) => {
    dispatch(
      ApikeyActions.deleteApikeyRequest({
        id: b.id,
        callback: () => {
          dispatch(
            GeneralActions.setMessage({ message: b.client_id + " deletado" })
          );
        },
      })
    );
  };

  return (
    <Container>
      <h1>Chaves de acesso a api</h1>

      <div className="horizontal">
        <p>
          Voce pode criar uma nova chave de acesso para usar os serviços
          disponiveis
        </p>
        <ButtonDefault
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      <table>
        <thead>
          <tr className="t-header">
            <th>Cliente id</th>
            <th>data de criaçao</th>
            <th>Açoes</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((key) => (
            <tr key={key.id}>
              <td>{key.client_id}</td>
              <td>{moment(key.createdAt).format("DD/MM/YYYY")}</td>
              <td>
                {/* <i className="material-icons md-14">info</i>
                <i className="material-icons md-14">edit</i> */}
                <i
                  className="material-icons md-14"
                  onClick={() => handleDelete(key)}
                >
                  delete
                </i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}
