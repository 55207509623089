import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  animation: ${fadeIn} 1s forwards;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100vh;
  section {
    justify-content: space-between;
  }
`;

export const ContainerInputs = styled.div`
  width: 100%;
  input {
    margin-bottom: 20px;
  }
`;
