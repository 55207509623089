import { all, takeLatest, put, call, select } from "redux-saga/effects";
import apiCustomer from "../../../services/apiCustomer";
import history from "../../../services/history";
import { saveToken } from "../../../services/auth";
import Errors from "../../../services/errors";
import * as UserActions from "../user/actions";
import * as GeneralActions from "../general/actions";

export function* registerUser(action) {
  try {
    const { user } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    yield call(apiCustomer.post, "/auth/register", action.payload);
    yield put(
      UserActions.loginRequest({
        username: user.email,
        password: user.password,
      })
    );
  } catch (error) {
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* registerLegalRepresentative(action) {
  try {
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(
      apiCustomer.post,
      "/register/legal_representative",
      action.payload
    );
    yield put(UserActions.setToken(data.token));
    history.push("/cadastro/empresa");
  } catch (error) {
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* registerCompany(action) {
  try {
    yield put(GeneralActions.setLoading({ loading: true }));
    const { business_type } = action.payload;
    const { data } = yield call(
      apiCustomer.post,
      "/register/company",
      action.payload
    );
    yield put(UserActions.setToken(data.token));
    history.push("/cadastro/endereco");
  } catch (error) {
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* registerAddress(action) {
  try {
    const { address } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiCustomer.post, "/register/address", {
      address,
    });
    yield put(UserActions.setToken(data.token));

    switch (data.company.business_type) {
      case "MEI":
      case "ME":
      case "EPP":
      case "EIRELI":
        return history.push("/cadastro/codigo");
      case "LTDA":
        return history.push("/cadastro/documento/pessoal");
    }
  } catch (error) {
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* registerPersonalDocument(action) {
  try {
    const { front_document, back_document } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(
      apiCustomer.post,
      "/register/personal_document",
      { front_document, back_document }
    );
    yield put(UserActions.setToken(data.token));
    history.push("/cadastro/documento/empresa");
  } catch (error) {
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* registerCompanyDocument(action) {
  try {
    const { token } = yield select((state) => state.user);
    const {
      company_document,
      qsa_document,
      yield_document,
      social_contract_document,
    } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(
      apiCustomer.post,
      "/register/company_document",
      {
        company_document,
        qsa_document,
        social_contract_document,
        yield_document,
      }
    );
    yield put(UserActions.setToken(data.token));
    return history.push("/cadastro/codigo");
  } catch (error) {
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* checkCode(action) {
  try {
    const { token, data } = yield select((state) => state.user);
    const { code, callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    yield call(
      apiCustomer.post,
      "/register/confirm",
      // { code, username: data.email },
      { username: data.email, code }
    );
    callback();
  } catch (error) {
    console.log(error);
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* sendNewCode(action) {
  try {
    const { token } = yield select((state) => state.user);
    yield put(GeneralActions.setLoading({ loading: true }));
    yield call(apiCustomer.get, "/register/request/confirm");
  } catch (error) {
    let code = "000000000000";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
      if (code === "10045" || code === "20005" || code === "10044") {
        yield put(UserActions.logout());
      }
    }
    const errorMessage = Errors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeLatest("@register/REGISTER_USER", registerUser),
  takeLatest("@register/REGISTER_COMPANY", registerCompany),
  takeLatest("@register/REGISTER_ADDRESS", registerAddress),
  takeLatest("@register/REGISTER_PERSONAL_DOCUMENT", registerPersonalDocument),
  takeLatest("@register/REGISTER_PERSONAL", registerLegalRepresentative),
  takeLatest("@register/REGISTER_COMPANY_DOCUMENT", registerCompanyDocument),
  takeLatest("@register/CHECK_CODE", checkCode),
  takeLatest("@register/SEND_NEW_CODE", sendNewCode),
]);
