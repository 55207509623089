import produce from 'immer'

const INITIAL_STATE = {
  data: [],
  page: 1,
  pages: 1,
  total: 0,
  per_page: 30,
}

export default function Transfer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@transaction/TRANSACTIONS_REQUEST_SUCCESS':
      return produce(state, (draft) => {
        draft.data = action.payload.data
        draft.page = action.payload.page
        draft.pages = action.payload.pages
        draft.total = action.payload.total
      })
    default:
      return state
  }
}
