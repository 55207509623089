import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import {
  Container,
  ContainerInputs,
  ContainerHeader,
  ContainerBody,
  ButtonNext,
  Loading,
} from './styles'

import { HeaderBackImage } from '../../components/Headers'
import { ButtonDefault } from '../../components/Buttons'
import { ResetPassword } from '../../store/modules/user/actions'

import animation from '../../assets/animations/wallet.json'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const initialValues = {
  email: '',
}

export default function SignIn() {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.user.loading)
  const [isDone, setIsDone] = useState(false)

  function login({ email }) {
    dispatch(
      ResetPassword({
        email,
        callback: () => {
          MySwal.fire(<p>Senha enviada para seu e-mail</p>).then((result) => {
            history.push('/')
          })
        },
      }),
    )
  }

  return (
    <Container>
      <section className="container-small">
        <HeaderBackImage onClick={() => history.goBack()} />
        <Formik
          onSubmit={login}
          initialValues={initialValues}
          initialErrors={initialValues}
          initialTouched={initialValues}
        >
          {(props) => (
            <>
              <ContainerInputs>
                <input
                  type="text"
                  autoCapitalize="none"
                  placeholder="Email"
                  value={props.values.email}
                  onChange={props.handleChange('email')}
                />
              </ContainerInputs>

              <ButtonDefault
                type="submit"
                onClick={props.handleSubmit}
                background={theme.color.blackButton}
                color={theme.color.white}
                title="Recuperar"
              />
            </>
          )}
        </Formik>
      </section>
    </Container>
  )
}
