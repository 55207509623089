import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as ApikeyActions from "../../../store/modules/apikey/actions";
import * as GeneralActions from "../../../store/modules/general/actions";

import { setOnClipboard } from "../../../utils/clipboard";

const HomeCategories = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const [grant_type, setGrantType] = useState("all");
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const { key } = useSelector((state) => state.apikey);
  const [types] = useState(["all"]);

  useEffect(() => {
    // dispatch(AccountActions.resumeRequest());
  }, []);

  const handleSave = () => {
    if (grant_type !== "") {
      const callback = () => {
        setSuccess(true);
      };
      dispatch(ApikeyActions.newApikeyRequest({ grant_type, callback }));
    }
  };

  const handleCancel = () => {
    setGrantType("");
    history.push("/api");
  };

  const copy = (value, key) => {
    dispatch(GeneralActions.setMessage({ message: key + " copiado" }));
    setOnClipboard(value);
  };

  return (
    <Container>
      {success ? (
        <>
          <div className="field">
            <div className="input-ref">
              <label>client_id</label>
              <input
                placeholder="id do cliente"
                value={key.client_id}
                disabled
              ></input>
            </div>
            <a
              className="button"
              onClick={() => copy(key.client_id, "client_id")}
            >
              <label></label>
              <i className="material-icons">content_copy</i>
            </a>
          </div>
          <div className="field">
            <div className="input-ref">
              <label>client_secret</label>
              <input
                placeholder="Segredo"
                value={key.client_secret}
                disabled
              ></input>
            </div>
            <a
              className="button"
              onClick={() => copy(key.client_secret, "client_secret")}
            >
              <label></label>
              <i className="material-icons">content_copy</i>
            </a>
          </div>
        </>
      ) : (
        <>
          <div>
            <label>Tipo de acesso</label>
            <select
              type="text"
              name="business_type"
              placeholder="Tipo"
              value={grant_type}
              onChange={({ target }) => setGrantType(target.value)}
            >
              {types.map((item) => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </div>
        </>
      )}
      <div className="horizontal">
        {!success && (
          <ButtonDefault
            title="Salvar"
            background={theme.color.blackButton}
            color={theme.color.white}
            width="170px;"
            onClick={handleSave}
          />
        )}
        <ButtonDefault
          title="Voltar"
          background={theme.color.white}
          width="170px;"
          onClick={handleCancel}
        />
      </div>
    </Container>
  );
};

export default HomeCategories;
