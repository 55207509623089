import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../../components/Buttons";
import { Container } from "./styles";
import { formatToReal } from "../../../utils/format";
import * as BillingActions from "../../../store/modules/billing/actions";
import Paginator from "../../../components/Paginator";

export default function Billing() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { billings, page, pages, total } = useSelector(
    (state) => state.billing
  );

  useEffect(() => {
    dispatch(BillingActions.billingsRequest({ per_page: 20, page: 1 }));
  }, [dispatch]);

  const handlePagePress = useCallback((page) => {
    dispatch(BillingActions.billingsRequest({ per_page: 20, page }));
  });

  const handleNewPress = () => {
    history.push("/financeiro/cobranca/novo");
  };

  return (
    <Container>
      <h1>Cobrança</h1>
      <div className="horizontal">
        <p>Você pode gerar uma cobrança para seu cliente</p>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      <table>
        <thead>
          <tr className="t-header">
            <th>ID</th>
            <th>Total</th>
            <th>Status</th>
            <th>Total de parcelas</th>
            <th>Progresso</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {billings.map((b) => (
            <tr key={b.id}>
              <td style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "5px",
                    backgroundColor: theme.color[b.status] || "#fff",
                    height: "100%",
                  }}
                ></div>
                {b.id}
              </td>
              <td>{formatToReal(b.amount)}</td>
              <td>{b.status}</td>
              <td>{b.installment_total}</td>
              <td>
                <div className={`progress`}>
                  <span
                    className="inner"
                    style={{ width: `${b.progress ? b.progress : "0"}px` }}
                  ></span>
                  <span className="title">{`${
                    b.progress ? b.progress : "0"
                  }%`}</span>
                </div>
              </td>
              <td>
                <i className="material-icons md-14">info</i>
                <i className="material-icons md-14">edit</i>
                <i className="material-icons md-14">delete</i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        page={page}
        pages={pages}
        total={total}
        callback={handlePagePress}
      />
    </Container>
  );
}
