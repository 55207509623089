import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 100px;
  width: 400px;
  min-height: 320px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
`;

export const ContainerSaldo = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 16px;
  }
`;

export const ContainerList = styled.div`
  margin-top: 20px;
`;

export const ContainerTotal = styled.div`
  flex-flow: row nowrap;
  -webkit-flex-flow: row nowrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  height: 180px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.white2};
  color: ${({ color }) => color};

  h2 {
    font-size: 16px;
    font-weight: 100 !important;
  }

  p {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Item = styled.div`
  padding: 10px;
  background-color: ${({ color, theme }) => color || theme.gray};
  border-radius: 10px;
  padding-bottom: 15px;
  margin-top: -15px;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  h2 {
    font-size: 14px;
    font-weight: 100 !important;
    color: #fff;
  }

  p {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
`;
