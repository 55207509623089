import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history'
import apiCustomer from '../../../services/apiCostumer'
import GetErrors from '../../../services/errors'
import * as InvoiceActions from './actions'
import * as GeneralActions from '../general/actions'

export function* invoicePayableRequest(action) {
  try {
    const { per_page, page } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiCustomer.post,
      `crypto/partner/v1/web/invoices/payable`,
      {
        per_page,
        page,
        order_by: {
          field: 'status_code',
          direction: 'asc'
        }
      }
    )
    yield put(InvoiceActions.invoicePayableRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* invoiceReceivableRequest(action) {
  try {
    const { per_page, page } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiCustomer.post,
      `crypto/partner/v1/web/invoices/receivable`,
      {
        per_page,
        page,
        order_by: {
          field: 'status_code',
          direction: 'asc'
        }
      }
    )
    yield put(InvoiceActions.invoiceReceivableRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newInvoice(action) {
  try {
    yield put(GeneralActions.setLoading({ loading: true }))
    yield put(GeneralActions.setProgress({ variant: 'success' }))
    yield call(
      apiCustomer.post,
      `crypto/partner/v1/web/invoice`,
      action.payload
    )

    yield put(InvoiceActions.invoiceReceivableRequest({ page: 1, per_page: 100 }))
    yield put(
      GeneralActions.setProgress({ variant: 'success', percentage: 100 })
    )
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* invoiceDocument(action) {
  const { document, callback } = action.payload
  try {
    const { data } = yield call(
      apiCustomer.post,
      `/crypto/partner/v1/web/invoice/document`,
      { document: document }
    )
    if (callback != null) {
      data.status = 'done'
      callback(data)
    }
    yield put(InvoiceActions.invoiceDocumentSuccess(data))
  } catch (error) {
    let data = error.response?.data || '0'
    if (callback != null) {
      data.status = 'error'
      callback(data)
    }
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
  }
}

export function* acceptInvoice(action) {
  const { id, callback } = action.payload 
  try {
    yield put(GeneralActions.setLoading({ loading: true }))
    yield put(GeneralActions.setProgress({ variant: 'success' }))
    const {data} = yield call(
      apiCustomer.put,
      `crypto/partner/v1/web/invoice/${id}`,
      action.payload
    )
    if (callback != null) {
      data.status = 'done'
      callback(data)
    }
    yield put(InvoiceActions.invoicePayableRequest({ page: 1, per_page: 100 }))
    yield put(
      GeneralActions.setProgress({ variant: 'success_accept', percentage: 100 })
    )
  } catch (error) {
    let data = error.response?.data || '0'
    if (callback != null) {
      data.status = 'error'
      callback(data)
    }
    yield put(GeneralActions.setError({ error }))
    yield put(GeneralActions.setProgress({ variant: 'error', percentage: 100 }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* CancelInvoice(action) {
  const { id, callback } = action.payload
  try {
    yield put(GeneralActions.setLoading({ loading: true }))
    yield put(GeneralActions.setProgress({ variant: 'success' }))
    const { data } = yield call(
      apiCustomer.delete,
      `crypto/partner/v1/web/invoice/${id}`
    )
    if (callback != null) {
      data.status = 'done'
      callback(data)
    }
    yield put(InvoiceActions.invoicePayableRequest({ page: 1, per_page: 100 }))
    yield put(
      GeneralActions.setProgress({ variant: 'success_reject', percentage: 100 })
    )
  } catch (error) {
    let data = error.response?.data || '0'
    if (callback != null) {
      data.status = 'error'
      callback(data)
    }
    yield put(GeneralActions.setError({ error }))
    yield put(GeneralActions.setProgress({ variant: 'error', percentage: 100 }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export default all([
  takeLatest('@invoice/INVOICE_PAYABLE_REQUEST', invoicePayableRequest),
  takeLatest('@invoice/INVOICE_RECEIVABLE_REQUEST', invoiceReceivableRequest),
  takeLatest('@invoice/NEW_INVOICE', newInvoice),
  takeLatest('@invoice/INVOICE_DOCUMENT', invoiceDocument),
  takeLatest('@invoice/ACCEPT_INVOICE', acceptInvoice),
  takeLatest('@invoice/CANCEL_INVOICE', CancelInvoice)
])
