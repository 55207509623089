import React, { useEffect, useState, useMemo } from 'react'
import {
  Container,
  ContainerSaldo,
  ContainerList,
  ContainerTotal,
  Item,
} from './styles'
import { CanvasCircleGraphic } from '../../Graphics'
import { ButtonDefault } from '../../../components/Buttons'
import { setOnClipboard } from "../../../utils/clipboard";

import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as AccountActions from '../../../store/modules/account/actions'
import { formatToReal } from '../../../utils/format'
import 'moment/locale/pt-br'
import QRCode from 'react-qr-code'
import {FaRegCopy} from "react-icons/fa";

const HomeCategories = ({ navigation }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.account.resume)
  const [selected, setSelected] = useState(null)
  const account = useSelector((state) => state.account.account)

  useEffect(() => {
    dispatch(AccountActions.resumeRequest())
  }, [])

  useEffect(() => {
    if (!selected && data?.length) {
      setSelected(data[0])
    }
  }, [data])

  const dataFromCircleGraphic = useMemo(() => {
    if (data) {
      return data.map((i) => ({ ...i, title: i.name, value: i.amount }))
    } else {
      return []
    }
  }, [data])

  return (
    <Container>
      <ContainerSaldo>
        <h1>Pix</h1>
      </ContainerSaldo>
      <p style={{ marginTop: 20, alignSelf: 'center' }}>
        Chave aleatória:<br></br>
        <strong>{account.pix_key}</strong> <FaRegCopy className="button" title="compartilhar" size={20}  onClick={()=>setOnClipboard(account.pix_key)}/>
        <br></br>
        <br></br>
        {/*Qrcode:<br></br>*/}
        {/*{account.brcode != '' && <QRCode value={account.brcode} />}<br></br><br></br>*/}
        Copia e cola<br></br>
        <strong>{account.brcode.substring(0,40)}...</strong><FaRegCopy className="button" title="compartilhar" size={20}  onClick={()=>setOnClipboard(account.brcode)}/>
      </p>
    </Container>
  )
}

export default HomeCategories
