import produce from "immer";

const INITIAL_STATE = {
  keys: [],
  page: 1,
  pages: 1,
  total: 0,
  key: {
    client_id: "",
    client_secret: "",
  },
};

export default function card(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@apikey/APIKEY_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.keys = action.payload.data;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    case "@apikey/NEW_APIKEY_SUCESS":
      return produce(state, (draft) => {
        draft.key = action.payload;
      });
    default:
      return state;
  }
}
