import React, { useMemo, useEffect } from "react";
import { Container, Content, Item, Title, ShareContent } from "./styles";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { FaRegCopy, FaShareAlt } from "react-icons/fa";
import "moment/locale/pt-br";
import {setOnClipboard} from "../../../utils/clipboard";
import { formatCnpj } from "../../../utils/format";

const HomeEntries = ({ navigation }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account.account);


  useEffect(() => {}, []);

  const copyAccount = () => {
    setOnClipboard(`Dados da conta GenesisPay: \n\nNome: ${account.name}\n\nCNPJ: ${account.document}\nConta corrente: ${account.number}\n Agência: ${account.branch} \n\nBanco: ${account.bank_name} - ${account.brank_number}`)
  }

  return (
      <Container>
        <Content>
          <Title>{account?.name}</Title> <FaRegCopy className="button" title="copiar" size={20}  onClick={()=>copyAccount()}/>
          <Item>
            Banco : {account?.bank_name} - {account?.brank_number}
          </Item>
          <Item>
            Agencia: {account?.branch} | CC: {account?.number}
          </Item>
          <Item>Cnpj: {formatCnpj(account?.document)}</Item>
        </Content>
      </Container>
  );
};

export default HomeEntries;
