import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import {InputMask} from "../../../components/Inputs";
import {formatToReal} from "../../../utils/format";
import {Formik} from "formik";
import * as Yup from "yup";

import Carousel from "react-elastic-carousel";
import {ContainerPage, ContainerPagination} from "../../../components/Cards/ClientCreate/styles";
import {ButtonDefault} from "../../../components/Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import DisplayInfos from "../../../components/DisplayInfo";
import ProgressBar from 'react-bootstrap/ProgressBar';
import apiPayment from "../../../services/apiPayment";
import * as CompanyActions from "../../../store/modules/company/actions";
import {Alert} from "react-bootstrap";

const buyValidSchema = Yup.object().shape({});

const initialValues = {
    amount: "0"
};


const initialErrors = {
    amount: "-"
};


const renderPagination = ({pages, activePage, onClick}, props, handleNextPage, success) => {
    return (<ContainerPagination>
        {pages.map((page) => {
            const isActivePage = activePage === page;
            return (<li
                className={`dot ${isActivePage ? "selected" : ""} ${success ? "success" : ""}`}
                key={page}
                onClick={() => {
                    if (!success) handleNextPage(page,props);
                }}
            />);
        })}
    </ContainerPagination>);
};


const diplayValues = [{key: 'name', name: 'Nome', value: '', position: 'row'}, {
    key: 'email',
    name: 'Email',
    value: '',
    position: 'row'
},{
    key: 'pix_key',
    name: 'Chave pix',
    value: '',
    position: 'row'
}, {key: 'balance', name: 'Saldo', value: '', position: 'row'}]


const ModalDelete = ({visible, onClose,company}) => {
    const balance = company.balance
    const [position, setPosition] = useState(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [variant, setVariant] = useState("info");
    var carousel = null;
    const theme = useTheme();
    const dispatch = useDispatch()
    const [send, setSend] = useState(false);


    const handleDelete = (values) => {
        setSend(true)
        const amount = values.amount
            .replace(/[\.^\R$\ ^\/]/gi, '')
            .replace(',', '.')
        return apiPayment
            .get(`/payment/partner/v1/web/account/delete/${company.id}`)
            .then((response) => {
                setVariant("success")
                setSuccess(true)
                setProgress(100)
                dispatch(
                    CompanyActions.CompanyGetDetail({ id: company.id, callback: () => {} }),
                )
                dispatch(CompanyActions.CompanyRequest({ per_page: 10, page: 1 }))
            })
            .catch((error) => {
                console.log(error)
                setVariant("danger")
                setError(true)
                setProgress(100)
            })
    }


    const handleCancel = () => {
        onClose()
    };

    const handleNextPagePosition = () => {
        carousel.goTo(position + 1);
        setPosition(position + 1);
    }

    const handleChangeBulletPage = (page,props) => {
        switch (page) {
            case 0:
                props.setFieldTouched('email', true)
                props.setFieldValue("email", company.email)
                props.setFieldTouched('name', true)
                props.setFieldValue("name", company.name)
                props.setFieldTouched('pix_key', true)
                props.setFieldValue("pix_key", company.pix_key)
                props.setFieldTouched('balance', true)
                props.setFieldValue("balance", formatToReal(parseFloat(balance)))
                carousel.goTo(page);
                setPosition(page);

                break;
            case 1:
                handleDelete(props.values)
                carousel.goTo(page);
                setPosition(page);
                break;
            case 2:
                carousel.goTo(page);
                setPosition(page);
                break;
            default:
                break;
        }


    };
    const handleNextPage = (props) => {
        switch (position) {
            case 0:
                props.setFieldTouched('email', true)
                props.setFieldValue("email", company.email)
                props.setFieldTouched('name', true)
                props.setFieldValue("name", company.name)
                props.setFieldTouched('pix_key', true)
                props.setFieldValue("pix_key", company.pix_key)
                props.setFieldTouched('balance', true)
                props.setFieldValue("balance", formatToReal(parseFloat(balance)))
                handleNextPagePosition()
                break;
            case 1:
                handleDelete(props.values)
                handleNextPagePosition()
                break;
            case 2:
                handleNextPagePosition()
                break;
            default:
                break;
        }
    };


    return (<Modal show={visible} onHide={onClose} size="sm" centered>
        <Formik
            initialValues={initialValues}
            validationSchema={buyValidSchema}
            onSubmit={handleDelete}
            initialErrors={initialErrors}
        >
            {(props) => (<>
                <Modal.Header closeButton>
                    <Modal.Title>Deseja deletar {company?.name} ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Carousel
                            showArrows={false}
                            ref={(ref) => (carousel = ref)}
                            renderPagination={(renderPropos) => {
                                return renderPagination(renderPropos, props, handleChangeBulletPage, success);
                            }}
                        >
                            <ContainerPage>
                                <div className="field">
                                    <label>Nome</label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="amount"
                                        placeholder="Quantidade"
                                        disabled
                                        value={company.email}
                                    ></input>
                                </div>
                                <div className="field">
                                    <label>Email</label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="amount"
                                        placeholder="Quantidade"
                                        disabled
                                        value={company.email}
                                    ></input>
                                </div>
                                <div className="field">
                                    <label>Chave pix</label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="amount"
                                        placeholder="Chave pix"
                                        disabled
                                        value={company.pix_key}
                                    ></input>
                                </div>
                                <div className="field">
                                    <label>Saldo</label>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        name="amount"
                                        placeholder="Quantidade"
                                        disabled
                                        value={formatToReal(balance)}
                                    ></input>
                                </div>


                            </ContainerPage>
                            <ContainerPage>
                                <div className="field">
                                    <DisplayInfos
                                        items={diplayValues.map((item) => {
                                            item.value = props.values[item.key]
                                            return item
                                        })}
                                    />
                                </div>
                                <br></br>
                                {parseFloat(balance)> 0 && (
                                    <Alert key={"danger"} variant={"danger"}>Atenção essa conta ainda tem saldo ao deletar você não tera mais acesso</Alert>
                                )}
                                <br></br>
                                <Alert key={"warning"} variant={"warning"}>Ao deletar essa conta a chave pix ficara disponível para outras sub-contas</Alert>

                            </ContainerPage>
                            <ContainerPage>
                                {variant == "info" && "Estamos deletando"}
                                <ProgressBar variant={variant} now={progress} label={`${progress}%`}/>
                                {variant == "danger" && "Houve um erro, favor tentar novamente."}
                                {variant == "success" && "Sub-conta deletada com sucesso"}
                            </ContainerPage>
                        </Carousel>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ContainerButtons>
                        <ButtonDefault
                            title="Fechar"
                            background={theme.color.white}
                            color={theme.color.blackButton}
                            width="170px;"
                            onClick={handleCancel}
                        />
                        {!send && (
                            <ButtonDefault
                                title="Próximo"
                                background={theme.color.blackButton}
                                color={theme.color.white}
                                width="170px;"
                                onClick={() => handleNextPage(props, handleDelete)}
                            />
                        )}

                    </ContainerButtons>
                </Modal.Footer>
            </>)}
        </Formik>
    </Modal>)
}

export default ModalDelete
