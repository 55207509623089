import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";
import {
  Container,
  ContainerBoxes,
  SelectContainer,
  SelectItem,
  SelectContent,
  SelectArrow,
  Selected,
  ContainerItem,
  Item,
} from "./styles";
import { formatToReal } from "../../utils/format";
import * as TransactionActions from "../../store/modules/transaction/actions";
import Paginator from "../../components/Paginator";

import { CardHomeEntries } from "../../components/Cards";

const types = ["Invoice", "Outros"];

const payments = [
  {
    name: "Invoice",
    icon: "receipt",
    iconType: "icon",
    href: "/conveciencia/invoice",
  },
];

export default function Convenience() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, page, pages, total } = useSelector(
    (state) => state.transaction
  );
  const [convenience, setConvenience] = useState("");

  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(TransactionActions.transactionsRequest({ per_page: 20, page: 1 }));
  }, [dispatch]);

  const handlePagePress = useCallback((page) => {
    dispatch(TransactionActions.transactionsRequest({ per_page: 3, page }));
  });

  const handleNewPress = () => {
    // history.push("/transferencia/novo");
  };

  return (
    <Container>
      <h1>Conveniência</h1>
      <div className="horizontal">
        <p>Você pode adquirir produtos de nosso parceiros</p>
      </div>
      <SelectContainer>
        <Selected>{convenience}</Selected>
        <SelectArrow onClick={() => setShow(!show)}>
          <div>
            <i class="material-icons">
              {show ? "arrow_drop_up" : "arrow_drop_down"}
            </i>
          </div>
        </SelectArrow>
        <SelectContent className={show ? "show" : "hide"}>
          {types.map((item) => (
            <SelectItem
              onClick={() => {
                setConvenience(item);
                setShow(false);
              }}
            >
              {item}
            </SelectItem>
          ))}
        </SelectContent>
      </SelectContainer>
      <ContainerItem>
        <Item onClick={() => history.push("/conveniencia/invoice")}>
          <div className="title">Invoice</div>
          <div className="icon">
            <i className="material-icons">receipt</i>
          </div>
        </Item>
      </ContainerItem>
    </Container>
  );
}
