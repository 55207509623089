export function listRequest({ per_page, page, query }) {
  return { type: "@pix/LIST_REQUEST", payload: { per_page, page, query } };
}

export function listRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@pix/LIST_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}

export function NewKey({ label, type, value, callback }) {
  return {
    type: "@pix/NEW_KEY",
    payload: {
      label,
      type,
      value,
      callback,
    },
  };
}

export function GetExternalKey({ key, callback }) {
  return {
    type: "@pix/GET_EXTERNAL_KEY",
    payload: {
      key,
      callback,
    },
  };
}




export function GetExternalKeySuccess({ account }) {
  return {
    type: "@pix/GET_EXTERNAL_KEY_SUCCESS",
    payload: {
      account,
    },
  };
}
