import React, { useState, useEffect } from "react";
// import InputMask from "react-input-mask";
import { InputMask } from "../../../components/Inputs";

import apiCustomer from "../../../services/apiCustomer";
import apiCep from "../../../services/apiCEP";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Container,
  Page,
  ContainerInputs,
  HorizontalContainer,
} from "../styles";

import { HeaderBackImage } from "../../../components/Headers";
import { ButtonDefault } from "../../../components/Buttons";
import { registerAddress } from "../../../store/modules/register/actions";
import * as GeneralActions from "../../../store/modules/general/actions";

const initialValues = {
  zip_code: "",
  address_line: "",
  neighborhood: "",
  city: "",
  state: "",
  number: "",
  complement: "",
};

const initialErrors = {
  zip_code: "-",
  address_line: "-",
  neighborhood: "-",
  city: "-",
  state: "-",
  building_number: "-",
  complement: "-",
};

const AddressSchema = Yup.object().shape({
  zip_code: Yup.string()
    .min(9, "minimo de 8 caracteres")
    .required("campo obrigatório")
    .test("valid", "cep inválido", (value = "0") => {
      const cep = value.replace(/[\_^\-^\/]/gi, "");
      if (cep && cep.length === 8) {
        return true;
      }
      return false;
    }),
  address_line: Yup.string().required("campo obrigatório"),
  neighborhood: Yup.string().required("campo obrigatório"),
  city: Yup.string().required("campo obrigatório"),
  state: Yup.string()
    .min(2, "deve ter dois digitos")
    .required("campo obrigatório"),
  building_number: Yup.string().required("campo obrigatório"),
});

export default function RegisterAddress() {
  const { data } = useSelector((state) => state.user);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(-1);

  const handleCepPress = (props) => {
    const { zip_code } = props.values;
    dispatch(GeneralActions.setLoading({ loading: true }));
    let cep = zip_code.replace(/\D/g, "");
    apiCep
      .getAddress(cep)
      .then((result) => result.data)
      .then((result) => {
        props.setFieldValue("address_line", result.logradouro);
        props.setFieldTouched("address_line", true);
        props.setFieldValue("neighborhood", result.bairro);
        props.setFieldTouched("neighborhood", true);
        props.setFieldValue("city", result.localidade);
        props.setFieldTouched("city", true);
        props.setFieldValue("state", result.uf);
        props.setFieldTouched("state", true);
        props.setFieldTouched("complement", true);
        handleToSet(1);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(GeneralActions.setError("CEP inválido, tente outro"));
        }
      })
      .finally(() => {
        dispatch(GeneralActions.setLoading({ loading: false }));
      });
  };

  function submitForm(values) {
    const zip_code = values.zip_code.replace(/[\.^\-^\/]/gi, "");
    dispatch(
      registerAddress({
        address: {
          ...values,
          zip_code,
          building_number: values.building_number + "",
        },
      })
    );
  }

  const handleToSet = (value) => {
    setCurrent(value);
  };

  const validInput = ({ input, props }) => {
    if (props.errors[input]) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Container>
      <section className="container-small">
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          initialErrors={initialErrors}
          initialTouched={initialValues}
          validationSchema={AddressSchema}
        >
          {(props) => (
            <>
              {/* MESSAGE [-1] */}
              {current === -1 && (
                <Page>
                  <HeaderBackImage onClick={() => history.goBack()} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      Endereço <strong>da Empresa</strong>
                    </label>
                  </ContainerInputs>
                  <ButtonDefault
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Próximo"
                    onClick={() => handleToSet(0)}
                  />
                </Page>
              )}

              {/* CEP [0]*/}
              {current === 0 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="zip_code">
                      <strong>Cep</strong>
                    </label>
                    <InputMask
                      format="cep"
                      id="zip_code"
                      type="text"
                      name="zip_code"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.zip_code}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {props.touched.zip_code && props.errors.zip_code}
                    </span>
                  </ContainerInputs>
                  {validInput({ input: "zip_code", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleCepPress(props)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => { }}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => history.goBack()}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* ADDRESS [1]*/}
              {current === 1 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="address_line">
                      <strong>Logradouro</strong>
                    </label>
                    <input
                      id="address_line"
                      type="text"
                      name="address_line"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.address_line}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>{props.touched.address_line && props.errors.address_line}</span>
                  </ContainerInputs>

                  {validInput({ input: "address_line", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(0)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* DISTRICT bairro [2]*/}
              {current === 2 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="neighborhood">
                      <strong>Bairro</strong>
                    </label>
                    <input
                      id="neighborhood"
                      type="text"
                      name="neighborhood"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.neighborhood}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {props.touched.neighborhood && props.errors.neighborhood}
                    </span>
                  </ContainerInputs>

                  {validInput({ input: "neighborhood", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* CITY cidade [3]*/}
              {current === 3 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="city">
                      <strong>Cidade</strong>
                    </label>
                    <input
                      id="city"
                      type="text"
                      name="city"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>{props.touched.city && props.errors.city}</span>
                  </ContainerInputs>

                  {validInput({ input: "city", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(4)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(4)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(2)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* STATE estado [4]*/}
              {current === 4 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="state">
                      <strong>Estado</strong>
                    </label>
                    <input
                      max={2}
                      maxLength={2}
                      id="state"
                      type="text"
                      name="state"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.state}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>{props.touched.state && props.errors.state}</span>
                  </ContainerInputs>

                  {validInput({ input: "state", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(5)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(5)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(3)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* NUMBER numero [5]*/}
              {current === 5 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="building_number">
                      <strong>Número</strong> do prédio
                    </label>
                    <input
                      id="building_number"
                      type="building_number"
                      name="building_number"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.building_number}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>{props.touched.building_number && props.errors.building_number}</span>
                  </ContainerInputs>

                  {validInput({ input: "building_number", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(6)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(6)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(4)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* COMPLEMENT complemento [6]*/}
              {current === 6 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="complement">
                      <strong>Complemento</strong> do endereço
                    </label>
                    <input
                      id="complement"
                      type="complement"
                      name="complement"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.complement}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {props.touched.complement && props.errors.complement}
                    </span>
                  </ContainerInputs>

                  {validInput({ input: "complement", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(7)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(6)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(4)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* CHECK INFORMATION [7] */}
              {current === 7 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label>
                      <strong>{data?.name} </strong>verifique as informações e
                      confirme
                    </label>
                    <br />
                    <label>
                      cep
                      <br />
                      <strong>{props.values.zip_code}</strong>
                    </label>
                    <br />
                    <label>
                      Logradouro
                      <br />
                      <strong>{props.values.address}</strong>
                    </label>
                    <br />
                    <label>
                      bairro
                      <br />
                      <strong>{props.values.neighborhood}</strong>
                    </label>
                    <br />
                    <label>
                      cidade
                      <br />
                      <strong>{props.values.city}</strong>
                    </label>
                    <br />

                    <label>
                      estado
                      <br />
                      <strong>{props.values.state}</strong>
                    </label>
                    <br />

                    <label>
                      número
                      <br />
                      <strong>{props.values.building_number}</strong>
                    </label>
                    <br />

                    {props.values.complement && (
                      <label>
                        complemento
                        <br />
                        <strong>{props.values.complement}</strong>
                      </label>
                    )}
                    <br />
                  </ContainerInputs>

                  <ButtonDefault
                    type="submit"
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Próximo"
                    onClick={props.handleSubmit}
                  />
                  <ButtonDefault
                    onClick={() => handleToSet(6)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}
            </>
          )}
        </Formik>
      </section>
    </Container>
  );
}
