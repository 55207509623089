import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";
import {
  Container,
  ContainerBoxes,
  SelectContainer,
  SelectItem,
  SelectContent,
  SelectArrow,
  Selected,
  ContainerItem,
  Item,
} from "./styles";
import { FaUserTie } from "react-icons/fa";

const types = ["Pagamentos", "Transporte"];

export default function CRM() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {}, [dispatch]);

  return (
    <Container>
      <h1>CRM</h1>
      <div className="horizontal">
        <p>Controle fornecedores e clientes</p>
      </div>
      <ContainerItem>
        {/* <Item onClick={() => history.push("/crm/fornecedor")}>
          <div className="title">Fornecedores</div>
          <div className="icon">
            <i className="material-icons">supervised_user_circle</i>
          </div>
        </Item> */}
        {/* <Item onClick={() => history.push("/crm/cliente")}>
          <div className="title">Clientes</div>
          <div className="icon">
            <i className="material-icons">record_voice_over</i>
          </div>
        </Item> */}
        <Item onClick={() => history.push("/crm/sub-conta")}>
          <div className="title">Subcontas</div>
          <div className="icon">
            <i className="material-icons">record_voice_over</i>
          </div>
        </Item>
      </ContainerItem>
    </Container>
  );
}
