import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import {InputMask} from "../../Inputs";
import {formatToReal} from "../../../utils/format";
import {Formik} from "formik";
import * as Yup from "yup";

import Carousel from "react-elastic-carousel";
import {ContainerPage, ContainerPagination} from "../ClientCreate/styles";
import {ButtonDefault} from "../../Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import DisplayInfos from "../../DisplayInfo";
import * as CryptoActions from "../../../store/modules/crypto/actions";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {withdrawRequest} from "../../../store/modules/crypto/sagas";
import { Alert } from 'react-bootstrap';
import * as GeneralActions from "../../../store/modules/general/actions";

import { pt } from 'yup-locale-pt';
Yup.setLocale(pt);


const validSchema = Yup.object().shape({
    amount: Yup.number().required().positive(),
    address:Yup.string().required(),
});



const initialValues = {
    amount: "0", currency: "", address: "", network: "matic"
};


const initialErrors = {
    amount: "-", currency: "-", address: "-", network: "-"
};


const renderPagination = ({pages, activePage, onClick}, props, success) => {
    return (<ContainerPagination>
        {pages.map((page) => {
            const isActivePage = activePage === page;
            return (<li
                className={`dot ${isActivePage ? "selected" : ""} ${success ? "success" : ""}`}
                key={page}
            />);
        })}
    </ContainerPagination>);
};


const diplayValues = [{key: 'balance_before', name: 'Saldo atual', value: '', position: 'row'}, {
    key: 'quantity_formated',
    name: 'Quantidade',
    value: '',
    position: 'row'
}, {key: 'address', name: 'Endereço', value: '', position: 'row'}, {
    key: 'balance_after',
    name: 'Saldo após',
    value: '',
    position: 'row'
}]


const ModalTransfer = ({visible, onClose, currency, balance, blockchain, time}) => {
    const [position, setPosition] = useState(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [variant, setVariant] = useState("info");
    const [send, setSend] = useState(false);
    const [balanceInvalid, setBalanceInvalid] = useState(false)
    var carousel = null;
    const theme = useTheme();
    const dispatch = useDispatch()
    const {networks} = useSelector((state) => state.crypto,)


    useEffect(() => {
        setPosition(0)
        setSuccess(false)
        setError(false)
        setProgress(0)
        setVariant("info")
        setSend(false)
        dispatch(
            CryptoActions.NetworksList({
                callback: () => {
                },
                per_page: 5,
                page: 1,
                query: {
                    currency: currency,
                },
            }),
        )
    }, [])

    const [selectedNetwork, setSelectedNetwork] = useState({
        fee: '0',
        name: '',
        Symbol: '',
        contract_address: '',
        blockchain: '',
        validation_address: '',
    })

    const handleSubmitForm = (values) => {
        setSend(true)
        dispatch(CryptoActions.WithdrawNew({
            amount: parseFloat(values.amount), currency: currency, network: values.network,address:values.address, callback: (data) => {
                if (data.status == "error") {
                    setVariant("danger")
                    setError(true)
                }
                if (data.status == "done") {
                    setVariant("success")
                    setSuccess(true)
                }
                setProgress(100)
            }
        }))

    };


    const handleCancel = () => {
        onClose()
    };

    const handleNextPagePosition = () => {
        carousel.goTo(position + 1);
        setPosition(position + 1);
    }

    const constDefineTotal  = (props,e) => {
        let amount = parseFloat(e.target.value)
        setBalanceInvalid(parseFloat(amount) > parseFloat(balance))
    }


    const handleNextPage = (props) => {
        switch (position) {
            case 0:
                props.validateForm().then(validation => props.setTouched(validation))
                validSchema
                    .isValid({ amount:props.values.amount,address:props.values.address })
                    .then((valid) => {
                        if (valid) {
                            props.setFieldTouched('balance_before', true)
                            props.setFieldValue("balance_before", formatToReal(parseFloat(balance)))
                            props.setFieldValue("balance_after", `${formatToReal(parseFloat(balance) - parseFloat(props.values.amount))} ${currency}`)
                            props.setFieldTouched('balance_after', true)
                            props.setFieldTouched('quantity_formated', true)
                            props.setFieldValue("quantity_formated", `${parseFloat(props.values.amount).toFixed(2)} ${currency}`)
                            handleNextPagePosition()
                            return
                        }
                        dispatch(GeneralActions.setError({ error: "Dados inválidos" }));
                    })

                break;
            case 1:
                handleSubmitForm(props.values)
                handleNextPagePosition()
                break;
            case 2:
                handleNextPagePosition()
                break;
            default:
                break;
        }
    };


    return (<Modal show={visible} onHide={onClose} size="md" centered>
        <Formik
            initialValues={initialValues}
            validationSchema={validSchema}
            onSubmit={handleSubmitForm}
            initialErrors={initialErrors}
        >
            {(props) => (<>
                <Modal.Header closeButton>
                    <Modal.Title>Transferir {currency}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Carousel
                            showArrows={false}
                            enableSwipe={false}
                            ref={(ref) => (carousel = ref)}
                            renderPagination={(renderPropos) => {
                                return renderPagination(renderPropos, props, success);
                            }}
                        >
                            <ContainerPage>
                                <div className="field">
                                    <label>Quantidade</label>
                                    <InputMask
                                        autocomplete="off"
                                        format="crypto"
                                        type="string"
                                        name="amount"
                                        placeholder="Quantidade"
                                        value={props.values.amount}
                                        onChange={
                                            (e)=>{
                                                props.handleChange(e)
                                                constDefineTotal(props,e)
                                            }
                                        }
                                        onBlur={props.handleBlur}
                                    ></InputMask>
                                    <span>{props.touched.amount && props.errors.amount}</span>
                                    {
                                        balanceInvalid && (
                                            <>
                                                <br></br>
                                                <Alert key={"danger"} variant={"danger"}>Você não tem saldo para essa operação</Alert>
                                            </>
                                        )
                                    }
                                    Saldo atual: {parseFloat(balance).toFixed(2)} {currency}
                                </div>
                                <div className="field">
                                    <label>Endereço</label>
                                    <input
                                        autoComplete="off"
                                        name="address"
                                        placeholder="Endereço"
                                        value={props.values.address}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    ></input>
                                    <span>{props.touched.address && props.errors.address}</span>
                                </div>
                                <br></br>
                                <div className="field">
                                    <Alert key={"warning"} variant={"warning"}>Essa moeda só permite transferência na rede: {blockchain} </Alert>
                                </div>
                            </ContainerPage>
                            <ContainerPage>
                                <div className="field">
                                    <DisplayInfos
                                        items={diplayValues.map((item) => {
                                            item.value = props.values[item.key]
                                            return item
                                        })}
                                    />
                                </div>
                            </ContainerPage>
                            <ContainerPage>
                                {variant == "info" && "Estamos processando sua transação"}
                                <ProgressBar variant={variant} now={progress} label={`${progress}%`}/>
                                {variant == "danger" && "Houve um erro, favor tentar novamente."}
                                {variant == "success" && "Sua transação foi recebida e está sendo processando."}
                            </ContainerPage>
                        </Carousel>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ContainerButtons>
                        <ButtonDefault
                            title="Cancelar"
                            background={theme.color.white}
                            color={theme.color.blackButton}
                            width="170px;"
                            onClick={handleCancel}
                        />
                        {!send && (
                            <ButtonDefault
                                title="Próximo"
                                background={theme.color.blackButton}
                                color={theme.color.white}
                                width="170px;"
                                onClick={() => handleNextPage(props, handleSubmitForm)}
                                disabled={balanceInvalid}
                            />

                        )}

                    </ContainerButtons>
                </Modal.Footer>
            </>)}
        </Formik>
    </Modal>)
}

export default ModalTransfer
