import produce from "immer";

const INITIAL_STATE = {
  transfers: [],
  page: 1,
  pages: 1,
  total: 0,
  per_page:30,
  bank: {
    data: [],
    page: 1,
    pages: 1,
    total: 0,
  },
  accounts: {
    data: [],
    page: 1,
    pages: 1,
    total: 0,
  },
  transfer: {},
};

export default function Transfer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@transfer/TRANSFERS_REQUEST":
      return produce(state, (draft) => {
        draft.transfers = [];
        draft.page = 1;
        draft.pages = 0;
        draft.total = 0;
      });
    case "@transfer/TRANSFERS_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.transfers = action.payload.data;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    case "@transfer/BANKS_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.bank = action.payload;
      });
    case "@transfer/ACCOUNTS_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.accounts = action.payload;
      });
    case "@tranfer/DEFINE_DETAIL":
      return produce(state, (draft) => {
        draft.transfer = action.payload;
      });
    default:
      return state;
  }
}
