import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../../components/Buttons";
import { Container } from "./styles";
import { formatToReal } from "../../../utils/format";
import * as InviteActions from "../../../store/modules/invite/actions";
import Pagination from "@material-ui/lab/Pagination";
import { setOnClipboard } from "../../../utils/clipboard";

export default function Billing() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, page, pages, total,per_page } = useSelector((state) => state.invite);

  useEffect(() => {
    dispatch(InviteActions.InviteRequest({ per_page: per_page, page: 1 }));
  }, [dispatch]);

  const handlePagePress = useCallback((page) => {
    dispatch(InviteActions.InviteRequest({ per_page: per_page, page }));
  });

  const handleNewPress = () => {
    history.push("/crm/convite/novo");
  };

  const handleDetail = (myclient) => {
    // dispatch(InviteActions.InviteDetail({ myclient }));
    // history.push(`/crm/cliente/detalhe/${myclient.id}`);
  };

  return (
    <Container>
      <h1>Convites</h1>
      <div className="horizontal">
        <p>Lista de convites</p>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      {!data.length && <p>nenhum convite cadastrado</p>}
      {!!data.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Código</th>
                <th>Email</th>
                <th></th>
                <th>Açoes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((b) => (
                <tr key={b.id}>
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        backgroundColor: theme.color[b.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {b.id}
                  </td>
                  <td>{b.code}</td>
                  <td>{b.email}</td>
                  <td></td>
                  <td>
                    <i
                      onClick={() => handleDetail(b)}
                      className="fa fa-exclamation-circle"
                    ></i>
                    <i className="fa fa-clipboard"   onClick={() =>  setOnClipboard(b.code)}></i>
                    {/* <i className="fa fa-trash"></i> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
        </>
      )}
    </Container>
  );
}
