import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";
import { Container } from "./styles";
import { formatToReal } from "../../utils/format";
import * as InvoiceActions from "../../store/modules/invoice/actions";
import Paginator from "../../components/Paginator";

import PyamentIcon from "../../components/PyamentIcon"
import InvoiceCreate from "../../components/Cards/InvoiceCreate";
import InvoiceDetail from "../../components/Cards/InvoiceDetail";
import moment from "moment";



export default function Invoice() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const { data_payable, data_receivable, page_receivable, pages_receivable, total_receivable, page_payable, pages_payable, total_payable, page, pages, total } = useSelector(
    (state) => state.invoice
  )
  
  const [invoiceCreate, setInvoiceCreate] = useState(false)
  const [invoiceDetail, setInvoiceDetail] = useState(false)


  useEffect(() => {
    dispatch(InvoiceActions.invoicePayableRequest({ per_page: 4, page: 1}));
    dispatch(InvoiceActions.invoiceReceivableRequest({ per_page: 4, page: 1 }));
  }, []);

  useEffect(() => {

  }, [dispatch])

  const handlePagePress = useCallback((page) => {
    dispatch(InvoiceActions.invoicePayableRequest({ per_page: 4, page }));
    dispatch(InvoiceActions.invoiceReceivableRequest({ per_page: 4, page }));

  });

  const handleDetail = (invoice, type) => {
    dispatch(InvoiceActions.invoiceDetail({ invoice: {
      ...invoice, type
    } }))
    setInvoiceDetail(true)
  }

  return (
    <>
    <Container>
      <h1>Invoices</h1>
      <div className="horizontal">
        <p>Lista de invoices criadas por mim</p>
        <InvoiceCreate visible={invoiceCreate} onClose={() => setInvoiceCreate(false)}/>
        <InvoiceDetail visible={invoiceDetail} onClose={() => setInvoiceDetail(false)}/>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={() => setInvoiceCreate(true)}
        />
      </div>
      {!data_receivable?.length && <p>nenhuma invoice criada</p>}
      {!!data_receivable?.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Valor</th>
                <th>Status</th>
                <th>Cliente</th>
                {/* <th>Link de cancelamento</th> */}
                <th>Vence em:</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {data_receivable?.map((invoice) => (
                <tr key={invoice.id} className={`${invoice.status}`} >
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        // TODO: alterar color status dinanim
                        backgroundColor: theme.color[invoice.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {invoice.id}
                  </td>
                  <td>{invoice.amount} {invoice.currency}</td>
                  <td>{invoice.status}</td>
                  <td className='truncate' >{invoice.client_name}</td>
                  {/* <td>{invoice.pdf_link_cancel}</td> */}
                  <td>{moment(invoice.expiredAt).format("DD/MM/YYYY")}</td>
                  <td className="actions">
                    <div className="button" onClick={() => handleDetail(invoice, 'receivable')}>
                    <i
                    className="material-icons icon"
                  >
                    keyboard_arrow_right
                  </i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            page={page_receivable}
            pages={pages_receivable}
            total={total_receivable}
            callback={handlePagePress}
          />
        </>
      )}
    </Container>

    <Container>
      <div className="horizontal">
        <p>Lista de invoices cobradas de mim</p>
       
      </div>
      {!data_payable?.length && <p>nenhuma invoice cobrada de você</p>}
      {!!data_payable?.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Valor</th>
                <th>Status</th>
                <th>Cliente</th>
                {/* <th>Link de cancelamento</th> */}
                <th>Expira em:</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {data_payable?.map((invoice) => (
                <tr key={invoice.id} className={`${invoice.status}`} >
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        backgroundColor: theme.color[invoice.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {invoice.id}
                  </td>
                  <td>{invoice.amount}</td>
                  <td>{invoice.status}</td>
                  <td className='truncate'>{invoice.receiver_name}</td>
                  {/* <td>{invoice.pdf_link_cancel}</td> */}
                  <td>{moment(invoice.expiredAt).format("DD/MM/YYYY")}</td>
                  <td className="actions">
                    <div className="button" onClick={() => handleDetail(invoice, 'payable')}>
                    <i
                    className="material-icons icon"
                  >
                    keyboard_arrow_right
                  </i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            page={page_payable}
            pages={pages_payable}
            total={total_payable}
            callback={handlePagePress}
          />
        </>
      )}
    </Container>
    </>
  );
}
