import styled, { keyframes } from "styled-components";

import colors from "../../styles/colors";

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 900px;
  padding: 20px;
  animation: ${animationName} 0.8s;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${colors.grayText};
  }
  p {
    font-weight: 100;
  }
  tr{
    td.payment-type {
    & div{
        width:auto;
     }
    }
    .actions{
      display: table-cell;
      .button{
        width: 33%;
        display: table-cell;
      }
    }
  }
  tr.done {
    .button {
      pointer-events: none;
      opacity:0.2;
    }
  }
`;

export const TransactionItem = styled.div`
  background-color: white;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  min-height: 80px;

  .bar-container {
    position: relative;
    width: 10px;
    flex: 1;
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
    }
  }
  .info {
    flex: 5;
    padding: 5px;
    justify-content: center;
    .title {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
    .subtitle {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: uppercase;
      color: #797878;
    }
  }
  .price {
    justify-content: center;
    flex: 3;
    text-align: right;
    padding: 5px 10px 5px 5px;
    flex-direction: column;
    .amount {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height */

      color: #000000;
    }
    .clock {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: uppercase;
      color: #797878;
    }
  }
  .action {
    flex: 1;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const DateLabel = styled.div`
  padding: 10px;
  font-family: 'Roboto', sans-serif;
`