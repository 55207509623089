import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Container, Item } from "./styles";

function Paginator({ page, pages, callback }) {
  const renderItems = useMemo(() => {
    const handlePress = page => {
      callback(page);
    };
    const items = [];
    for (let index = 1; index <= pages; index++) {
      items.push(
        <Item
          key={index }
          onClick={() => handlePress(index )}
          current={+(page === index )}
        >
          {index }
        </Item>
      );
    }
    return items;
  }, [pages, page, callback]);
  return <Container>{renderItems}</Container>;
}

Paginator.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired
};

export default Paginator;
