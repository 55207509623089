import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  DateLabel,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatHour,
} from '../../../utils/format'
import * as CryptoActions from '../../../store/modules/crypto/actions'

import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import '../../../assets/animations/animations.css'
import ModalBuy from '../../../components/Cards/HomeDigitalMoney/modalBuy'
import ModalOrder from './modalOrder'

export default function Orders() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { list, page, pages, per_page, total } = useSelector(
    (state) => state.crypto.orders,
  )
  const [orderItens, setOrderItens] = useState([])

  const [orderItem, setOrderItem] = useState()

  const [orderModal, setOrderModal] = useState(false)

  useEffect(() => {
    dispatch(AccountActions.balanceRequest())
    dispatch(AccountActions.GetAccount())
  }, [dispatch])

  useEffect(() => {
    if (list.length > 0) {
      let dataFormated = []
      list.forEach((item) => {
        let checktIndex = dataFormated.findIndex(
          (el) => el.key == formatDate(item.createdAt),
        )
        if (checktIndex > -1) {
          dataFormated[checktIndex].data.push(item)
        } else {
          dataFormated.push({ key: formatDate(item.createdAt), data: [item] })
        }
      })

      setOrderItens(dataFormated)
    }
  }, [list])

  useEffect(() => {
    dispatch(CryptoActions.LisOrders({ per_page: per_page, page: 1 }))
  }, [dispatch])

  const handlePagePress = useCallback((event, value) => {
    dispatch(CryptoActions.LisOrders({ per_page: per_page, page: value }))
  })

  const selectOrder = (item) => {
    setOrderItem(item)
    setOrderModal(true)
  }

  return (
    <ContainerItens>
      <ModalOrder
        visible={orderModal}
        onClose={() => setOrderModal(false)}
        order={orderItem}
      />
      <Container>
        <h1>Últimas ordens</h1>
        <div className="horizontal">
          <p>Lista das suas últimas ordens</p>
        </div>
        <Content>
          <div className="table">
            {!orderItens.length && <p>Você não tem ordens pendentes</p>}
            {!!orderItens.length && (
              <>
                {orderItens.map((item, index) => (
                  <>
                    <DateLabel key={index}>{item.key}</DateLabel>
                    {item.data.map((b, indexData) => (
                      <TransactionItem key={indexData}>
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{
                              backgroundColor: theme.color[b.status] || '#fff',
                            }}
                          ></div>
                        </div>
                        <div className="info">
                          <span className="title">
                            {' '}
                            {parseFloat(b.amount).toFixed(2)} {b.currency}
                          </span>
                          <span className="subtitle">
                            {' '}
                            {b.action == 'sell' ? 'Venda' : 'Compra'}
                          </span>
                        </div>
                        <div className={`price`}>
                          {' '}
                          <span className={`amount ${b.operation_direct}`}>
                            {formatToReal(parseFloat(b.total_amount))}
                          </span>
                          <span className="clock">
                            {' '}
                            {formatHour(b.updatedAt)}{' '}
                          </span>
                        </div>
                        <div
                          className="action"
                          onClick={() => {
                            selectOrder(b)
                          }}
                        >
                          <i className="material-icons icon">navigate_next</i>
                        </div>
                      </TransactionItem>
                    ))}
                  </>
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
        </Content>
      </Container>

      {/*<ContainerRigthModal className={rightMenu ? 'show-right-menu' : ''}>*/}
      {/*  {renderView(selectType)}*/}
      {/*</ContainerRigthModal>*/}
    </ContainerItens>
  )
}
