import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  height: 70px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  -webkit-box-shadow: 3px 20px 26px -19px rgba(0, 0, 0, 0.68);
  box-shadow: 3px 20px 26px -19px rgba(0, 0, 0, 0.68);
  position: relative;
`;

export const Info = styled.div`
  text-align: right;
`;

export const InfoImage = styled.div`
  text-align: left;
  display: flex;
`;

export const Image = styled.img`
  border-width: 1px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  border-radius: 40px;
  border-style: dashed;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  margin-left: 30px;
`;


export const TextInfo = styled.div`
    font-size:16px;
`;