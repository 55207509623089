import React from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { CardDepositCreate } from "../../../../components/Cards";
import { Container } from "./styles";

export default function NewDeposit() {
  const history = useHistory();

  return (
    <Container>
      <h1>Novo depósito</h1>
      <p>
        você pode fazer um depósito em sua conta com vários meios de pagamento
      </p>
      <div className="horizontal"></div>
      <CardDepositCreate />
    </Container>
  );
}
