import axios from "axios";

const url = process.env.REACT_APP_RECEITA_URL;

const apiCNPJ = {
  GetRefService: () => {
    return axios.create({
      baseURL: url,
    });
  },

  checkCNPJ: (cnpj) => {
    return apiCNPJ
      .GetRefService()
      .get(`/${cnpj}`)
      .then((result) => result.data);
  },
};

export default apiCNPJ;
