import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  left: -10px;
  color: #000;
  i {
    font-size: 20px;
  }
`;

export const Image = styled.img`
  width: 200px;
`;
