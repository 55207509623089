import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  ContainerRigthModal,
  LabelRightMenu,
  ContentRightMenu,
  HeaderRigthModal,
  CloseIconHeader,
  RigthModal,
  LabelType,
  DateLabel,
  ButtonDownload,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from '../../../utils/format'
import * as TransferActions from '../../../store/modules/transfer/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Paginator from '../../../components/Paginator'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import * as DepositActions from '../../../store/modules/deposit/actions'
import * as UserActions from '../../../store/modules/user/actions'
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from '@react-pdf/renderer'
import '../../../assets/animations/animations.css'
import CloseIcon from '../../../assets/images/close.png'
import logoIcon from '../../../assets/images/packman-white.png'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export default function Transaction() {
  const [rightMenu, setRightMenu] = useState(false)
  const [rightMenuInfo, setRightMenuInfo] = useState([])
  const [selectType, setSelectType] = useState('transfer')

  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, page, pages, per_page, total } = useSelector(
    (state) => state.deposit,
  )
  const [openDeposit, setOpenDeposit] = useState(false)

  const { balance, balance_block } = useSelector((state) => state.account)
  const account = useSelector((state) => state.account.account)
  const [depositItens, setdepositItens] = useState([])
  const [depositSelected, setDepositSelected] = useState({})

  const handleCloseDeposit = () => setOpenDeposit(false)

  useEffect(() => {
    dispatch(AccountActions.balanceRequest())
    dispatch(AccountActions.GetAccount())
  }, [dispatch])
  useEffect(() => {
    if (data.length > 0) {
      let dataFormated = []
      data.forEach((item) => {
        let checktIndex = dataFormated.findIndex(
          (el) => el.key == formatDate(item.processedAt),
        )
        if (checktIndex > -1) {
          dataFormated[checktIndex].data.push(item)
        } else {
          dataFormated.push({ key: formatDate(item.processedAt), data: [item] })
        }
      })

      setdepositItens(dataFormated)
    }
  }, [data])

  useEffect(() => {
    dispatch(DepositActions.depositRequest({ per_page: per_page, page: 1 }))
  }, [dispatch])

  const handlePagePress = useCallback((event, value) => {
    dispatch(DepositActions.depositRequest({ per_page: per_page, page: value }))
  })

  const renderView = (type) => {
    return renderDeposit()
  }
  const handleNewPress = () => {
    clearInterval(window.checkStatus)
    history.push('/financeiro/deposito/novo')
  }

  const handleInfoRightMenu = (info) => {
    setSelectType('deposit')
    setOpenDeposit(true)
    setRightMenuInfo(info)
    setDepositSelected(info)
    // history.push("/transferencia/novo");
  }

  const renderDeposit = () => (
    <Modal
      open={openDeposit}
      onClose={handleCloseDeposit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h1> Detalhes </h1>
        <LabelType className="deposit">Recebido</LabelType>
        <Typography id="modal-modal-title" sx={{ mt: 2 }}>
          Tipo: {depositSelected.type}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Data:{formatDate(depositSelected.updatedAt)}
        </Typography>
        {depositSelected.sender_name != '' && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Nome da origem: :{depositSelected.sender_name}
          </Typography>
        )}

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Valor: {formatToReal(parseFloat(depositSelected.amount))}{' '}
        </Typography>
        {depositSelected.sender_document != '' && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Documento: {depositSelected.sender_document}
          </Typography>
        )}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Documento: {depositSelected.sender_document}
        </Typography>
        {depositSelected.bank_name != '' && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Instituição: {depositSelected.bank_name}
          </Typography>
        )}

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          ID da transação: {depositSelected.integration_id}
        </Typography>
        <Box>
          {depositSelected.integration_id && (
            <PDFDownloadLink
              document={
                <DocumentDeposit deposit={depositSelected} account={account} />
              }
              fileName="deposit.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  'Loading document...'
                ) : (
                  <ButtonDownload
                    title="Baixar"
                    background={theme.color.green}
                    color={theme.color.white}
                    height="50px"
                    href={url}
                    target="_blank"
                    download={depositSelected.protocol}
                  >
                    Baixar
                  </ButtonDownload>
                )
              }
            </PDFDownloadLink>
          )}
        </Box>
      </Box>
    </Modal>
  )

  const DocumentDeposit = ({ deposit, account }) => {
    return (
      <Document>
        <Page
          size="A4"
          style={{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 }}
        >
          <View>
            <Image
              style={{
                height: 50,
                width: 50,
                backgroundColor: 'black',
              }}
              src={logoIcon}
            />
          </View>
          <Text style={{ fontSize: 24, textAlign: 'left', marginTop: 12 }}>
            Comprovante de Transferência
          </Text>
          <Text style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}>
            {' '}
            Valor:{' '}
          </Text>
          <Text style={{ marginTop: 4, fontSize: 10 }}>
            {' '}
            {formatToReal(parseFloat(deposit.amount))}{' '}
          </Text>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Conta Origem:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  De:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.sender_name}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Documento:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.sender_document}{' '}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Instituição:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.bank_name}{' '}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Conta Destino:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  Para:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {account.name}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Documento:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {formatCnpj(account.document)}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Instituição:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {account.bank_name}{' '}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Informações:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  Tipo:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.type}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Data:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {formatDateHour(deposit.updatedAt)}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  ID da transação:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.integration_id}{' '}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <ContainerItens>
      <Container>
        <h1>Depósitos</h1>
        <div className="horizontal">
          <p>Lista de depositos</p>
          <ButtonDefault
            type="button"
            title="Novo"
            background={theme.color.green}
            color={theme.color.white}
            width="250px"
            height="50px"
            onClick={handleNewPress}
          />
        </div>

        <Content>
          <div className="table">
            {!depositItens.length && <p>nenhum depósito realizado</p>}
            {!!depositItens.length && (
              <>
                {depositItens.map((item, index) => (
                  <>
                    <DateLabel key={index}>{item.key}</DateLabel>
                    {item.data.map((b, indexData) => (
                      <TransactionItem
                        key={indexData}
                        onClick={() => {
                          handleInfoRightMenu(b)
                        }}
                      >
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{
                              backgroundColor: theme.color['in'] || '#fff',
                            }}
                          ></div>
                        </div>
                        <div className="info">
                          {b.type == 'withdraw' ? (
                            <span className="title">{b.sender_document}</span>
                          ) : (
                            <span className="title">{b.sender_name}</span>
                          )}

                          {b.type == 'withdraw' ? (
                            <span className="subtitle">saque da sub-conta</span>
                          ) : (
                            <span className="subtitle"> {b.type}</span>
                          )}
                         
                        </div>
                        <div className={`price`}>
                          {' '}
                          <span className={`amount ${b.operation_direct}`}>
                            {formatToReal(b.amount)}
                          </span>
                          <span className="clock">
                            {' '}
                            {formatHour(b.updatedAt)}{' '}
                          </span>
                        </div>
                        <div className="action">
                          <i className="material-icons icon">navigate_next</i>
                        </div>
                      </TransactionItem>
                    ))}
                  </>
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
          <div className="balance">
            <div className="content">
              <div className="item">
                <div className="title">Saldo atual:</div>
                <div className="subtitle">{formatToReal(balance)}</div>
              </div>
              <div className="item">
                <div className="title">Saldo bloqueado:</div>
                <div className="subtitle">{formatToReal(balance_block)}</div>
              </div>
            </div>
          </div>
        </Content>
      </Container>

      <ContainerRigthModal className={rightMenu ? 'show-right-menu' : ''}>
        {renderView(selectType)}
      </ContainerRigthModal>
    </ContainerItens>
  )
}
