import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  DateLabel,
  Filter,
  ContainerButtons,
} from "./styles";
import { formatToReal, formatDate, formatHour } from "../../../utils/format";
import Pagination from "@material-ui/lab/Pagination";
import * as TransferActions from "../../../store/modules/transfer/actions";
import * as DepositActions from "../../../store/modules/deposit/actions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "../../../assets/animations/animations.css";
import ToggleButton from "react-bootstrap/ToggleButton";
import ExtractPdf from "./ExtractPdf";

import * as AccountActions from "../../../store/modules/account/actions";

import DepositModal from "./DepositModal";
import TransferModal from "./TransferModal";
import * as CompanyActions from "../../../store/modules/company/actions";

import DatePicker from "react-datepicker";
import { ButtonDefault } from "../../../components/Buttons";

export default function TransactionByPartner({ client_id, client_type }) {
  const [transaction, setTransaction] = useState([]);
  const [refPrint, setRefPrint] = useState(false);
  const [radioValue, setRadioValue] = useState("30");

  const radios = [
    {name:"Dia",value:"1"},
    { name: '30 dias', value: '30' },
    { name: '90 dias', value: '90' },
    { name: '120 dias', value: '120' },
];

  const [query, setQuery] = useState({from: rmDays(new Date(), 30).setUTCHours(0,0,0), to: addDays(new Date(), 1).setUTCHours(23,59,59)})


  const theme = useTheme();
  const dispatch = useDispatch();
  let { data, page, pages, per_page } = useSelector(
    (state) => state.company.transactions
  );
  const { company } = useSelector((state) => state.company);

  const { account } = useSelector((state) => state.account);

  const [openDeposit, setOpenDeposit] = useState(false);

  const [openTransfer, setOpenTransfer] = useState(false);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function rmDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  function setDate(from, to) {
    from = new Date(from).setUTCHours(0, 0, 1)
    to = new Date(to).setUTCHours(23, 59, 59)
    setQuery({
      ...query,
      from: from,
      to: to,
    });
  }

  useEffect(() => {
    dispatch(AccountActions.GetAccount());
    dispatch(AccountActions.balanceRequest());
    queryTransactions();

    // );
  }, [dispatch]);
  useEffect(() => {
    if (data.length > 0) {
      let dataFormated = [];
      data.forEach((item) => {
        let checktIndex = dataFormated.findIndex(
          (el) => el.key == formatDate(item.createdAt)
        );
        if (checktIndex > -1) {
          dataFormated[checktIndex].data.push(item);
        } else {
          dataFormated.push({ key: formatDate(item.createdAt), data: [item] });
        }
      });

      dataFormated.forEach((item, key) => {
        item.balance = item.data[item.data.length - 1].balance;
      });

      setTransaction(dataFormated);
    }
  }, [data]);

  const handlePagePress = useCallback((event, value) => {
    query.from = new Date(query.from);
    query.to = new Date(query.to);
    dispatch(
      CompanyActions.CompanyTransactionRequest({
        per_page: per_page,
        page: value,
        query: {
          ...query,
          client_id,
          client_type,
        },
      })
    );
  });

  const queryTransactions = () => {
    query.from = new Date(query.from);
    query.to = new Date(query.to);
    setRefPrint(false);
    dispatch(
      CompanyActions.CompanyTransactionRequest({
        per_page: per_page,
        page: 1,
        query: {
          ...query,
          client_id,
          client_type,
        },
      })
    );
  };

  const changeRadioDays = (value) => {
    let queryRef = {...query}
    switch (value){
        case "1":
          const startOfDay = new Date()
          startOfDay.setUTCHours(0,0, 1)
          const endOfDay = new Date()
          endOfDay.setUTCHours(23, 59, 59)
            queryRef.from = startOfDay
            queryRef.to =  endOfDay
            break;
        default:
            queryRef.from =  rmDays(new Date(), parseInt(value)).setUTCHours(0,0,1)
            queryRef.to =  addDays(new Date(), 1).setUTCHours(23,56,56)
            break
    }
    setRadioValue(value)
    setQuery(queryRef)
  };

  const printTransctions = () => {
    setRefPrint(false);
    query.from = new Date(query.from);
    query.to = new Date(query.to);
    dispatch(
      CompanyActions.CompanyTransactionRequest({
        per_page: 100000,
        page: 1,
        query: {
          ...query,
          client_id,
          client_type,
        },
        order_by: { field: "created_at", direction: "asc" },
      })
    );
    setRefPrint(true);
  };

  const handleInfoRightMenu = (info) => {
    switch (info.operation_type) {
      case "transfer":
        dispatch(
          TransferActions.transferGet({
            id: info.liquid_id,
            type: "customer",
          })
        );
        setOpenTransfer(true);
        break;
      case "deposit":
        dispatch(
          DepositActions.depositGet({
            id: info.liquid_id,
            type: "customer",
          })
        );
        setOpenDeposit(true);
        break;
    }
  };

  return (
    <ContainerItens>
      <Container>
        <h1>Transações</h1>
        <div className="horizontal">
          <p>Todas as movimentações</p>
        </div>

        <Filter>
          <h2>Filtros</h2>
          <div className="filter_container">
            <div className="filter_item">
              <div>Data inicio:</div>
              <DatePicker
                max
                dateFormat="dd/MM/yyyy"
                locale="pt-br"
                selected={query.from}
                onChange={(date) => setDate(date, query.to)}
              />
            </div>
            <div className="filter_item">
              <div>Data fim:</div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                locale="pt-br"
                selected={query.to}
                onChange={(date) => setDate(query.from, date)}
              />
            </div>
            <div className="filter_item">
              <ButtonDefault
                type="button"
                title="filtrar"
                background={theme.color.blackButton}
                color={theme.color.white}
                width="250px"
                height="50px"
                onClick={() => queryTransactions()}
              />
            </div>
          </div>
          <div className="rang-date">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={
                  radioValue === radio.value
                    ? "outline-success"
                    : "outline-success"
                }
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => changeRadioDays(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </div>
        </Filter>

        <Content>
          <div className="table">
            {!transaction.length && <p>nenhuma transferência realizada</p>}
            {!!transaction.length && (
              <>
                {transaction.map((item, index) => (
                  <>
                    <DateLabel key={index}>{item.key}</DateLabel>
                    {item.data.map((b, indexData) => (
                      <TransactionItem
                        key={indexData}
                        onClick={() => {
                          handleInfoRightMenu(b);
                        }}
                      >
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{
                              backgroundColor:
                                theme.color[b.operation_direct] || "#fff",
                            }}
                          ></div>
                        </div>
                        <div className="info">
                          <span className="title">
                            {b.title == "" ? b.category_name : b.title}
                          </span>
                          <span className="subtitle">
                            {b.subtitle == "" ? b.category_name : b.subtitle}<br></br>
                           {(b.operation_service == "pix" || b.operation_service == "ted")  && <> CNPJ/CPF:{b.document}</>}
                            {" "}
                            {}
                          </span>
                        </div>
                        <div className={`price`}>
                          {" "}
                          <span className={`amount ${b.operation_direct}`}>
                            {formatToReal(parseFloat(b.amount))}
                          </span>
                          <span className="clock">
                            {" "}
                            {formatHour(b.updatedAt)}{" "}
                          </span>
                        </div>
                        <div className="action">
                          <i className="material-icons icon">navigate_next</i>
                        </div>
                      </TransactionItem>
                    ))}
                  </>
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
          <div className="balance">
            <div className="content">
              <div className="item">
                <div className="title">Saldo atual:</div>
                <div className="subtitle">{formatToReal(company.balance)}</div>
              </div>
              <div className="item">
                <div className="title">Saldo bloqueado:</div>
                <div className="subtitle">
                  {formatToReal(company.balance_block)}
                </div>
              </div>
            </div>
            <ButtonDefault
              type="button"
              title="Gerar extrato"
              background={theme.color.blackButton}
              color={theme.color.white}
              width="250px"
              height="50px"
              onClick={() => printTransctions()}
            />

            <ContainerButtons>
              {refPrint && (
                <PDFDownloadLink
                  document={
                    <ExtractPdf
                      transactions={transaction}
                      company={company}
                      balance={company.balance}
                      account={account}
                      from={query.from}
                      to={query.to}
                    />
                  }
                  fileName={`extract_${company.pix_key}_${company.name}.pdf`}
                  className="button-pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : <>Baixar</>
                  }
                </PDFDownloadLink>
              )}
            </ContainerButtons>
          </div>
        </Content>
      </Container>
      <DepositModal
        visible={openDeposit}
        onClose={() => setOpenDeposit(false)}
      />
      <TransferModal
        visible={openTransfer}
        onClose={() => setOpenTransfer(false)}
      />
    </ContainerItens>
  );
}
