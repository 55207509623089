import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";
import { Container, TransactionItem, DateLabel } from "./styles";
import { formatHour, formatToReal } from "../../utils/format";
import * as PayBillActions from "../../store/modules/paybill/actions";
import Paginator from "../../components/Paginator";

import PyamentIcon from "../../components/PyamentIcon"
import PayBillModal from "../Finance/Transaction/PayBillModal";


export default function PayBill() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [paymentModal, setPaymentModal] = useState()
  const { data, page, pages, total } = useSelector((state) => state.paybill)

  console.log(data)


  useEffect(() => {
    dispatch(PayBillActions.payBillsRequest({ per_page: 3, page: 1 }));
  }, [dispatch]);

  const handlePagePress = useCallback((page) => {
    dispatch(PayBillActions.payBillsRequest({ per_page: 3, page }));
  });

  const cancel = (item) => {

  }

  const selectPayment = (item) => {
    setPaymentModal(true)
  }

  const handleNewPress = () => {
    PayBillActions.newPayBillDone()
    history.push("/financeiro/pagar-conta/novo");
  };

  return (
    <Container>
      <h1>Pagamento de contas</h1>
      <div className="horizontal">
        <p>Lista das contas que você pagou.</p>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      {!data?.length && <p>nenhum pagamento realizado</p>}
      {!!data?.length && (
        <>
                    {data?.map((b, indexData) => (
                      <TransactionItem key={indexData}>
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{
                              backgroundColor: theme.color[b.status] || '#fff',
                            }}
                          ></div>
                        </div>
                        <div className="info">
                          <span className="title">
                            {' '}
                            {parseFloat(b.amount).toFixed(2)} {b.currency}
                          </span>
                          <span className="subtitle">
                            {' '}
                            {b.action == 'sell' ? 'Venda' : 'Compra'}
                          </span>
                        </div>
                        <div className={`price`}>
                          {' '}
                          <span className={`amount ${b.operation_direct}`}>
                            {formatToReal(parseFloat(b.total_amount))}
                          </span>
                          <span className="clock">
                            {' '}
                            {formatHour(b.updatedAt)}{' '}
                          </span>
                        </div>
                        <div
                          className="action"
                          onClick={() => {
                            selectPayment(b)
                          }}
                        >
                          <i className="material-icons icon">navigate_next</i>
                        </div>
                      </TransactionItem>
              ))}
          <Paginator
            page={page}
            pages={pages}
            total={total}
            callback={handlePagePress}
          />
        </>
      )}
     <PayBillModal
     visible={paymentModal}
     onClose={() => setPaymentModal(false)}
   />
    </Container>
  );
}
