import produce from "immer";

const INITIAL_STATE = {
  data: [],
  page: 1,
  pages: 1,
  total: 0,
  amount: 0,
  balance: 0,
  have_balance: true,
  checked:false,
  paybill:{
    "id":0,
    "protocol":"",
    "bar_code":"",
    "createdAt":"",
    "updatedAt":"",
    "client_id":0,
    "integration_id":"",
    "amount":0,
    "status":"",
    "object":"",
    "digitable_line":"",
    "transaction_id_authorize":0,
    "type":"boleto",
    "dueDate":"",
    "type_authorize":0,
    "error_message":"",
    "document_payer":"",
    "document_recipient":"",
    "liquid_in":0,
    "send_in":0,
    "client_type":"",
    "authorize_id":"",
    "correlation_id":"",
    "partner_id":0,
    "sender_branch":"",
    "sender_number":"",
    "sender_document":"",
    "sender_ispb_number":"",
    "sender_name":"",
    "bank_name":"",
    "brank_number":""
  },
  paybillCheck: {
    digitable_line:"",
    settleDate:"",
    dueDate:"",
    assignor:"",
    amount:0,
  }
};

export default function paybill(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@paybill/REQUEST_SUCCESS":
      return produce(state, draft => {
        draft.data = action.payload.data;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    case "@paybill/REQUEST_CHECK":
      return produce(state, draft => {
        draft.checked = false;
      });
    case "@paybill/REQUEST_CHECK_SUCCESS":
      return produce(state, draft => {
        draft.balance  = action.payload.have_balance
        draft.paybillCheck = {
          digitable_line: action.payload.digitable_line,
          settleDate: action.payload.settleDate,
          dueDate: action.payload.dueDate,
          assignor: action.payload.assignor,
          amount: action.payload.amount,
          issuer_name:action.payload.issuer_name,
          payer_name:action.payload.payer_name,
        }
        draft.checked = true;
      });
    case "@paybill/DEFINE_DETAIL":
      return produce(state, (draft) => {
        draft.paybill = action.payload.paybill;
      });
      case "@paybill/NEW_DONE":
      return produce(state, (draft) => {

        draft.checked = false
        draft.paybillCheck = {
          digitable_line:"",
          settleDate:"",
          dueDate:"",
          assignor:"",
          amount:0,
        };
        draft.paybill = {
          "id":0,
          "protocol":"",
          "bar_code":"",
          "createdAt":"",
          "updatedAt":"",
          "client_id":0,
          "integration_id":"",
          "amount":0,
          "status":"",
          "object":"",
          "digitable_line":"",
          "transaction_id_authorize":0,
          "type":"boleto",
          "dueDate":"",
          "type_authorize":0,
          "error_message":"",
          "document_payer":"",
          "document_recipient":"",
          "liquid_in":0,
          "send_in":0,
          "client_type":"",
          "authorize_id":"",
          "correlation_id":"",
          "partner_id":0,
          "sender_branch":"",
          "sender_number":"",
          "sender_document":"",
          "sender_ispb_number":"",
          "sender_name":"",
          "bank_name":"",
          "brank_number":""
        }
      });
    default:
      return state;
  }
}
