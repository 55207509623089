import styled from "styled-components";

export const Container = styled.button`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "60px"};
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${({ disable, background }) =>
    disable ? "#fff" : background};
  color: ${({ disable, color, theme }) =>
    disable ? theme.color.blackButton : color};
  border-radius: 10px;
  margin-top: 10px;
  -webkit-box-shadow: 0px 2px 12px -5px #000000;
  box-shadow: 0px 2px 12px -5px #000000;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.larger}px;
`;
