import produce from "immer";

const INITIAL_STATE = {
  billings: [],
  page: 1,
  pages: 1,
  total: 0,
  billingsCreated: []
};

export default function card(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@billing/BILLING_REQUEST_SUCCESS":
      return produce(state, draft => {
        draft.billings = action.payload.data;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    case "@billing/NEW_BILLING":
      return produce(state, draft => {
        draft.billingsCreated = []
      });
    case "@billing/NEW_BILLING_SUCCESS":
      return produce(state, draft => {
        draft.billingsCreated = action.payload.billings;
      });
    default:
      return state;
  }
}
