import React, {useMemo, useEffect, useState} from "react";
import { Container, Content, Item, Title, ContainerItem } from "./styles";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import "moment/locale/pt-br";
import {useHistory} from "react-router-dom";
import Carousel from 'react-elastic-carousel'
import ModalTransfer from "./Transfer";


const payments = [
  {
    name: "Transferência",
    icon: "trending_up",
    iconType: "icon",
    href: "/financeiro/transferencia/novo",
  },

  {
    name: "Pagamento de conta",
    icon: "receipt",
    iconType: "icon",
    href: "/conveniencia/pagar-conta",
  },
];


const HomeEntries = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [orderModal, setOrderModal] = useState(false)


  useEffect(() => {}, []);


  return (
    <Container>
      <ModalTransfer visible={orderModal} onClose={()=>setOrderModal(false)} order={null} handleCancel={()=> setOrderModal(false)}/>
      <h2>Menu rápido</h2>
      <ContainerItem>
        <Item onClick={() => setOrderModal(true)}>
          <div xclassName="title">Transferência</div>
          <div className="icon">
            <i className="material-icons">trending_up</i>
            </div>
        </Item>

      </ContainerItem>
    </Container>
  );
};

export default HomeEntries;
