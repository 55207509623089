import produce from "immer";

const INITIAL_STATE = {
  data: [],
  page: 1,
  pages: 1,
  total: 0,
  employee: {},
};

export default function Employee(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@employee/LIST_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.data = action.payload.data;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    case "@employee/DEFINE_DETAIL":
      return produce(state, (draft) => {
        draft.employee = action.payload.employee;
      });
    default:
      return state;
  }
}
