import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../../components/Buttons";
import { Container, ContainerBox, NoteContainer, OuterBox } from "./styles";
import { formatToReal } from "../../../utils/format";
import * as MyClientActions from "../../../store/modules/myclient/actions";
import * as BillingActions from "../../../store/modules/billing/actions";
import Paginator from "../../../components/Paginator";
import DisplayInfos from "../../../components/DisplayInfo"


const diplayValues = [
  { key: "name", name: "Nome", value: "", position: "row" },
  { key: "email", name: "Email", value: "", position: "row" },
  { key: "phone", name: "Telefone", value: "", position: "row" },
  { key: "document", name: "document", value: "", position: "row" },
  { key: "zip_code", name: "Cep", value: "", position: "row" },
  { key: "address", name: "Logradouro", value: "", position: "column" },
  { key: "neighborhood", name: "Bairro", value: "", position: "column" },
  { key: "city", name: "Cidade", value: "", position: "column" },
  { key: "state", name: "Estado", value: "", position: "column" },
  { key: "number", name: "Número", value: "", position: "column" },
  { key: "complement", name: "Complemento", value: "", position: "column" },
];


const diplayValuesTotal = [
  {
    key: "total",
    name: "Total cobrado",
    value: 1,
    position: "row",
    type: "money"
  },
  {
    key: "total_paid",
    name: "Total pago",
    value: 1,
    position: "row",
    type: "money"
  },
  {
    key: "total_expired",
    name: "Total em Atraso",
    value: 1,
    position: "row",
    type: "money"
  },
];

export default function ClientDetail() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { myclient, totalResume } = useSelector(
    (state) => state.myclient
  );

  const { billings, page, pages, total } = useSelector(
    (state) => state.billing
  );


  useEffect(() => {
    if (myclient.name == undefined) {
      history.push("/crm/cliente")
    }
    dispatch(MyClientActions.resumeBilling({ my_client_id: myclient.id }));
    dispatch(BillingActions.billingsRequest({ per_page: 20, page: 1, query: { my_client_id: myclient.id } }));
  }, [dispatch, myclient]);

  const handlePagePress = useCallback((page) => {
    dispatch(BillingActions.billingsRequest({ per_page: 20, page, query: { my_client_id: myclient.id } }));
  });


  return (
    <Container>
      <h1>Cliente</h1>
      <div className="horizontal">
        <p>Detalhes do novo cliente</p>
      </div>
      <OuterBox>
        <ContainerBox>
          <DisplayInfos items={diplayValues.map(item => {
            item.value = myclient[item.key];
            return item;
          })} />
        </ContainerBox>
        <ContainerBox>
          <DisplayInfos items={diplayValuesTotal.map(item => {
            item.value = totalResume[item.key];
            return item;
          })} />
          <NoteContainer>
            <div>{totalResume.note}</div>
          </NoteContainer>
        </ContainerBox>
      </OuterBox>

      <h1>Cobranças</h1>
      <div className="horizontal">
        <p>Cobranças do cliente</p>
      </div>

      {!billings.length && <p>nenhum cliente cadastrado</p>}
      {!!billings.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Total</th>
                <th>Status</th>
                <th>Total de parcelas</th>
                <th>Progresso</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {billings.map((b) => (
                <tr key={b.id}>
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        backgroundColor: theme.color[b.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {b.id}
                  </td>
                  <td>{formatToReal(b.amount)}</td>
                  <td>{b.status}</td>
                  <td>{b.installment_total}</td>
                  <td>
                    <div className={`progress`}>
                      <span className="inner" style={{ width: `${b.progress ? b.progress : "0"}px` }}></span>
                      <span className="title">{`${b.progress ? b.progress : "0"}%`}</span>
                    </div>
                  </td>
                  <td>
                    <i className="material-icons md-14">info</i>
                    <i className="material-icons md-14">edit</i>
                    <i className="material-icons md-14">delete</i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            page={page}
            pages={pages}
            total={total}
            callback={handlePagePress}
          />
        </>
      )}
    </Container>
  );
}
