import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { InputMask } from "../../Inputs";
import { formatToReal } from "../../../utils/format";
import { Formik } from "formik";
import * as Yup from "yup";

import Carousel from "react-elastic-carousel";
import { ContainerPage, ContainerPagination } from "../ClientCreate/styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { ContainerButtons, Form, SelectArea } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import DisplayInfos from "../../DisplayInfo";
import * as CryptoActions from "../../../store/modules/crypto/actions";
import ProgressBar from "react-bootstrap/ProgressBar";
import Alert from "react-bootstrap/Alert";
import { pt } from 'yup-locale-pt';
import * as GeneralActions from "../../../store/modules/general/actions";
Yup.setLocale(pt);


const buyValidSchema = Yup.object().shape({
  amount: Yup.number().required().positive(),
});

const initialErrors = {
  amount: "-",
  currency: "-",
};

const renderPagination = ({ pages, activePage, onClick }, props, success) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page;
        return (
          <li
            className={`dot ${isActivePage ? "selected" : ""} ${
              success ? "success" : ""
            }`}
            key={page}
          />
        );
      })}
    </ContainerPagination>
  );
};

const diplayValues = [
  { key: "balance_before", name: "Saldo atual", value: "", position: "row" },
  {
    key: "quantity_formated",
    name: "Quantidade",
    value: "",
    position: "row",
  },
  { key: "price", name: "Preço", value: "", position: "row" },
  {
    key: "total",
    name: "Total",
    value: "",
    position: "row",
  },
  { key: "balance_after", name: "Saldo após", value: "", position: "row" },
];

const ModalBuy = ({ visible, onClose, currency, price, time,counter }) => {
  const balance = useSelector((state) => state.account.balance);
  const [position, setPosition] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [send, setSend] = useState(false);
  const [progress, setProgress] = useState(0);
  const [variant, setVariant] = useState("info");
  const [balanceInvalid, setBalanceInvalid] = useState(false)
  var carousel = null;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { page, per_page } = useSelector((state) => state.crypto.orders);

  const initialValues = {
    amount: "0",
    currency: currency,
    price: formatToReal(parseFloat(price)),
  };

  const handleSubmitForm = (values) => {
    setSend(true);
    dispatch(
      CryptoActions.SellRequest({
        amount: parseFloat(values.amount),
        currency: currency,
        price: values.price,
        type: values.type,
        callback: (data) => {
          if (data.status == "error") {
            setVariant("danger");
            setError(true);
          }
          if (data.status == "done") {
            dispatch(
              CryptoActions.LisOrders({ per_page: per_page, page: page })
            );
            setVariant("success");
            setSuccess(true);
          }
          setProgress(100);
        },
      })
    );
  };

  useEffect(() => {
    setPosition(0);
    setSuccess(false);
    setError(false);
    setProgress(0);
    setVariant("info");
    setSend(false);
    setBalanceInvalid(false)
  }, [time]);

  const handleCancel = () => {
    onClose();
  };

  const constDefineTotal  = (props,e) => {
    let amount = parseFloat(e.target.value)
    let total = status === 1 ? parseFloat(amount) * parseFloat(price) : parseFloat(amount) * parseFloat(props.values.price.replace(/[\.^\R$\ ^\/]/gi, '')
        .replace(',', '.'))
    setBalanceInvalid(parseFloat(total)> parseFloat(balance))

  }

  const handleNextPagePosition = () => {
    carousel.goTo(position + 1);
    setPosition(position + 1);
  };
  const handleNextPage = (props) => {
    switch (position) {
      case 0:

        props.validateForm().then(validation => props.setTouched(validation))
        buyValidSchema
            .isValid({ amount:props.values.amount })
            .then((valid) => {
              if (valid) {
                let priceFloat = parseFloat(
                    props.values.price.replace(/[\.^\R$\ ^\/]/gi, "").replace(",", ".")
                );
                let total = parseFloat(props.values.amount) * priceFloat;
                // props.setFieldTouched('price', true)
                // props.setFieldValue("price", formatToReal(parseFloat(price)))
                props.setFieldTouched("total", true);
                props.setFieldValue("total", formatToReal(total));
                props.setFieldTouched("balance_after", true);
                props.setFieldValue(
                    "balance_after",
                    formatToReal(parseFloat(balance) + total)
                );
                props.setFieldTouched("balance_before", true);
                props.setFieldValue(
                    "balance_before",
                    formatToReal(parseFloat(balance))
                );
                props.setFieldTouched("quantity_formated", true);
                props.setFieldValue(
                    "quantity_formated",
                    `${parseFloat(props.values.amount).toFixed(2)} ${currency}`
                );
                handleNextPagePosition();
                return
              }
              dispatch(GeneralActions.setError({ error: "Dados inválidos" }));
            })


        break;
      case 1:
        handleSubmitForm(props.values);
        handleNextPagePosition();
        break;
      case 2:
        handleNextPagePosition();
        break;
      default:
        break;
    }
  };

  const [status, setStatus] = useState(1);

  function radioHandler(status, props) {
    switch (status) {
      case 1:
        props.setFieldValue("price", formatToReal(parseFloat(price)));
        break;
    }
    setStatus(status);
  }

  return (
    <Modal show={visible} onHide={onClose} size="md" centered>
      <Formik
        initialValues={initialValues}
        validationSchema={buyValidSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Vender {currency}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Carousel
                  showArrows={false}
                  enableSwipe={false}
                  ref={(ref) => (carousel = ref)}
                  renderPagination={(renderPropos) => {
                    return renderPagination(renderPropos, props, success);
                  }}
                >
                  <ContainerPage>
                    <Form>
                      <fieldset>
                        <legend>Valor da ordem</legend>
                        <SelectArea>
                          <div className="item">
                            <input
                              type="radio"
                              id="market"
                              name="type"
                              value="market"
                              checked={status === 1}
                              onClick={(e) => radioHandler(1, props)}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <label for="market">Preço de mercado</label>
                          </div>

                          <div className="item">
                            <input
                              type="radio"
                              id="limit"
                              name="type"
                              value="limit"
                              checked={status === 2}
                              onClick={(e) => radioHandler(2, props)}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <label for="limit">Seu preço</label>
                          </div>
                        </SelectArea>
                      </fieldset>
                    </Form>

                    <div className="field">
                      <label>Preço</label>
                      <InputMask
                        type="text"
                        name="price"
                        placeholder="preço"
                        disabled={status === 1}
                        format="money"
                        value={props.values.price}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      ></InputMask>
                       {status === 1 && (
                        <span>
                        Cotação valida até <strong>{counter} segundos</strong>
                        </span>
                      )}
                      <span>{props.touched.price && props.errors.price}</span>
                    </div>
                    <br></br>
                    <div className="field">
                      <label>Quantidade</label>
                      <InputMask
                        autocomplete="off"
                        format="crypto"
                        type="string"
                        name="amount"
                        placeholder="Quantidade"
                        value={props.values.amount}
                        onChange={
                          (e)=>{
                            props.handleChange(e)
                            constDefineTotal(props,e)
                          }
                        }
                        onBlur={props.handleBlur}
                      ></InputMask>
                      <span>{props.touched.amount && props.errors.amount}</span>
                    </div>
                    <div
                      className="field"
                      className={
                        parseFloat(props.values.amount) * parseFloat(price) >
                        parseFloat(balance)
                          ? "err"
                          : ""
                      }
                    >
                      <label>Total</label>
                      <input
                        type="text"
                        name="total"
                        placeholder="Total"
                        value={
                          status === 1
                            ? formatToReal(
                                parseFloat(props.values.amount) *
                                  parseFloat(price)
                              )
                            : formatToReal(
                                parseFloat(props.values.amount) *
                                  parseFloat(
                                    props.values.price
                                      .replace(/[\.^\R$\ ^\/]/gi, "")
                                      .replace(",", ".")
                                  )
                              )
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      {
                          balanceInvalid && (
                              <>
                                <br></br>
                                <Alert key={"danger"} variant={"danger"}>Você não tem saldo para essa operação</Alert>
                              </>
                          )
                      }
                      <span>{props.touched.total && props.errors.total}</span>
                      Saldo atual:{formatToReal(balance)}
                    </div>
                  </ContainerPage>
                  <ContainerPage>
                    <div className="field">
                      <DisplayInfos
                        items={diplayValues.map((item) => {
                          item.value = props.values[item.key];
                          return item;
                        })}
                      />
                    </div>
                  </ContainerPage>
                  <ContainerPage>
                    {variant == "info" && "Estamos processando sua transação"}
                    <ProgressBar
                      variant={variant}
                      now={progress}
                      label={`${progress}%`}
                    />
                    {variant == "danger" &&
                      "Houve um erro, favor tentar novamente."}
                    {status === 1
                      ? variant == "success" &&
                        "Sua transação foi recebida e está sendo processada."
                      : variant === "success" &&
                        "Ordens com o seu preço ficarão pendentes até serem executadas"}
                  </ContainerPage>
                </Carousel>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <ContainerButtons>
                <ButtonDefault
                  title="Fechar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
                {!send && (
                  <ButtonDefault
                    title="Próximo"
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    width="170px;"
                    onClick={() => handleNextPage(props, handleSubmitForm)}
                    disabled={balanceInvalid}
                  />
                )}
              </ContainerButtons>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalBuy;
