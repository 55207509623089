import styled from "styled-components";

export const Wave = styled.div`
  position: absolute;
  left: -100px;
  height: 200vh;
  width: 120vw;
  background: #8cc1c4;
  transform: rotateZ(20deg) translateY(40vh) translateX(-10vw);
  &:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 90vw;
    height: 260px;
    background-color: #8cc1c4;
    right: -20vw;
    top: -15vh;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 90vw;
    height: 200px;
    background-color: #fff;
    left: -32vw;
    top: -80px;
  }
  .block {
    background-color: #8cc1c4;
    width: 200%;
    height: 100%;
    position: absolute;
    right: -200%;
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const Card = styled.div`
  padding: 20px;
  min-height: 350px;
  width: 220px;
  align-self: center;
  margin-top: 20vh;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  -webkit-box-shadow: 4px 2px 42px -18px #000000;
  box-shadow: 4px 2px 42px -18px #000000;
  align-items: center;
  h1 {
    font-size: 16px;
    color: #8cc1c4;
    cursor: pointer;
  }
  p {
    margin-top: 20px;
    text-align: center;
    color: ${({ theme }) => theme.grayText};
  }
  .check-icon {
    background-color: #ffb540;
    width: 85px;
    height: 85px;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border-radius: 50%;
    color: #fff;
    align-self: center;
    margin-top: 20px;
  }
`;
