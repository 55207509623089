import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, ContainerPage, ContainerPagination } from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as TransferActions from "../../../store/modules/transfer/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import Carousel from "react-elastic-carousel";
import CurrencyInput from "react-currency-input-field";
import DisplayInfos from "../../DisplayInfo";
import ReactCodeInput from "react-code-input";
const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success
) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page;
        return (
          <li
            className={`dot ${isActivePage ? "selected" : ""} ${
              success ? "success" : ""
            }`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props);
            }}
          />
        );
      })}
    </ContainerPagination>
  );
};

const AmountValidSchema = Yup.object().shape({
  amount: Yup.string().required("campo obrigatório"),
});

const initialValues = {
  amount: "",
  code: "",
};

const initialErrors = {
  amount: "-",
  code: "",
};

const diplayValues = [
  { key: "id", name: "id", value: "", position: "column" },
  {
    key: "pix_key_type",
    name: "Tipo da chave",
    value: "",
    position: "row",
  },
  { key: "pix_key", name: "Chave pix", value: "", position: "row" },
  {
    key: "name",
    name: "Nome",
    value: "",
    position: "row",
  },
  {
    key: "amount",
    name: "Valor",
    value: "",
    position: "column",
    type: "money",
  },
];
const MyClientCreate = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [position, setPosition] = useState(0);

  let { id } = useParams();

  var carousel = null;

  const [success, setSuccess] = useState(false);
  const { transfer } = useSelector((state) => state.transfer);

  const { myclient } = useSelector((state) => state.myclient);

  useEffect(() => {}, [dispatch]);

  var listRef = null;

  const handleCancel = () => {
    history.push("/financeiro/transferencia");
  };

  useEffect(() => {
    if (transfer != undefined) {
      console.log(transfer);
    }
  }, [transfer]);

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1);
    setPosition(position - 1);
  };
  const handleNextPage = (props) => {
    console.log(position);
    switch (position) {
      case 0:
        carousel.goTo(position + 1);
        setPosition(position + 1);
        break;
      case 1:
        handleSubmitForm(props.values);
        break;
    }
  };

  const handleSubmitCode = (code) => {
    console.log(code);
    dispatch(
      TransferActions.ConfirCode({
        code,
        id: transfer.id,
        callback: () => setSuccess(true),
      })
    );
  };
  const handleNewCodeRequest = () => {
    dispatch(
      TransferActions.ResendCode({
        id: transfer.id,
        callback: () => {},
      })
    );
  };

  const handleSubmitForm = (values) => {
    dispatch(
      TransferActions.ConfirCode({
        code: values.code,
        id: transfer.id,
        callback: () => setSuccess(true),
      })
    );
  };

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">Cliente criado com sucesso</div>
          </div>
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={AmountValidSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            <Carousel
              showArrows={false}
              ref={(ref) => (carousel = ref)}
              renderPagination={(renderPropos) => {
                return renderPagination(
                  renderPropos,
                  props,
                  handlePrevPage,
                  success
                );
              }}
            >
              <ContainerPage>
                <DisplayInfos
                  items={diplayValues.map((item) => {
                    item.value = transfer[item.key];
                    return item;
                  })}
                />
              </ContainerPage>
              <ContainerPage>
                <div className="field">
                  <h2>Código de segurança para aprovar</h2>
                  <ReactCodeInput
                    disabled={false}
                    type="text"
                    fields={6}
                    pattern={/[0-9]/}
                    onChange={(v) => {
                      props.setFieldValue("code", v);
                    }}
                  />
                  <p>Digite o código de segurança que você recebeu no email</p>
                  <br></br>
                  <p
                    onClick={handleNewCodeRequest}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    reenviar código
                  </p>
                </div>
              </ContainerPage>
            </Carousel>
            <div className="container-buttons">
              <div className="horizontal">
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
                <ButtonDefault
                  title="Próximo"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={() => handleNextPage(props, handleSubmitForm)}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default MyClientCreate;
