import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  min-height: 320px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 400px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
`;

export const ContainerTitle = styled.div`
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 16px;
  }
`;

export const ContainerList = styled.div`
  flex-direction: row;
  overflow: show;
  margin-top: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
  .card-container {
    cursor: pointer;
    margin-left: 5%;
  }
`;

export const ContainerSaldo = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  align-self: center;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.white2};

  h1 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
  }
`;
