export function transferGet({ id,type }) {
  return {
    type: "@transfer/TRANSFER_GET",
    payload: { id,type },
  };
}

export function transfersRequest({ per_page, page, query }) {
  return {
    type: "@transfer/TRANSFERS_REQUEST",
    payload: { per_page, page, query },
  };
}

export function transfersRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@transfer/TRANSFERS_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}

export function banksRequest({ per_page, page, query }) {
  return {
    type: "@transfer/BANKS_REQUEST",
    payload: { per_page, page, query },
  };
}

export function banksRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@transfer/BANKS_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}

export function newTransfer({
  name,
  account,
  amount,
  branch,
  bank_number,
  document,
  transfer_type,
  pix_key,
  pix_type,
  callback,
}) {
  return {
    type: "@transfer/NEW_TRANSFER",
    payload: {
      name,
      account,
      amount,
      branch,
      bank_number,
      document,
      transfer_type,
      pix_key,
      pix_type,
      callback,
    },
  };
}

export function Detail(tranfer) {
  return {
    type: "@tranfer/DEFINE_DETAIL",
    payload: tranfer,
  };
}

export function ResendCode({ id, callback }) {
  return {
    type: "@tranfer/RESEND_CODE",
    payload: { id, callback },
  };
}

export function ConfirCode({ id, code, callback }) {
  return {
    type: "@tranfer/CONFIRM",
    payload: { id, code, callback },
  };
}

export function accountsRequest({ per_page, page, query }) {
  return {
    type: "@transfer/ACCOUNTS_REQUEST",
    payload: { per_page, page, query },
  };
}

export function accountsRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@transfer/ACCOUNTS_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}

export function newAccount({
  name,
  account,
  branch,
  bank_number,
  document,
  callback,
  supplier_id,
}) {
  return {
    type: "@transfer/NEW_ACCOUNT",
    payload: {
      name,
      account,
      branch,
      bank_number,
      document,
      supplier_id,
      callback,
    },
  };
}
