import styled, { keyframes } from 'styled-components'


import colors from '../../../styles/colors'


export const ContainerPagination = styled.div`
  display: block;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
  & .dot{
    width:29px;
    height:29px;
    margin: 10px;
    border: #707070 solid 1px;
    border-radius: 20px;
    &.selected{
      background:#000000
    }
  }
`

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`



export const ContainerPage = styled.div`

input {
  border-bottom: none;
}

input:focus {
  padding: 4px;
}


.slider {
  /* -webkit-appearance: none; */
  width: 100%;
  height: 2px;
  background: #333333;
  outline: none;
  opacity: 0.7;
}

.value {
  color: #d3d3d3;
  position: relative;
  top: 20px;
}
.min.value {
  left: 20px;
}
.max.value {
  left: -20px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  border-radius: 50%;
  border: 2px solid #999; /* Green 
  background */
  background: #fff;
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  border-radius: 50%;
  background: #4caf50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
`


export const Container = styled.div`
  display: flex;
  flex: 1;
  animation: ${animationName} 0.8s;
  display: grid;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: 'Roboto-Bold', sans-serif;
    color: ${colors.grayText};
  }

  .autentic-p {
    color: #666666;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-self: center;
    padding-top: 8px;
    margin: 0;
  }

  .autentic-span {
    color: #919090;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-self: center;
    padding-bottom: 8px;
  }

  tr {
    td.payment-type {
      & div {
        width: auto;
      }
    }
    .actions {
      display: table-cell;
      .button {
        width: 33%;
        display: table-cell;
        i {
          font-size: 18px;
        }
      }
    }
  }
  tr.done {
    .button {
      pointer-events: none;
      opacity: 0.2;
    }
  }
`;

export const ContainerDiv = styled.div`

border-bottom: 2px dashed #C2C2C2;

  .header-modal {
    margin: 32px 0;
  }

  img {
    height: 50px;
    width: 200;
    display: flex;
    align-self: center;
  }

  .subcontainer-modal {
    height: 280px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px;
  }

  .title {
    text-transform: uppercase;
    color: #5B5B5B;
    font-weight: bold;
  }

  .subtitle {
    text-transform: uppercase;
    color: #433F3F;
    font-weight: bold;
    margin: 0;
  }

  .document {
    color: #8F8E8E;
    font-weight: bold;
    margin: 0;
    margin-bottom: 32px;
  }

  .value {
    color: #5B5B5B;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    margin-bottom: 32px;
  }

  .info {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    /* margin-top: 42px; */

    .title-2 {
      color: #5B5B5B;
      font-weight: bold;
    margin: 0;
    }

    .subtitle-2 {
      color: #8F8E8E;
      font-weight: bold;
    margin: 0;

    }
    
  }
`