import axios from "axios";

const url = process.env.REACT_APP_CUSTOMER_URL;

const apiCep = {
  GetRefService: () => {
    return axios.create({
      baseURL: url
    });
  },

  getAddress: cep => {
    return apiCep.GetRefService().get(`https://viacep.com.br/ws/${cep}/json`);
  }
};

export default apiCep;
