import { all, takeLatest, put, call, select } from "redux-saga/effects";
import history from "../../../services/history";
import apiPayment from "../../../services/apiPayment";
import GetErrors from "../../../services/errors";
import * as BillingActions from "./actions";
import * as GeneralActions from "../general/actions";
import moment from "moment";

export function* billingRequest(action) {
  try {
    const { per_page, page, query } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiPayment.post, "/payment/partner/v1/web/billings", {
      per_page,
      page,
      query,
    });
    yield put(BillingActions.billingsRequestSuccess(data));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* pendingBillingRequest(action) {
  try {
    const { per_page, page } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));

    const { data } = yield call(apiPayment.post, "/payment/partner/v1/web/billing/children", {
      per_page,
      page,
      query: {
        from_expired: moment().subtract(10, "day").unix(),
        to_expired: moment().add(30, "day").unix(),
        status: "create",
      },
    });
    yield put(BillingActions.billingsRequestSuccess(data));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* newBilling(action) {
  try {
    const { callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));

    const { data } = yield call(apiPayment.post, "/payment/partner/v1/web/billing", action.payload);
    const { billings } = data;
    yield put(BillingActions.newBillingSuccess({ billings }));
    if (callback) {
      callback();
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeLatest("@billing/BILLING_REQUEST", billingRequest),
  takeLatest("@billing/PENDING_BILLING_REQUEST", pendingBillingRequest),
  takeLatest("@billing/NEW_BILLING", newBilling),
]);
