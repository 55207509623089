import axios from "axios";
import { getToken } from "./auth";

const url = process.env.REACT_APP_URL;

const apiCustomer = axios.create({
  baseURL: url
});

apiCustomer.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiCustomer;
