
export function payBillsRequest({ per_page, page }) {
  return { type: "@paybill/REQUEST", payload: { per_page, page } };
}


export function payBillCheckRequest({ digitable_line, callback }) {
  return { type: "@paybill/REQUEST_CHECK", payload: { digitable_line, callback } };
}

export function payBillCheckSuccess({
  digitable_line,
  settleDate,
  dueDate,
  assignor,
  amount,
  have_balance,
                                      issuer_name,
                                      payer_name,

}) {
  return {
    type: "@paybill/REQUEST_CHECK_SUCCESS",
    payload: {
      digitable_line,
      settleDate,
      dueDate,
      assignor,
      amount,
      have_balance,
      issuer_name,
      payer_name,
    }
  };
}


export function payBillsRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@paybull/REQUEST_SUCCESS",
    payload: { data, page, pages, total }
  };
}

export function newPayBill({
  callback,
  digitable_line
}) {
  return {
    type: "@paybill/NEW",
    payload: {
      callback,
      digitable_line
    }
  };
}

export function newPayBillDone() {
  return {
    type: "@paybill/NEW_DONE",
    
  };
}

export function Get({ id }) {
  return {
    type: "@paybill/PAYBILL_GET",
    payload: { id },
  };
}



export function Detail(paybill) {
  return {
    type: "@paybill/DEFINE_DETAIL",
    payload: { paybill },
  };
}
