import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  ContainerRigthModal,
  LabelRightMenu,
  ContentRightMenu,
  HeaderRigthModal,
  CloseIconHeader,
  RigthModal,
  LabelType,
  DateLabel,
  ButtonDownload,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from '../../../utils/format'
import * as TransferActions from '../../../store/modules/transfer/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Paginator from '../../../components/Paginator'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import * as DepositActions from '../../../store/modules/deposit/actions'
import * as UserActions from '../../../store/modules/user/actions'
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from '@react-pdf/renderer'
import '../../../assets/animations/animations.css'
import CloseIcon from '../../../assets/images/close.png'
import logoIcon from '../../../assets/images/packman-white.png'

export default function Transaction() {
  const [rightMenu, setRightMenu] = useState(false)
  const [rightMenuInfo, setRightMenuInfo] = useState([])
  const [selectType, setSelectType] = useState('transfer')

  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { transfers, page, pages, per_page, total } = useSelector(
    (state) => state.transfer,
  )

  const { balance, balance_block } = useSelector((state) => state.account)
  const { transfer } = useSelector((state) => state.transfer)
  const { deposit } = useSelector((state) => state.deposit)
  const account = useSelector((state) => state.account.account)
  const company = useSelector((state) => state.user.company)
  const [transferItens, settransferItens] = useState([])
  const [transferSelected, setTransferSelected] = useState({})

  useEffect(() => {
    dispatch(AccountActions.balanceRequest())
    dispatch(AccountActions.GetAccount())
    // dispatch(UserActions.GetInfo())
  }, [dispatch])
  useEffect(() => {
    if (transfers.length > 0) {
      let dataFormated = []
      transfers.forEach((item) => {
        let checktIndex = dataFormated.findIndex(
          (el) => el.key == formatDate(item.createdAt),
        )
        if (checktIndex > -1) {
          dataFormated[checktIndex].data.push(item)
        } else {
          dataFormated.push({ key: formatDate(item.createdAt), data: [item] })
        }
      })

      settransferItens(dataFormated)
    }
  }, [transfers])

  useEffect(() => {
    dispatch(TransferActions.transfersRequest({ per_page: per_page, page: 1 }))
  }, [dispatch])

  const handlePagePress = useCallback((event, value) => {
    dispatch(
      TransferActions.transfersRequest({ per_page: per_page, page: value }),
    )
  })

  const renderView = (type) => {
    return renderTransfer()
  }
  const renderTransfer = () => (
    <>
      <HeaderRigthModal>
        {' '}
        <CloseIconHeader
          src={CloseIcon}
          alt="close menu"
          onClick={() => {
            setRightMenu(false)
          }}
        />{' '}
      </HeaderRigthModal>
      <RigthModal>
        <h1> Detalhes </h1>
        <LabelType className="transfer">Enviado</LabelType>
        <LabelRightMenu> Tipo: </LabelRightMenu>
        <ContentRightMenu> {transferSelected.type} </ContentRightMenu>
        <LabelRightMenu> Data: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {formatDate(transferSelected.updatedAt)}{' '}
        </ContentRightMenu>
        <LabelRightMenu> Nome do Destino: </LabelRightMenu>
        <ContentRightMenu> {transferSelected.name} </ContentRightMenu>

        <LabelRightMenu> Valor: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {formatToReal(parseFloat(transferSelected.amount))}{' '}
        </ContentRightMenu>

        <LabelRightMenu> Documento: </LabelRightMenu>
        <ContentRightMenu> {transferSelected.document} </ContentRightMenu>

        <LabelRightMenu> Instituição: </LabelRightMenu>
        <ContentRightMenu> {transferSelected.bank_name} </ContentRightMenu>

        <LabelRightMenu> Origem: </LabelRightMenu>
        <ContentRightMenu> {company.name} </ContentRightMenu>

        <LabelRightMenu> CNPJ: </LabelRightMenu>
        <ContentRightMenu> {formatCnpj(company.document)} </ContentRightMenu>
        <LabelRightMenu> ID da transação: </LabelRightMenu>
        <ContentRightMenu> {transferSelected.integration_id} </ContentRightMenu>
        <ContentRightMenu>
          {transferSelected.integration_id && (
            <PDFDownloadLink
              document={
                <DocumentTransfer
                  transfer={transfer}
                  company={company}
                  account={account}
                />
              }
              fileName="deposit.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  'Loading document...'
                ) : (
                  <ButtonDownload
                    title="Baixar"
                    background={theme.color.green}
                    color={theme.color.white}
                    height="50px"
                    href={url}
                    target="_blank"
                    download={transfer.protocol}
                  >
                    Baixar
                  </ButtonDownload>
                )
              }
            </PDFDownloadLink>
          )}
        </ContentRightMenu>
      </RigthModal>
    </>
  )
  const handleNewPress = () => {
    history.push('/financeiro/transferencia/novo')
  }

  const handleInfoRightMenu = (info) => {
    setSelectType('transfer')
    setRightMenu(true)
    setRightMenuInfo(info)
    setTransferSelected(info)
    // history.push("/transferencia/novo");
  }

  const DocumentTransfer = ({ transfer, company, account }) => {
    return (
      <Document>
        <Page
          size="A4"
          style={{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 }}
        >
          <View>
            <Image
              style={{
                height: 50,
                width: 50,
                backgroundColor: 'black',
              }}
              src={logoIcon}
            />
          </View>
          <Text style={{ fontSize: 24, textAlign: 'left', marginTop: 12 }}>
            Comprovante de Transferência
          </Text>
          <Text style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}>
            {' '}
            Valor:{' '}
          </Text>
          <Text style={{ marginTop: 4, fontSize: 10 }}>
            {' '}
            {formatToReal(transferSelected.amount)}{' '}
          </Text>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Conta Origem:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  De:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {company.name}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Documento:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {formatCnpj(company.document)}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Instituição:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {account.bank_name}{' '}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Conta Destino:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  Para:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {transferSelected.name}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Documento:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {transferSelected.document}{' '}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Instituição:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {transferSelected.bank_name}{' '}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Informações:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  Tipo:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {transferSelected.type}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Data:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {formatDateHour(transferSelected.updatedAt)}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  ID da transação:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {transferSelected.integration_id}{' '}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  }
  const DocumentDeposit = ({ deposit, company, account }) => {
    return (
      <Document>
        <Page
          size="A4"
          style={{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 }}
        >
          <View>
            <Image
              style={{
                height: 50,
                width: 50,
                backgroundColor: 'black',
              }}
              src={logoIcon}
            />
          </View>
          <Text style={{ fontSize: 24, textAlign: 'left', marginTop: 12 }}>
            Comprovante de Transferência
          </Text>
          <Text style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}>
            {' '}
            Valor:{' '}
          </Text>
          <Text style={{ marginTop: 4, fontSize: 10 }}>
            {' '}
            {formatToReal(parseFloat(deposit.amount))}{' '}
          </Text>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Conta Origem:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  De:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.sender_name}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Documento:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.sender_document}{' '}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Instituição:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.bank_name}{' '}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Conta Destino:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  Para:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {company.name}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Documento:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {formatCnpj(company.document)}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Instituição:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {account.bank_name}{' '}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                {' '}
                Informações:{' '}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  Tipo:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.type}{' '}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  Data:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {formatDateHour(deposit.updatedAt)}
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexWrap: 'wrap' }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                >
                  {' '}
                  ID da transação:{' '}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {' '}
                  {deposit.integration_id}{' '}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <ContainerItens>
      <Container>
        <h1>Transferência</h1>
        <div className="horizontal">
          <p>Lista de transferência</p>
          <ButtonDefault
            type="button"
            title="Novo"
            background={theme.color.green}
            color={theme.color.white}
            width="250px"
            height="50px"
            onClick={handleNewPress}
          />
        </div>

        <Content>
          <div className="table">
            {!transferItens.length && <p>nenhuma transferência realizada</p>}
            {!!transferItens.length && (
              <>
                {transferItens.map((item, index) => (
                  <>
                    <DateLabel key={index}>{item.key}</DateLabel>
                    {item.data.map((b, indexData) => (
                      <TransactionItem key={indexData}>
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{
                              backgroundColor: theme.color['out'] || '#fff',
                            }}
                          ></div>
                        </div>
                        <div className="info">
                          <span className="title">{b.name}</span>
                          <span className="subtitle"> {b.type}</span>
                        </div>
                        <div className={`price`}>
                          {' '}
                          <span className={`amount ${b.operation_direct}`}>
                            {formatToReal(parseFloat(b.amount))}
                          </span>
                          <span className="clock">
                            {' '}
                            {formatHour(b.updatedAt)}{' '}
                          </span>
                        </div>
                        <div
                          className="action"
                          onClick={() => {
                            handleInfoRightMenu(b)
                          }}
                        >
                          <i className="material-icons icon">navigate_next</i>
                        </div>
                      </TransactionItem>
                    ))}
                  </>
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
          <div className="balance">
            <div className="content">
              <div className="item">
                <div className="title">Saldo atual:</div>
                <div className="subtitle">{formatToReal(balance)}</div>
              </div>
              <div className="item">
                <div className="title">Saldo bloqueado:</div>
                <div className="subtitle">{formatToReal(balance_block)}</div>
              </div>
            </div>
          </div>
        </Content>
      </Container>

      <ContainerRigthModal className={rightMenu ? 'show-right-menu' : ''}>
        {renderView(selectType)}
      </ContainerRigthModal>
    </ContainerItens>
  )
}
