import { all, takeLatest, put, call, select } from "redux-saga/effects";
import history from "../../../services/history";
import apiCostumer from "../../../services/apiCostumer";
import apiPayment from "../../../services/apiPayment";
import GetErrors from "../../../services/errors";
import * as EmplyeeActions from "./actions";
import * as GeneralActions from "../general/actions";
import moment from "moment";

export function* EmployeesRequest(action) {
  try {
    const { per_page, page } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    const { data } = yield call(
      apiCostumer.post,
      "/employees",
      { per_page, page },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(EmplyeeActions.EmployeesRequestSuccess(data));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* newEmplyee(action) {
  try {
    const { callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    yield call(apiCostumer.post, "/employee", action.payload, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    yield put(EmplyeeActions.EmployeesRequest({ page: 1, per_page: 3 }));
    if (callback) {
      callback();
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeLatest("@employee/LIST_REQUEST", EmployeesRequest),
  takeLatest("@employee/NEW", newEmplyee),
]);
