import React, { useState, useEffect, CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, ContainerPage, ContainerPagination } from './styles'
import { ButtonDefault } from '../../Buttons'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as CompanyActions from '../../../store/modules/company/actions'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead'
import { InputMask } from '../../Inputs'
import { cpfIsValid } from '../../../utils/check'
import Carousel from 'react-elastic-carousel'
import apiCep from '../../../services/apiCEP.js'
import * as GeneralActions from '../../../store/modules/general/actions'
import { setOnClipboard } from "../../../utils/clipboard";
import DisplayInfos from '../../DisplayInfo'
import {Alert} from "react-bootstrap";
import Form from 'react-bootstrap/Form';


const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success,
) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${
              success ? 'success' : ''
            }`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props)
            }}
          />
        )
      })}
    </ContainerPagination>
  )
}

const ClientSchema = Yup.object().shape({
  email: Yup.string().required('campo obrigatório'),
})

const initialValues = {
'email' :"",
'name' :"",
'bank_deposit' :true,
'crypto_deposit' :false,
'crypto_withdraw' :false,
'crypto_buy' :false,
'crypto_sell' :false,
'buy_price_spreed' :"",
'sell_price_spreed' :"",
}

const initialErrors = {
  'email' :"-",
  'name' :"-",
  'bank_deposit' :"-",
  'crypto_deposit' :"-",
  'crypto_withdraw' :"-",
  'crypto_buy' :"-",
  'crypto_sell' :"-",
  'buy_price_spreed' :"-",
  'sell_price_spreed' :"-",
}

const diplayValues = [
  { key: 'email', name: 'email', value: '', position: 'row' },
  { key: 'name', name: 'Nome', value: '', position: 'row' },
  { key: 'bank_deposit', name: 'Depositar Pix', value: '', position: 'row',type:"bool" },
  { key: 'crypto_deposit', name: 'Depósitar moedas digitais', value: '', position: 'row' , type:"bool" },
  { key: 'crypto_withdraw', name: 'Sacar moedas digitais', value: '', position: 'row' , type:"bool" },
  { key: 'crypto_buy', name: 'Comprar moedas digitais', value: '', position: 'row' , type:"bool" },
  { key: 'crypto_sell', name: 'Vender moedas digitais', value: '', position: 'row' , type:"bool" },
  { key: 'buy_price_spreed', name: 'Spreed na compra de moedas', value: '', position: 'row' },
  { key: 'sell_price_spreed', name: 'Spreed na venda de moedas', value: '', position: 'row' },

]

const MyClientCreate = ({ navigation }) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [position, setPosition] = useState(0)
  const [company, setCompany] = useState({})

  var carousel = null

  const [success, setSuccess] = useState(false)

  const { bank } = useSelector((state) => state.transfer)

  useEffect(() => {}, [])

  var listRef = null

  const handleCancel = () => {
    history.push('/crm/sub-conta')
  }

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }
  const handleNextPage = (props) => {
    switch (position) {
      case 0:
        const { name,email } = props.values
        props.validateForm().then((validation) => props.setTouched(validation))
        ClientSchema.isValid({ name,email }).then((valid) => {
          if (valid) {
            carousel.goTo(position + 1)
            setPosition(position + 1)
            return
          }
          dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
        })
        break
      case 1:
        handleSubmitForm(props.values)
        break
      case 2:
        break
      default:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        break
    }
  }

  const handleSubmitForm = (values) => {
    values.buy_price_spreed = parseFloat(values.buy_price_spreed)
    values.sell_price_spreed = parseFloat(values.sell_price_spreed)
    dispatch(
      CompanyActions.newCompany({
        ...values,
        callback: ({user}) => {
          setSuccess(true)
          setCompany(user)
        },
      }),
    )
  }

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">Sub-conta criado com sucesso</div><br></br>
            <div className="sub-title">Usuário para acesso: {company.user_name}</div>
            <div className="sub-title">Nome: {company.name}</div>
            <div className="sub-title">email : {company.email}</div>
            <div className="sub-title">Senha : ******</div>
          </div>
          <br></br>
          <ButtonDefault
            title="voltar"
            background={theme.color.blackButton}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={ClientSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            <Carousel
              showArrows={false}
              ref={(ref) => (carousel = ref)}
              renderPagination={(renderPropos) => {
                return renderPagination(
                  renderPropos,
                  props,
                  handlePrevPage,
                  success,
                )
              }}
            >
              <ContainerPage>
                <div className="field">
                  <input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.name && props.errors.name}</span>
                </div>
                <div className="field">
                  <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.email && props.errors.email}</span>
                </div>
                <br></br>
                <h3>Permisões de acesso</h3>
                <br></br>
                <h2>Banco</h2>
                <div className="field">
                  <Form.Check
                      name="bank_deposit"
                      type="switch"
                      label="Depósitar pix"
                      value={props.values.bank_deposit}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      defaultChecked={true}
                  />
                </div>
                <br></br>
                <h2>Moedas digitais</h2>
                <div className="field">
                  <Form.Check
                      name="crypto_deposit"
                      type="switch"
                      label="Depósitar moedas digitais"
                      value={props.values.crypto_deposit}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      defaultChecked={false}
                  />
                  <Form.Check
                      name="crypto_withdraw"
                      type="switch"
                      label="Saquar moedas digitais"
                      value={props.values.crypto_withdraw}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      defaultChecked={false}
                  />
                  <Form.Check
                      name="crypto_buy"
                      type="switch"
                      label="Comprar moedas digitais"
                      value={props.values.crypto_buy}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      defaultChecked={false}
                  />
                  <Form.Check
                      name="crypto_sell"
                      type="switch"
                      label="Vender moedas digitais"
                      value={props.values.crypto_sell}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      defaultChecked={false}
                  />
                </div>

                <div className="field">
                  <input
                      type="text"
                      name="sell_price_spreed"
                      placeholder="Spreed na venda de moedas"
                      value={props.values.sell_price_spreed}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.sell_price_spreed && props.errors.sell_price_spreed}</span>
                </div>
                <div className="field">
                  <input
                      type="text"
                      name="buy_price_spreed"
                      placeholder="Spreed na compra de moedas"
                      value={props.values.buy_price_spreed}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.name && props.errors.name}</span>
                </div>

                <br></br>
                <div className="field">
                  <Alert key={"warning"} variant={"warning"}>  O usuário e senha para acesso será enviado por e-mail</Alert>
                </div>
              </ContainerPage>
              <ContainerPage>
                {position == 1 && (
                  <DisplayInfos
                    items={diplayValues.map((item) => {
                      item.value = props.values[item.key]
                      return item
                    })}
                  />
                )}
              </ContainerPage>
            </Carousel>
            <div className="container-buttons">
              <div className="horizontal">
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
                <ButtonDefault
                  title="Próximo"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={() => handleNextPage(props, handleSubmitForm)}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  )
}

export default MyClientCreate
