import styled from "styled-components";

export const Container = styled.div`
  flex-direction: row;
  width: 300px;
  min-height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  right: 20px;
  top: ${({ show }) => (show ? "50px" : "-200px")};
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 38px -16px #000000;
  box-shadow: 5px 5px 38px -16px #000000;
  z-index: 9999999999;
  cursor: pointer;

  .toast-message {
    flex: 1;
  }

  .toast-icon {
    width: 20px;
    height: 20px;
  }

  .toast-message {
    font-size: 14px;
  }
`;
