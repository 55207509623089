import React, { useMemo, useState } from 'react'
import {
  ContainerWrapper,
  ContainerChildren,
  Scroll,
  ContainerMenu,
  List,
  ListItem,
  Header,
  ImageContainer,
  ContainerMenuBottom,
  ListItemBottom,
  ListBottom
} from './styles'
import { HeaderUserInformations } from '../../components/Headers'
import { useHistory } from 'react-router-dom'
import logoIcon from '../../assets/images/g-white.png'
import {useSelector} from "react-redux";

function Menu({ children }) {
  const { pathname } = useHistory().location
  const [hide, setHide] = useState(false)
  const {partner} = useSelector((state) => state.user)
  return (
    <ContainerWrapper>
      <ContainerMenu>
        <ImageContainer
          onClick={() => {
            setHide(!hide)
          }}
        >
          <img className="logo" src={logoIcon} alt="icone banco paggo" width="60"/>
        </ImageContainer>

        <List className={hide ? 'hide' : ''}>
          <ListItem current={+pathname.includes('/home')} to="/home">
            <div className="flag-marked"></div>
            <i className="material-icons">home</i>
            <p>home</p>
          </ListItem>
          {partner.crypto_module == "s" && (
              <ListItem current={+pathname.includes('/digitalmoney')} to="/digitalmoney">
                <div className="flag-marked"></div>
                <i className="material-icons">attach_money</i>
                <p>DigitalMoney</p>
              </ListItem>
          )}

          <ListItem current={+pathname.includes('/crm')} to="/crm">
            <div className="flag-marked"></div>
            <i className="material-icons">recent_actors</i>
            <p>CRM</p>
          </ListItem>
          {/* <ListItem
            current={+pathname.includes('/conveniencia')}
            to="/conveniencia"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">store</i>
            <p>conveniência</p>
          </ListItem> */}
          <ListItem
            current={+pathname.includes('/invoice')}
            to="/invoice"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">receipt</i>
            <p>invoice</p>
          </ListItem>
          
          <ListItem
            current={+pathname.includes('/financeiro')}
            to="/financeiro"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">assessment</i>
            <p>Financeiro</p>
          </ListItem>

          <ListItem
            current={+pathname.includes('/financeiro/movimentacao')}
            to="/financeiro/movimentacao"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">sync_alt</i>
            <p>Extrato</p>
          </ListItem>
          {/*<ListItem current={+pathname.includes("/gestao")} to="/gestao">*/}
          {/*  <div className="flag-marked"></div>*/}
          {/*  <i className="material-icons">assignment</i>*/}
          {/*  <p>Gestão</p>*/}
          {/*</ListItem>*/}
        </List>
      </ContainerMenu>
      <ContainerChildren>
        <HeaderUserInformations />
        <Scroll>{children}</Scroll>
      </ContainerChildren>
      <ContainerMenuBottom>
        <ListBottom className={hide ? 'hide' : ''}>
          <ListItemBottom current={+pathname.includes('/home')} to="/home">
            <div className="flag-marked"></div>
            <i className="material-icons">home</i>
            <p>home</p>
          </ListItemBottom>
          {partner.crypto_module == "s" && (
              <ListItemBottom current={+pathname.includes('/digitalmoney')} to="/digitalmoney">
                <div className="flag-marked"></div>
                <i className="material-icons">attach_money</i>
                <p>DigitalMoney</p>
              </ListItemBottom>
          )}

          <ListItemBottom current={+pathname.includes('/crm')} to="/crm">
            <div className="flag-marked"></div>
            <i className="material-icons">recent_actors</i>
            <p>CRM</p>
          </ListItemBottom>
          <ListItemBottom
            current={+pathname.includes('/user/changepass')}
            to="/user/changepass"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">account_circle</i>
            <p>Meu Perfil</p>
          </ListItemBottom>
          <ListItemBottom
            current={+pathname.includes('/financeiro')}
            to="/financeiro"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">assessment</i>
            <p>Financeiro</p>
          </ListItemBottom>
          <ListItemBottom
            current={+pathname.includes('/financeiro/movimentacao')}
            to="/financeiro/movimentacao"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">sync_alt</i>
            <p>Extrato</p>
          </ListItemBottom>
        </ListBottom>
      </ContainerMenuBottom>
    </ContainerWrapper>
  )
}

export default Menu
