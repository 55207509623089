import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import apiCustomer from '../../../services/apiCustomer'
import history from '../../../services/history'
import getError from '../../../services/errors'
import * as UserActions from './actions'
import * as GeneralActions from '../general/actions'

export function* ResetPassword(action) {
  let { email, callback } = action.payload
  yield put(GeneralActions.setLoading({ loading: true }))
  try {
    const { data } = yield call(
      apiCustomer.post,
      `/partner/auth/reset-password`,
      {
        email,
      },
    )
    if (callback != null) {
      callback(data)
    }
    yield put(GeneralActions.setLoading({ loading: false }))
  } catch (error) {
    let code = error.response?.data?.code || '0'
    const errorMessage = getError('PT_BR', code)
    if (callback != null) {
      callback(undefined, `${errorMessage} - código ${code}`)
    }
    yield put(GeneralActions.checkErrorAndResolve({ error }))

    yield put(GeneralActions.setLoading({ loading: false }))
  } finally {
  }
}

export function* login(action) {
  try {
    const { username, password } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const response = yield call(apiCustomer.post, '/partner/auth/signin', {
      email: username,
      password,
    })
    const { user, token, partner } = response.data
    yield put(UserActions.loginSuccess({ user, token,partner }))
    switch (partner.step_register) {
      default:
        return history.push('/home')
      // case 0:
      //   return history.push("/cadastro/representante");
      // case 1:
      //   return history.push("/cadastro/empresa");
      // case 2:
      //   return history.push("/cadastro/endereco");
      // case 3:
      //   return history.push("/cadastro/documento/pessoal");
      // case 4:
      //   return history.push("/cadastro/documento/empresa");
      // case 5:
      //   return history.push("/cadastro/codigo");
      // case 6:
      // case 7:
      //   return history.push("/cadastro/confirmacao");
      // case 8:
      //   return history.push("/home");
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* getInfo(action) {
  try {
    const response = yield call(apiCustomer.get, '/partner/v1/info')

    yield put(UserActions.GetInfoSuccess(response.data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* ChangePassword(action) {
  let { password, new_password, callback } = action.payload
  console.log({password, new_password, callback})
  yield put(GeneralActions.setLoading({ loading: true }))
  try {
    const { data } = yield call(
      apiCustomer.post,
      `/partner/v1/user/change-password`,
      {
        password,
        new_password,
      },
    )
    if (callback != null) {
      callback(data)
    }
    yield put(GeneralActions.setLoading({ loading: false }))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export default all([
  takeLatest('@user/LOGIN_REQUEST', login),
  takeLatest('@user/GET_INFO', getInfo),
  takeLatest('@user/RESET_PASSWORD', ResetPassword),
  takeLatest('@user/CHANGE_PASSWORD', ChangePassword),
])
