export function InviteRequest({ per_page, page }) {
  return { type: "@invite/LIST_REQUEST", payload: { per_page, page } };
}

export function InviteRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@invite/LIST_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}


export function InviteDetail({ invite }) {
  console.log(invite);
  return {
    type: "@invite/DEFINE_DETAIL",
    payload: { invite },
  };
}




export function newInvite({
  email,
  callback,
}) {
  return {
    type: "@invite/NEW",
    payload: {
      email,
      callback,
    },
  };
}