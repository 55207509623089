import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  100% {
    opacity: 1;
    right:-300px;
  }
  0% {
    opacity: 0;
    right:0px;

  }
`;

export const Page = styled.div`
  flex: 1;
  animation: ${fadeIn} 1s forwards;
  padding: 10px;
  width: 300px;
  padding-bottom: 20px;
`;

export const Container = styled.div`
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100vh;
  section {
    justify-content: space-between;
    overflow: auto;
  }
  .container-small {
    width: 100%;
    align-items: center;
  }
  .label-file {
    width: 200px;
    min-width: 200px;
    height: 100px;
    border-radius: 10px;
    border-width: 1px;
    border-style: dashed;
    margin-right: 20px;
    cursor: pointer;
  }
  .label-open {
    width: 80px !important;
    min-width: 80px !important;
    height: 80px;
  }
  input[type="file"] {
    display: none;
  }
`;

export const ContainerInputs = styled.div`
  width: 100%;
  flex: 1;
  justify-content: center;
  max-height: 500px;
    overflow-x: auto;

  label,
  strong {
    font-size: 20px;
  }
  span {
    font-size: 12px;
  }
`;

export const ContainerHorizontal = styled.div`
  flex-direction: row;
  max-width: 400px;
  overflow: auto;
  margin-top: 20px;
`;

export const SmallLabel = styled.label`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border-width: 1px;
  border-style: dashed;
  margin-right: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Label = styled.label`
  width: auto;
  min-height: 100px;
  border-radius: 10px;
  border-width: 1px;
  border-style: dashed;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
`;

export const ButtonClose = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  top: -2px;
  right: -5px;
  z-index: 999;
  -webkit-box-shadow: 4px 2px 42px -18px #000000;
  box-shadow: 4px 2px 42px -18px #000000;
  cursor: pointer;
  i {
    color: #000;
  }
`;
