import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "../../../src/store/modules/account/actions";
import * as CryptoActions from "../../../src/store/modules/crypto/actions";
import { TablePendingChanges } from "../../components/Table";
import { Container, ContainerBoxes, Space } from "./styles";

import {
  CardHomeEntries,
  CardHomeCategories,
  HomeAccount,
  CardHomeDigitalMoney,
} from "../../components/Cards";
import { GetWallet } from "../../../src/store/modules/crypto/actions";
import Orders from "./Orders";



export default function Home() {
  const dispatch = useDispatch();
  const DefaultTime = 5
  const [counter, setCounter] = useState(DefaultTime);
  
  //decrease counter
  const decrease = () => {
    setCounter(count => count - 1);
  };

  const { wallets } = useSelector((state) => state.crypto);

  useEffect(() => {
    dispatch(CryptoActions.GetWallet({ per_page: 10, page: 1 }));
    dispatch(AccountActions.balanceRequest());
  }, []);

  const UpdateCurrency = () => {
    dispatch(CryptoActions.GetWallet({ per_page: 10, page: 1 }));
  }


  useEffect(() => {
    const interval = setInterval(() => {
      decrease()
    }, 1200);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (counter < 0){
      setCounter(DefaultTime)
      UpdateCurrency()
    }
  }, [counter]);

  return (
    <Container>
      <h1>Digital Money</h1>
      <p className="description">Esses são os dados da sua carteira digital.</p>
      <ContainerBoxes>
        {wallets.map((item) => (
          <>
            <CardHomeDigitalMoney
              price_buy_brl={item.price_buy_brl}
              price_sell_brl={item.price_sell_brl}
              name={item.name}
              symbol={item.currency}
              balance={item.balance}
              address={item.address}
              blockchain={item.blockchain}
              UpdateCurrency={UpdateCurrency}
              counter={counter}
            />{" "}
            <Space></Space>
          </>
        ))}
      </ContainerBoxes>
      <Orders />
    </Container>
  );
}
