import React, { useState, useEffect } from "react";
// import InputMask from "react-input-mask";
import { InputMask } from "../../../components/Inputs";

import apiCustomer from "../../../services/apiCustomer";
import apiCNPJ from "../../../services/apiCNPJ";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Container,
  Page,
  ContainerInputs,
  HorizontalContainer,
} from "../styles";

import { HeaderBackImage } from "../../../components/Headers";
import { ButtonDefault } from "../../../components/Buttons";
import { registerCompany } from "../../../store/modules/register/actions";
import * as GeneralActions from "../../../store/modules/general/actions";

const initialValues = {
  name: "",
  legal_name: "",
  document: "",
  business_type: "LTDA",
};

const initialErrors = {
  name: "-",
  legal_name: "-",
  document: "-",
  business_type: "",
};

const CompanySchema = Yup.object().shape({
  name: Yup.string().required("campo obrigatório"),
  legal_name: Yup.string().required("campo obrigatório"),
  business_type: Yup.string().required("campo obrigatório"),
  document: Yup.string()
    .min(18, "minimo de 14 caracteres")
    .test("cnpj-length", "Preencha o cnpj completo", (value) => {
      let valueFormat = value?.replace(/\D/g, "");
      if (valueFormat && valueFormat.length === 14) {
        return true;
      } else {
        return false;
      }
    })
    .test("is-cnpj", "CNPJ está sendo usado", (value) => {
      let valueFormat = value?.replace(/\D/g, "");
      if (valueFormat && valueFormat.length === 14) {
        return apiCustomer
          .get(`/check/company/document/${valueFormat}`)
          .then((result) => result.data)
          .then((data) => !data.exists);
      }
    })
    .required("campo obrigatório"),
});

export default function SignIn() {
  const { data } = useSelector((state) => state.user);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(-1);

  const [types] = useState(["LTDA"]);

  const checkCNPJ = (props) => {
    const cnpj = props.values.document.replace(/[\.^\-^\/]/gi, "");
    dispatch(GeneralActions.setLoading({ loading: true }));
    setTimeout(() => {
      apiCNPJ
        .checkCNPJ(cnpj)
        .then((data) => {
          handleToSet(2);
          if (data.nome) {
            props.setFieldValue("name", data.nome);
            props.setFieldError("name", "");
            props.setFieldTouched("name", true);
          }
          if (data.fantasia) {
            props.setFieldValue("legal_name", data.fantasia);
            props.setFieldError("legal_name", "");
            props.setFieldTouched("legal_name", true);
          }
          if (data?.status === "ERROR") {
            return dispatch(GeneralActions.setError({ error: data.message }));
          }
        })
        .catch((err) => {
          console.log(err, err.response);
          if (err.response && err.response.status === 429) {
            return dispatch(
              GeneralActions.setError({
                error:
                  "Muitas requisições em pouco tempo, tente novamente mais tarde",
              })
            );
          }
        })
        .finally(() => {
          return dispatch(GeneralActions.setLoading({ loading: false }));
        });
    }, 1000);
  };

  function submitForm(values) {
    const document = values.document.replace(/[\.^\-^\/]/gi, "");
    dispatch(
      registerCompany({
        person_type: "company",
        company: { ...values, document },
      })
    );
  }

  const handleToSet = (value) => {
    setCurrent(value);
  };

  const validInput = ({ input, props }) => {
    if (props.errors[input]) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Container>
      <section className="container-small">
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          initialErrors={initialErrors}
          initialTouched={initialValues}
          validationSchema={CompanySchema}
        >
          {(props) => (
            <>
              {/* MESSAGE [-1] */}
              {current === -1 && (
                <Page>
                  <HeaderBackImage onClick={() => history.goBack()} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      Agora cadastraremos a <strong>sua empresa.</strong>
                    </label>
                  </ContainerInputs>
                  <ButtonDefault
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Próximo"
                    onClick={() => handleToSet(0)}
                  />
                </Page>
              )}

              {/* DOCUMENTO [0]*/}
              {current === 0 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="document">
                      <strong>Tipo</strong> da Empresa
                    </label>
                    <select
                      type="text"
                      name="business_type"
                      placeholder="Tipo"
                      value={props.values.business_type}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      {types.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </select>
                    <span>
                      {console.log(props.errors)}
                      {props.touched.business_type &&
                        props.errors.business_type}
                    </span>
                  </ContainerInputs>
                  {validInput({ input: "business_type", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(1)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(-1)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(-1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* DOCUMENTO [0]*/}
              {current === 1 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="document">
                      <strong>CNPJ</strong> da Empresa
                    </label>
                    <InputMask
                      id="document"
                      format="cnpj"
                      type="text"
                      name="document"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.document}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {console.log(props.errors)}
                      {props.touched.document && props.errors.document}
                    </span>
                  </ContainerInputs>
                  {validInput({ input: "document", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => checkCNPJ(props)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(-1)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(-1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* NOME [1] */}
              {current === 2 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="name">
                      <strong>Nome fantasia</strong> da Empresa
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>{props.touched.name && props.errors.name}</span>
                  </ContainerInputs>
                  {validInput({ input: "name", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* LEGAL_NAME [2]*/}
              {current === 3 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label htmlFor="legal_name">
                      <strong>Razão social</strong> da Empresa
                    </label>
                    <input
                      id="legal_name"
                      type="text"
                      name="legal_name"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.legal_name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {props.touched.legal_name && props.errors.legal_name}
                    </span>
                  </ContainerInputs>

                  {validInput({ input: "legal_name", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(4)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(2)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* CHECK INFORMATION [3] */}
              {current === 4 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs>
                    <label>
                      <strong>{data?.name}</strong> verifique as informações e
                      confirme
                    </label>
                    <br />
                    <label>
                      Tipo
                      <br />
                      <strong>{props.values.business_type}</strong>
                    </label>
                    <br />
                    <label>
                      nome fantasia
                      <br />
                      <strong>{props.values.name}</strong>
                    </label>
                    <br />
                    <label>
                      razão social
                      <br />
                      <strong>{props.values.legal_name}</strong>
                    </label>
                    <br />
                    <label>
                      documento
                      <br />
                      <strong>{props.values.document}</strong>
                    </label>
                    <br />
                  </ContainerInputs>

                  <ButtonDefault
                    type="submit"
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Próximo"
                    onClick={props.handleSubmit}
                  />
                  <ButtonDefault
                    onClick={() => handleToSet(3)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}
            </>
          )}
        </Formik>
      </section>
    </Container>
  );
}
