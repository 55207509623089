import produce from "immer";

const INITIAL_STATE = {
  balance: 0,
  balance_block: 0,
  resume: [],
  inout: {},
  months: {},
  limits: [
    {
      id: 4,
      name: "deposit-picpay",
      volume: 0,
      balance: 0,
      min: 5,
      fee: 5,
      fee_type: "percent",
      progress: "0",
    },
    {
      name: "transfer-ted",
      volume: 0,
      balance: 0,
      min: 10,
      fee: 3,
      fee_type: "value",
      progress: "0",
    },
    {
      name: "billing",
      volume: 0,
      balance: 0,
      min: 20,
      fee: 0,
      fee_type: "value",
      progress: "0",
    },
    {
      name: "deposit-boleto",
      volume: 0,
      balance: 0,
      min: 10,
      fee: 2.5,
      fee_type: "value",
      progress: "0",
    },
  ],
  account: {
    bank_name: "",
    branch: "",
    brank_number: "",
    brcode: "",
    client_type: "",
    createdAt: "",
    document: "",
    id: 0,
    name: "",
    number: "",
    partner_id: 0,
    pix_key: "",
    type: "",
    updatedAt: "",
  },
};

export default function card(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@account/BALANCE_SUCCESS":
      return produce(state, (draft) => {
        draft.balance = action.payload.balance;
        draft.balance_block = action.payload.balance_block;
      });
    case "@account/RESUME_SUCCESS":
      return produce(state, (draft) => {
        draft.resume = action.payload.data;
      });
    case "@account/RESUME_IN_OUT_SUCCESS":
      return produce(state, (draft) => {
        draft.inout = action.payload.inout;
      });
    case "@account/RESUME_MONTHS_SUCCESS":
      return produce(state, (draft) => {
        draft.months = action.payload.months;
      });
    case "@account/LIST_LIMIT_SUCCESS":
      return produce(state, (draft) => {
        draft.limits = action.payload.data;
      });
    case "@account/GET_ACCOUNT_SUCCESS":
      return produce(state, (draft) => {
        draft.account = action.payload.account;
      });
    default:
      return state;
  }
}
