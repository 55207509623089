import { all, takeLatest, put, call, select } from "redux-saga/effects";
import history from "../../../services/history";
import apiPayment from "../../../services/apiPayment";
import GetErrors from "../../../services/errors";
import * as SupplierActions from "./actions";
import * as GeneralActions from "../general/actions";
import moment from "moment";

export function* SuppliersRequest(action) {
  try {
    const { per_page, page } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    const { data } = yield call(
      apiPayment.post,
      "/suppliers",
      { per_page, page },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(SupplierActions.SuppliersRequestSuccess(data));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* newSupplier(action) {
  try {
    const { callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    yield call(apiPayment.post, "/supplier", action.payload, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    yield put(SupplierActions.SuppliersRequest({ page: 1, per_page: 3 }));
    if (callback) {
      callback();
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* billingResumeRequest(action) {
  try {
    const { my_client_id } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    const { data } = yield call(
      apiPayment.get,
      `/billing/resume/${my_client_id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(SupplierActions.billingResumeSuccess({ totalResume: data }));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeLatest("@supplier/LIST_REQUEST", SuppliersRequest),
  takeLatest("@supplier/NEW", newSupplier),
  takeLatest("@supplier/RESUME_BILLING", billingResumeRequest),
]);
