import React, { useState, useEffect, CSSProperties } from "react";
import { useHistory } from "react-router-dom";
import { Container, ContainerPage, ContainerPagination } from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as EmployeeActions from "../../../store/modules/employee/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import { InputMask } from "../../Inputs";
import { cpfIsValid } from "../../../utils/check";
import Carousel from 'react-elastic-carousel'
import apiCep from "../../../services/apiCEP.js";
import * as GeneralActions from "../../../store/modules/general/actions";

import DisplayInfos from "../../DisplayInfo"

const renderPagination = ({ pages, activePage, onClick }, props, handleNextPage, success) => {
  return (
    <ContainerPagination>
      {pages.map(page => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${success ? 'success' : ''}`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props)

            }}
          />
        );


      })}
    </ContainerPagination>
  )
}

const clientValidSchema = Yup.object().shape({
  name: Yup.string().required("campo obrigatório"),
  email: Yup.string().required("campo obrigatório"),
  phone: Yup.string().required("campo obrigatório"),
  document: Yup.string().required("campo obrigatório"),

})

const AddressValidSchema = Yup.object().shape({
  zip_code: Yup.string()
    .min(9, "minimo de 8 caracteres")
    .required("campo obrigatório")
    .test("valid", "cep inválido", (value = "0") => {
      const cep = value.replace(/[\_^\-^\/]/gi, "");
      if (cep && cep.length === 8) {
        return true;
      }
      return false;
    }),
  address: Yup.string().required("campo obrigatório"),
  neighborhood: Yup.string().required("campo obrigatório"),
  city: Yup.string().required("campo obrigatório"),
  state: Yup.string()
    .min(2, "deve ter dois digitos")
    .required("campo obrigatório"),
  default: Yup.boolean(),
  number: Yup.string().required("campo obrigatório"),
  complement: Yup.string(),

})

const ClientSchema = Yup.object().concat(clientValidSchema).concat(AddressValidSchema)
const initialValues = {
  name: "",
  email: "",
  phone: "",
  document: "",
  zip_code: "",
  address: "",
  neighborhood: "",
  city: "",
  state: "",
  number: "",
  complement: "",
  salary_base: "",
  benefits_base: "",
  type: "clt"
};

const initialErrors = {
  name: "-",
  email: "-",
  phone: "-",
  document: "-",
  zip_code: "-",
  address: "-",
  neighborhood: "-",
  city: "-",
  state: "-",
  number: "-",
  complement: "-",
};



const diplayValues = [
  { key: "name", name: "Nome", value: "", position: "row" },
  { key: "email", name: "Email", value: "", position: "row" },
  { key: "phone", name: "Telefone", value: "", position: "row" },
  { key: "document", name: "document", value: "", position: "row" },
  { key: "salary_base", name: "Salário base", value: "", position: "row" },
  { key: "benefits_base", name: "Beneficio base", value: "", position: "row" },
  { key: "zip_code", name: "Cep", value: "", position: "row" },
  { key: "address", name: "Logradouro", value: "", position: "column" },
  { key: "neighborhood", name: "Bairro", value: "", position: "column" },
  { key: "city", name: "Cidade", value: "", position: "column" },
  { key: "state", name: "Estado", value: "", position: "column" },
  { key: "number", name: "Número", value: "", position: "column" },
  { key: "complement", name: "Complemento", value: "", position: "column" },
];



const EmployeeCreate = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [position, setPosition] = useState(0);

  var carousel = null

  const [success, setSuccess] = useState(false);

  const { bank } = useSelector(
    (state) => state.transfer
  );

  useEffect(() => {
  }, []);

  const handleCepPress = (props) => {
    const { zip_code } = props.values;
    let cep = zip_code.replace(/\D/g, "");
    if (cep.length < 8) {
      dispatch(GeneralActions.setError({ error: "Cep inválido" }));
      return;
    }
    dispatch(GeneralActions.setLoading({ loading: true }));
    apiCep
      .getAddress(cep)
      .then((result) => result.data)
      .then((result) => {
        props.setFieldValue("address", result.logradouro);
        props.setFieldTouched("address", true);
        props.setFieldValue("neighborhood", result.bairro);
        props.setFieldTouched("neighborhood", true);
        props.setFieldValue("city", result.localidade);
        props.setFieldTouched("city", true);
        props.setFieldValue("state", result.uf);
        props.setFieldTouched("state", true);
        props.setFieldTouched("complement", true);
        carousel.goTo(position + 1)
        setPosition(position + 1)
      })
      .catch((error) => {
        if (error.response) {
          dispatch(GeneralActions.setError("CEP inválido, tente outro"));
        }
      })
      .finally(() => {
        dispatch(GeneralActions.setLoading({ loading: false }));
      });
  };

  var listRef = null

  const handleCancel = () => {
    history.push("/gestao/funcionario");
  };

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }
  const handleNextPage = (props) => {
    console.log(position)
    switch (position) {
      case 0:
        const { name, email, phone, document } = props.values
        props.validateForm().then(validation => props.setTouched(validation))
        clientValidSchema
          .isValid({ name, email, phone, document })
          .then((valid) => {
            if (valid) {
              carousel.goTo(position + 1)
              setPosition(position + 1)
              return
            }
            dispatch(GeneralActions.setError({ error: "Dados inválidos" }));
          })
        break;
      case 1:
        handleCepPress(props)
        break;
      case 2:
        const { zip_code, address, neighborhood, city, state, number } = props.values
        props.validateForm().then(validation => props.setTouched(validation))
        AddressValidSchema
          .isValid({ zip_code, address, neighborhood, city, state, number })
          .then((valid) => {
            if (valid) {
              carousel.goTo(position + 1)
              setPosition(position + 1)
            }
          });
        break;
      case 4:
        handleSubmitForm(props.values)
        break;
      default:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        break
    }
  };

  const handleSubmitForm = (values) => {
    const document = values.document.replace(/[\.^\-^\/]/gi, "");
    const salary_base = values.salary_base.replace(/[\.^\-^\/]/gi, "");
    const benefits_base = values.benefits_base.replace(/[\.^\-^\/]/gi, "");
    console.log("handleSubmitForm")
    dispatch(
      EmployeeActions.newEmployee({
        ...values,
        document,
        salary_base,
        benefits_base,
        callback: () => {
          setSuccess(true);
        }
      })
    );
  };

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">
              Funcionário criado com sucesso
               </div>
          </div>
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={ClientSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}

      >
        {(props) => (
          <>
            <Carousel
              showArrows={false} ref={ref => (carousel = ref)}
              renderPagination={(renderPropos) => {
                return renderPagination(renderPropos, props, handlePrevPage, success)
              }}
            >
              <ContainerPage>
                <div className="field">
                  <input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.name && props.errors.name}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.email && props.errors.email}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Telefone"
                    value={props.values.phone}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.phone && props.errors.phone}</span>
                </div>
                <div className="field">
                  <InputMask
                    format="cpf"
                    type="text"
                    name="document"
                    placeholder="Documento do titular"
                    value={props.values.document}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></InputMask>
                  <span>{props.touched.document && props.errors.document}</span>
                </div>
                <div className="field">

                  <span>
                    {props.touched.benefits_base && props.errors.benefits_base}
                  </span>
                </div>
                <div className="horizontal">
                  <div className="radio-item">
                    <label for="clt">CLT</label>
                    <input
                      type="radio"
                      id="clt"
                      name="type"
                      value="clt"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      checked={props.values.type === 'clt'}
                    />
                  </div>
                  <div className="radio-item">
                    <label for="pj">PJ</label>
                    <input
                      type="radio"
                      id="pj"
                      name="type"
                      value="pj"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      checked={props.values.type === 'pj'}
                    />
                  </div>
                  <div className="radio-item">
                    <label for="autonomo">Autônomo</label>
                    <input
                      type="radio"
                      id="autonomo"
                      name="type"
                      value="autonomo"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      checked={props.values.type === 'autonomo'}
                    />
                  </div>
                  <span>
                    {props.touched.benefits_base && props.errors.benefits_base}
                  </span>
                </div>
              </ContainerPage>
              <ContainerPage>
                <InputMask
                  format="cep"
                  id="zip_code"
                  type="text"
                  name="zip_code"
                  autoCapitalize="none"
                  placeholder="Cep"
                  value={props.values.zip_code}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <span>
                  {props.touched.zip_code && props.errors.zip_code}
                </span>
              </ContainerPage>
              <ContainerPage>
                <div className="field">
                  <input
                    type="text"
                    name="address"
                    placeholder="Logradouro"
                    value={props.values.address}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled={props.values.address != ""}

                  ></input>
                  <span>{props.touched.address && props.errors.address}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="neighborhood"
                    placeholder="Bairro"
                    value={props.values.neighborhood}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled={props.values.neighborhood != ""}

                  ></input>
                  <span>{props.touched.neighborhood && props.errors.neighborhood}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="city"
                    placeholder="Cidade"
                    value={props.values.city}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled
                  ></input>
                  <span>{props.touched.city && props.errors.city}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="state"
                    placeholder="Estado"
                    value={props.values.state}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled
                  ></input>
                  <span>{props.touched.state && props.errors.state}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="number"
                    placeholder="Numero"
                    value={props.values.number}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.number && props.errors.number}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="complement"
                    placeholder="Complemento"
                    value={props.values.complement}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.complement && props.errors.complement}</span>
                </div>
              </ContainerPage>
              <ContainerPage>
                <div className="field">
                  <input
                    id="salary_base"
                    type="text"
                    name="salary_base"
                    autoCapitalize="none"
                    placeholder="Salário base"
                    value={props.values.salary_base}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.salary_base && props.errors.salary_base}
                  </span>
                </div>
                <div className="field">
                  <input
                    id="benefits_base"
                    type="text"
                    name="benefits_base"
                    autoCapitalize="none"
                    placeholder="Salário base"
                    value={props.values.benefits_base}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.benefits_base && props.errors.benefits_base}
                  </span>
                </div>

              </ContainerPage>
              <ContainerPage>
                {(position == 4) && (
                  <DisplayInfos items={diplayValues.map(item => {
                    item.value = props.values[item.key]
                    return item;
                  })} />
                )}
              </ContainerPage>
            </Carousel>
            <div className="container-buttons">
              <div className="horizontal">
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
                <ButtonDefault
                  title="Próximo"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={() => handleNextPage(props, handleSubmitForm)}
                />

              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default EmployeeCreate;
