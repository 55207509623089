import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history'
import apiPayment from '../../../services/apiPayment'
import GetErrors from '../../../services/errors'
import * as CompanyActions from './actions'
import * as GeneralActions from '../general/actions'
import moment from 'moment'
import * as TransferActions from "../transaction/actions";
import {CompanyTransactionRequestSuccess} from "./actions";

export function* CompanyRequest(action) {
  try {
    const { per_page, page } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(apiPayment.post, '/partner/v1/companies', {
      per_page,
      page,
    })
    yield put(CompanyActions.CompanyRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}


export function* CompanyGetDetail(action) {
  try {
    const { callback, id } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(apiPayment.get, `/partner/v1/company/${id}`)

    const balanceRef = yield call(
        apiPayment.get,
        `/payment/partner/v1/web/customer/balance/${id}`,
    )
    yield put(
        CompanyActions.CompanyDetail({
          company: { ...data, ...balanceRef.data },
        }),
    )
    if (callback) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newCompany(action) {
  try {
    const { callback } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } =  yield call(apiPayment.post, '/partner/v1/company', action.payload)
    yield put(CompanyActions.CompanyRequest({ page: 1, per_page: 3 }))
    if (callback) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}


export function* transactionsRequestCompany(action) {
  const { per_page, page,query,order_by } = action.payload;
  try {

    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiPayment.post, "/payment/partner/v1/web/customer/transactions", {
      per_page,
      page,
      query,
      order_by,
    });
    yield put(CompanyTransactionRequestSuccess(data));
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}



export default all([
  takeLatest('@company/LIST_REQUEST', CompanyRequest),
  takeLatest('@company/NEW', newCompany),
  takeLatest('@company/GET_DETAIL', CompanyGetDetail),
  takeLatest('@company/LIST_TRASACTIONS', transactionsRequestCompany),
])
