import styled from "styled-components";


export const ContainerPagination = styled.div`
  display: block;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
  & .dot{
    width:29px;
    height:29px;
    margin: 10px;
    border: #707070 solid 1px;
    border-radius: 20px;
    &.selected{
      background:#000000
    }
  }
`

export const ContainerPayments = styled.div`

  display:flex;
  flex-direction: row;
  margin: 28px 0;


`

export const PaymentBillingItem = styled.div`

display:flex;
width:50%;
justify-content: center;
align-items: center;
flex-direction: row;
& div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
&.active{

  :before {
    background:#000000
  }

}
&:hover{
  cursor: pointer;
}
:before {
  display:block;
  height:18px;
  width:18px;
  content: "";
  border: #707070 solid 1px;
}
`


export const ContainerPage = styled.div`
 
`


export const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  min-height: 381px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  max-width: 410px;
  .rbt{
    outline: none;
    position: relative;
    z-index: 99;
    background: white;
    ul{
      background: white;
      width: 387px!important;
      max-height: 314px!important;
      left: -8px!Important;
      li{
        padding: 8px;
        a{
          color: #747474;
          mark{
            background: white;
          }
        }
      }
    }
  }
  span {
    font-size: 10px;
    position: absolute;
    bottom: -15px;
  }
  input {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
    :focus {
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
    }
  }
  .field {
    min-width: 40%;
    padding: 0px !important;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child{
      width: 100%;
    }
  }
  .fieldNoMargin {
    min-width: 40%;
    padding: 0px !important;
  }
  .horizontal {
    width: 100%;
    margin-bottom: 0px !important;
    align-items: flex-end !important;
    justify-content: space-between;
  }
  .horizontal-flex-start {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    input {
      margin: 0px !important;
      padding: 0px;
    }
    label {
      width: 100%;
      padding-left: 20px;
      padding-bottom: 3px;
    }
  }
  .container-buttons {
    margin-top: 20px;
  }

  .container-success {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    p {
      font-size: 20px;
      text-align: center;
      margin-top: 20%;
    }
    button {
      margin: 0px;
    }
    .container-pay{
      width: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .total{
      color: #A7A2A2;
      font-size: 19px;
      font-family: "Roboto-Bold", sans-serif;
    }
  }
  .rec.rec-swipable-null {
    justify-content: center;
  }
  .container-icon-success{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    & .content{
      background:#16BF57;
      width: 95px;
      height: 95px;
      display: flex;
     justify-content: center;
      align-items: center;
      border-radius: 53px;
      i{
        color: white;
      }
    }
    & .title{
      font-size:32px;
      text-align:center;
      margin-top:20px
    }
  }
  
  .container-pay{
    flex-direction: row;
    margin: 12px 0;
    .display-pay{
      align-items: flex-start;
      width:33%;
      & div {
        align-items: flex-start;
      }
    }
  }

  tbody tr{
    box-shadow: 5px 5px 20px -8px #000000;
    .share:hover{
      cursor: pointer;
    }
  }

`;
