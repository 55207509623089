import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

.MuiPagination-ul {
  flex-direction: row;
  justify-content: center;
    align-items: center;

}

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    font-size: 14px;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    text-decoration: none;
    list-style: none;
    transition:0.3s;
  };
  i{
    text-rendering: optimizeLegibility;
  }
  th{
    text-align:left;
  }
  h1{
    font-size:20px;
  }
  h2{
    font-size:14px;
  }
  html, body, header, footer, div, section, nav, ul, li, a, button{
    text-rendering: optimizeLegibility!important;
    flex-direction: column;
    position:relative;
    display: -webkit-box;
    /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    display: -moz-box;
    /* OLD: Firefox (buggy) */
    display: -ms-flexbox;
    /* MID: IE 10 */
    display: -webkit-flex;
    /* NEW, Chrome 21–28, Safari 6.1+ */
    display: flex;
  }
  body, #root{
    min-height:100vh;
    max-height:100vh;
    overflow:hidden;
    -webkit-font-smoothing: antialiased !important
  }
  input, button{
   /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif */
  };

  input{
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    width: 100%;
    padding: 10px 20px;
    :focus{
      border-bottom: 1px solid rgba(0,0,0,0.5);
      padding-bottom:20px;
      padding-left:25px;
    }
  };

  .container-small{
    width:320px;
    min-height:500px;
  }

  *:focus{
    outline:0;
  }

  button {
    cursor: pointer;
  };

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    table-layout: fixed;
    thead{
      background-color:transparent;
    }
  };
  th{
    font-weight:normal;
  }
  tbody tr{
    background-color:#fff;
    overflow:hidden;
    i{
      margin-right:15px;
      cursor:pointer;
      opacity:0;
    }
    :hover{
      z-index:100;
      -webkit-box-shadow: 5px 5px 20px -8px #000000; 
      box-shadow: 5px 5px 20px -8px #000000;
      i{
        opacity:1;
      }
    }
  }
  th, td{
    padding:20px;
  }
  .nobreak{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Rules for sizing the icon. */
  .material-icons.md-14 { font-size: 18px; }
  .material-icons.md-18 { font-size: 18px; }
  .material-icons.md-24 { font-size: 24px; }
  .material-icons.md-36 { font-size: 36px; }
  .material-icons.md-48 { font-size: 48px; }

  /* Rules for using icons as black on a light background. */
  .material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
  .material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

  /* Rules for using icons as white on a dark background. */
  .material-icons.md-light { color: rgba(255, 255, 255, 1); }
  .material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }
`;
