import { all, takeLatest, put, call, select } from "redux-saga/effects";
import history from "../../../services/history";
import apiPayment from "../../../services/apiPayment";
import GetErrors from "../../../services/errors";
import * as TransferActions from "./actions";
import * as GeneralActions from "../general/actions";
import moment from "moment";

export function* transactionsRequest(action) {
  try {
    const { per_page, page ,query,order_by} = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    console.log(query)
    const { data } = yield call(apiPayment.post, "/payment/partner/v1/web/transactions", {
      per_page,
      page,
      query,
      order_by,
    });
    yield put(TransferActions.transactionsRequestSuccess(data));
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}





export default all([
  takeLatest("@transaction/TRANSACTIONS_REQUEST", transactionsRequest),
]);
