import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import {ButtonDefault} from "../../../../components/Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons} from "./styles";
import CardTransferCreate from "../../TransferCreateModal";



const ModalOrder = ({visible, order, onClose,handleCancel}) => {
  const theme = useTheme();




  return (<Modal show={visible} onHide={onClose} size="md" centered>
    <Modal.Header closeButton>
      <Modal.Title>Transferência</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CardTransferCreate handleCancel={handleCancel} />
    </Modal.Body>
    <Modal.Footer>
    </Modal.Footer>
  </Modal>)
}

export default ModalOrder
