import styled, { keyframes } from "styled-components";

import colors from "../../styles/colors";

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 900px;
  padding: 20px;
  animation: ${animationName} 0.8s;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${colors.grayText};
  }
  p {
    font-weight: 100;
  }
`;




export const ContainerItem = styled.div`
  margin: 29px 0;
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 536px) {
    justify-content: center !important;
  }
`;


export const Break = styled.div`
flex-basis: 100%;
height: 0;
border:none;
-webkit-box-shadow: none;
-moz-box-shadow: none;
box-shadow: none;

`

export const Item = styled.div`
  width:184px;
  text-align: center;
  border-radius: 18px;
  background: white;
  padding: 20px 0 57px 0;



  .title{
    font-family: "Roboto-Bold", sans-serif;
    color:#747474;
    font-size:15px;
    margin-bottom: 20px;
  }
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  .icon{
    i{
      font-size:70px;
      color:#747474;
    }
  }
  &:hover{
    cursor: pointer;
    opacity:1;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  }
  opacity:0.7;
  margin:16px;
`;



