import styled, { keyframes } from "styled-components";

import colors from "../../styles/colors";

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 900px;
  padding: 20px;
  animation: ${animationName} 0.8s;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${colors.grayText};
  }
  p {
    font-weight: 100;
  }
  tr{
    td.payment-type {
    & div{
        width:auto;
     }
    }
    .actions{
      display: table-cell;
      cursor: pointer;
      .button{
        width: 33%;
        display: table-cell;
      }
    }
  }
  tr.done {
    .button {
      
    }
  }
  .truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;
