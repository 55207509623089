export function nfRequest({ per_page, page }) {
  return { type: "@nf/NFS_REQUEST", payload: { per_page, page } };
}

export function nfRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@nf/NFS_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}
export function newNf({
  amount,
  type,
  callback
}) {
  return {
    type: "@nf/NEW_NF",
    payload: {
      amount,
      type,
      callback,
    },
  };
}

export function newNfSuccess({ nf }) {
  return {
    type: "@nf/NEW_NF_SUCCESS",
    payload: {
      nf
    },
  };
}
export function CancelNf(id) {
  return {
    type: "@nf/CANCEL_NF",
    payload: {
      id,
    },
  };
}
