

export function GetWallet(req) {
  return {
    type: "@crypto/WALLET_REQUEST",
    payload: req,
  }
}

export function GetWalletSuccess(
    response,
) {
  return {
    type: "@crypto/WALLET_REQUEST_SUCCESS",
    payload: response,
  }
}

export function GetCurrencies(req) {
  return {
    type: "@crypto/CURRENCY_REQUEST",
    payload: req,
  }
}

export function GetCurrenciesSuccess(
    response,
) {
  return {
    type: "@crypto/CURRENCY_REQUEST_SUCCESS",
    payload: response,
  }
}

export function WalletDefaultTotalSuccess(
    req,
){
  return {
    type: "@crypto/WALLET_DEFINE_TOTAL_SUCCESS",
    payload: req,
  }
}

export function SelectWalletSuccess(
    req,
) {
  return {
    type:"@crypto/WALLET_SELECT_SUCCESS",
    payload: req,
  }
}

export function GetLedgers(req) {
  return {
    type: "@crypto/LEDGERS_REQUEST",
    payload: req,
  }
}

export function GetLedgersSuccess(
    response,
){
  return {
    type: "@crypto/LEDGERS_REQUEST_SUCCESS",
    payload: response,
  }
}

export function BuyRequest(req) {
  return {
    type: "@crypto/BUY_CRYPTO",
    payload: req,
  }
}

export function SellRequest(req) {
  return {
    type: "@crypto/SELL_CRYPTO",
    payload: req,
  }
}

export function DeleteRequest(req) {
  return {
    type: "@crypto/DELETE_CRYPTO",
    payload: req,
  }
}

export function BuyRequestSuccess(
    response,
) {
  return {
    type: "@crypto/BUY_CRYPTO_SUCCESS",
    payload: response,
  }
}

export function DeleteRequestSuccess(
  response,
) {
return {
  type: "@crypto/DELETE_CRYPTO_SUCCESS",
  payload: response,
}
}

export function SelectWallet(req){
  return {
    type: "@crypto/WALLET_SELECT",
    payload: req,
  }
}

export function NetworksList(req) {
  return {
    type: "@crypto/NETWORK_REQUEST",
    payload: req,
  }
}

export function NetworksListSuccess(
    response,
) {
  return {
    type: "@crypto/NETWORK_REQUEST_SUCCESS",
    payload: response,
  }
}



// export function selectCryptoNetwork(data) {
//   return {
//     type: '@crypto/SELECT_CRYPTO_NETWORK',
//     payload: { data },
//   };
// }

export function WithdrawNew(req) {
  return {
    type: "@crypto/WITHDRAW_REQUEST",
    payload: req,
  }
}

export function WithdrawSuccess(response) {
  return {
    type: "@crypto/WITHDRAW_REQUEST_SUCCESS",
    payload: response,
  }
}

export function ListCryptoDeposit(
    category,
) {
  return {
    type: "@crypto/CRYPTO_DEPOSIT_LIST",
    payload: category,
  }
}

export function ListCryptoDepositSuccess(
    response,
) {
  return {
    type: "@crypto/CRYPTO_DEPOSIT_LIST_SUCCESS",
    payload: response,
  }
}



export function LisOrders(
    category,
) {
  return {
    type: "@crypto/ORDERS_LIST",
    payload: category,
  }
}

export function ListOrdersSuccess(
    response,
) {
  return {
    type: "@crypto/ORDER_LIST_SUCCESS",
    payload: response,
  }
}


export function ListCryptoWithdraw(
    category,
) {
  return {
    type: "@crypto/CRYPTO_WITHDRAW_LIST",
    payload: category,
  }
}

export function ListCryptoWithdrawSuccess(
    response,
) {
  return {
    type: "@crypto/CRYPTO_WITHDRAW_LIST_SUCCESS",
    payload: response,
  }
}

export function CreateAddress(req) {
  return {
    type: "@crypto/CREATE_ADDRESS",
    payload: req,
  }
}

export function CreateAddressSuccess(
    address,
) {
  return {
    type: "@crypto/CREATE_ADDRESS_SUCCESS",
    payload: address,
  }
}
