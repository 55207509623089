import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { ButtonDefault } from '../../../components/Buttons'
import {
  Container,
  ContainerBox,
  NoteContainer,
  OuterBox,
  ContainerButtons,
  ContainerPage,
  ContainerPagination,
  ContainerProgressBar,
  ContainerPageSelect
} from './styles'
import * as GeneralActions from '../../../store/modules/general/actions'
import * as CryptoActions from '../../../store/modules/crypto/actions'
import * as InvoiceActions from '../../../store/modules/invoice/actions'

import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import Carousel from 'react-elastic-carousel'
import { Typeahead } from 'react-bootstrap-typeahead'
import Modal from 'react-bootstrap/Modal'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Paginator from '../../../components/Paginator'
import DisplayInfos from '../../../components/DisplayInfo'
import Select from 'react-select'

const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success
) => {
  return (
    <ContainerPagination>
      {pages.map(page => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${success ? 'success' : ''
              }`}
            key={page}
            onClick={() => {
              if (success) handleNextPage(props)
            }}
          />
        )
      })}
    </ContainerPagination>
  )
}

const InvoiceSchema = Yup.object().shape({
  amount: Yup.number().required('Obrigatório'),
  currency: Yup.number().required('Obrigatório'),
  document: Yup.number().required('Obrigatório'),
  expiredAt: Yup.string().test(
    "is_before_yesterday",
    "insira uma data válida",
    (value) => {
      const yesterday = moment().subtract(1, "day");
      const selectedDate = moment(value, "YYYY-MM-DD").format();
      return moment(selectedDate).isAfter(yesterday);
    }
  ),
  description: Yup.string().required('Obrigatório'),
})

const initialValues = {
  amount: '',
  currency: '',
  document: '',
  name: '',
  expiredAt: '',
  description: ''
}

const initialErrors = {
  amount: '-',
  currency: '-',
  document: '-',
  expiredAt: '-',
  description: '-'
}

const displayValues = [
  {
    key: 'currency',
    name: 'Moeda',
    value: '',
    position: 'column',
    type: 'currency'
  },
  {
    key: 'amount',
    name: 'Valor',
    value: '',
    position: 'column',
    type: 'string'
  },

  {
    key: 'document',
    name: 'Documento',
    value: '',
    position: 'row',
    type: 'string'
  },

  {
    key: 'client_name',
    name: 'Nome',
    value: '',
    position: 'column',
    type: 'string'
  },

  {
    key: 'description',
    name: 'Descrição',
    value: '',
    position: 'row',
    type: 'string'
  },
  {
    key: 'expiredAt',
    name: 'Vence em',
    value: '',
    position: 'row',
    type: 'date'
  }
]

export default function InvoiceDetail({ visible, onClose }) {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const [position, setPosition] = useState(0)
  const [pagination, setPagination] = useState(0)
  const [success, setSuccess] = useState(false)
  const [variant, setVariant] = useState("info");
  const [detailModal, setDetailModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmAcceptModal, setConfirmAcceptModal] = useState(false)

  var carousel = null

  const { progress } = useSelector(state => state.general)
  const { data, per_page } = useSelector(state => state.myclient)
  const { invoice, client } = useSelector(state => state.invoice)
  const { currencies } = useSelector(state => state.crypto)

  const listCurrencies = currencies.map(currency => {
    return {
      label: currency.name,
      value: currency.symbol
    }
  })

  const mask = (v) => {
    v = v.replace(/\D/g, "")

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
  }


  useEffect(() => {
    setPosition(0)
  }, [visible])

  useEffect(() => {
    if (carousel !== null) {
      carousel.goTo(position)
    }
    console.log(invoice.status)
  }, [position])

  const handleSubmitForm = values => {
    // dispatch(InvoiceActions.acceptInvoice(values))
  }

  const handleCancel = () => {
    setDetailModal(false)
    setConfirmAcceptModal(false)
    setConfirmDeleteModal(false)
  }

  const handlePrevPage = props => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }

  const handleNextPage = props => {
    switch (position) {
      case 0:
        const { amount, currency, expiredAt, document, description } =
          props.values

        props.validateForm().then(validation => props.setTouched(validation))
        InvoiceSchema.isValid({
          amount,
          currency,
          expiredAt,
          document,
          description
        }).then(valid => {
          if (valid) {
            props.setFieldValue(
              'formated_date',
              moment(expiredAt).format('DD/MM/YYYY')
            )
            props.setFieldValue('name', client.name)
            handleSubmitForm(props.values)
            carousel.goTo(position + 1)
            setPosition(position + 1)
            return
          }
          dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
        })
        break

      case 1:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        handleSubmitForm(props.values)
        break

      default:
        break
    }
  }

  const handleConfirmDelete = () => {
    setConfirmDeleteModal(true)
  }

  const handleConfirmAccept = () => {
    setConfirmAcceptModal(true)
  }

  const handleCancelInvoice = () => {
    dispatch(InvoiceActions.CancelInvoice(invoice.id))
    setSuccess(success)
    carousel.goTo(position + 1)
    setPosition(1)
    setVariant("success_reject")

    // dispatch(
    //   GeneralActions.setMessage({
    //     message: 'cobrança de invoice cancelada com sucesso'
    //   })
    // )
    setTimeout(handleCancel, 1000)
  }

  const handleAcceptInvoice = () => {
    dispatch(InvoiceActions.acceptInvoice({
      id: invoice.id,
      callback: (data) => {
        if (data.status === 'error') {
          setVariant('error')
        }
        // dispatch(
        //   GeneralActions.setMessage({
        //     message: 'cobrança de invoice paga com sucesso'
        //   })
        // )
      }
    }))
    setSuccess(success)
    carousel.goTo(position + 1)
    setPosition(1)
    setVariant("success_accept")
    setTimeout(handleCancel, 1000)
  }

  // const handlePagePress = useCallback(page => {
  //   dispatch(
  //     InvoiceActions.invoicePayableRequest({
  //       per_page: 20,
  //       page,
  //       query: { invoice_id: invoice.id }
  //     })
  //   )
  // })

  return (
    <>
      <Modal show={visible} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{'Detalhes Invoice'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={invoice}
            validationSchema={InvoiceSchema}
            onSubmit={handleSubmitForm}
            initialErrors={initialErrors}
          >
            {props => (
              <>
                <Carousel
                  showArrows={false}
                  ref={ref => (carousel = ref)}
                  enableSwipe={false}
                  renderPagination={renderPropos => {
                    return renderPagination(
                      renderPropos,
                      props,
                      handlePrevPage,
                      success
                    )
                  }}
                >
                  <ContainerPage>
                    <DisplayInfos
                      items={displayValues.map(item => {
                        dispatch(InvoiceActions.invoiceDetail({ invoice }))
                        item.value = invoice[item.key]
                        return item
                      })}
                    />
                  </ContainerPage>

                  <ContainerPage>
                    <ContainerProgressBar>
                      <ProgressBar
                        variant={progress.variant}
                        now={progress.percentage}
                        label={`${progress.percentage}%`}
                      />
                      {progress.variant === 'success_accept' && (
                        <div className="container-success">
                          <p>{'Invoice paga com sucesso'}</p>
                        </div>
                      )}

                      {progress.variant === 'success_reject' && (
                        <div className="container-success">
                          <p>{'Cobrança de invoice rejeitada'}</p>
                        </div>
                      )}

                      {progress.variant === 'error' && (
                        <div className="container-success">
                          <p>{'Ocorreu um erro. Verifique se há saldo suficiente'}</p>
                        </div>
                      )}
                    </ContainerProgressBar>
                  </ContainerPage>
                </Carousel>

                <Modal.Footer>
                  {invoice.type === 'receivable' && (
                    <ContainerButtons>
                      <ButtonDefault
                        title="Fechar"
                        background={theme.color.white}
                        color={theme.color.blackButton}
                        width="120px;"
                        onClick={onClose}
                      />
                    </ContainerButtons>
                  )}

                  {invoice.type === 'payable' && (
                    <>
                      <ContainerButtons>
                        {position === 0 && invoice.status === 'open' && (
                          <>
                            <ButtonDefault
                              title="Aceitar"
                              background={theme.color.green}
                              color={theme.color.white}
                              width="120px;"
                              onClick={handleConfirmAccept}
                            />
                            <ButtonDefault
                              title="Recusar"
                              background={theme.color.red}
                              color={theme.color.white}
                              width="120px;"
                              onClick={handleConfirmDelete}
                            />
                          </>
                        )}
                      </ContainerButtons>

                      {position === 1 && (
                        <ContainerButtons>
                          <ButtonDefault
                            title="Fechar"
                            background={theme.color.white}
                            color={theme.color.blackButton}
                            width="120px;"
                            onClick={onClose}
                          />
                        </ContainerButtons>
                      )}

                      {invoice.status !== 'open' && (
                        <ContainerButtons>
                          <ButtonDefault
                            title="Fechar"
                            background={theme.color.white}
                            color={theme.color.blackButton}
                            width="120px;"
                            onClick={onClose}
                          />
                        </ContainerButtons>
                      )}
                    </>
                  )}
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={confirmDeleteModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{'Tem certeza de que deseja recusar?'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ButtonDefault
            title={'Recusar'}
            background={theme.color.out}
            color={theme.color.white}
            width="165px;"
            onClick={handleCancelInvoice}
          />
          <ButtonDefault
            title={'Cancelar'}
            background={theme.color.white}
            color={theme.color.blackButton}
            width="165px;"
            onClick={handleCancel}
          />
        </Modal.Body>
      </Modal>

      <Modal show={confirmAcceptModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{'Confirmar pagamento de invoice?'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ButtonDefault
            title={'Pagar'}
            background={theme.color.green}
            color={theme.color.white}
            width="165px;"
            onClick={handleAcceptInvoice}
          />
          <ButtonDefault
            title={'Cancelar'}
            background={theme.color.white}
            color={theme.color.blackButton}
            width="165px;"
            onClick={handleCancel}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
