export function CompanyRequest({per_page, page}) {
    return {type: '@company/LIST_REQUEST', payload: {per_page, page}}
}

export function CompanyRequestSuccess({data, page, pages, total}) {
    return {
        type: '@company/LIST_REQUEST_SUCCESS', payload: {data, page, pages, total},
    }
}


export function CompanyTransactionRequest({per_page, page, query,order_by}) {
    return {type: '@company/LIST_TRASACTIONS', payload: {per_page, page, query,order_by}}
}

export function CompanyTransactionRequestSuccess({data, page, pages, total}) {
    return {
        type: '@company/LIST_TRASACTIONS_SUCCESS', payload: {data, page, pages, total},
    }
}


export function CompanyDetail({company}) {
    return {
        type: '@company/DEFINE_DETAIL', payload: {company},
    }
}

export function CompanyGetDetail({id, callback}) {
    return {
        type: '@company/GET_DETAIL', payload: {id, callback},
    }
}

export function newCompany({
    email,
    name,
    bank_deposit,
    crypto_deposit,
    crypto_withdraw,
    crypto_buy,
    crypto_sell,
    buy_price_spreed,
    sell_price_spreed,
    callback}) {
    return {
        type: '@company/NEW', payload: {
            email,
            name,
            bank_deposit,
            crypto_deposit,
            crypto_withdraw,
            crypto_buy,
            crypto_sell,
            buy_price_spreed,
            sell_price_spreed,
            callback,

        },
    }
}
