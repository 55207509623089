import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { InputMask } from '../../../components/Inputs'
import { formatToReal } from '../../../utils/format'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Carousel from 'react-elastic-carousel'
import {
  ContainerPage,
  ContainerPagination,
} from '../../../components/Cards/ClientCreate/styles'
import { ButtonDefault } from '../../../components/Buttons'
import { useTheme } from 'styled-components'
import { ContainerButtons } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import DisplayInfos from '../../../components/DisplayInfo'
import ProgressBar from 'react-bootstrap/ProgressBar'
import apiPayment from '../../../services/apiPayment'
import * as CompanyActions from '../../../store/modules/company/actions'
import * as GeneralActions from '../../../store/modules/general/actions'

const validSchema = Yup.object().shape({
  amount: Yup.string().required(),
})

const initialValues = {
  amount: '',
}

const initialErrors = {
  amount: '-',
}

const renderPagination = ({ pages, activePage, onClick }, props, success) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${
              success ? 'success' : ''
            }`}
            key={page}
          />
        )
      })}
    </ContainerPagination>
  )
}

const diplayValues = [
  { key: 'balance_before', name: 'Saldo atual', value: '', position: 'row' },
  {
    key: 'quantity_formated',
    name: 'Valor',
    value: '',
    position: 'row',
  },
  { key: 'balance_after', name: 'Saldo após', value: '', position: 'row' },
]

const ModalRmAmount = ({ visible, onClose, company }) => {
  const balance = company.balance
  const [position, setPosition] = useState(0)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [progress, setProgress] = useState(0)
  const [variant, setVariant] = useState('info')
  var carousel = null
  const theme = useTheme()
  const dispatch = useDispatch()
  const [send, setSend] = useState(false)

  const handleWithdraw = (values) => {
    setSend(true)
    const amount = values.amount
      .replace(/[\.^\R$\ ^\/]/gi, '')
      .replace(',', '.')
    return apiPayment
      .post('/payment/partner/v1/web/customer/transfer', {
        amount: parseFloat(amount),
        client_id: company.id,
      })
      .then((response) => {
        setVariant('success')
        setSuccess(true)
        setProgress(100)
        dispatch(
          CompanyActions.CompanyGetDetail({
            id: company.id,
            callback: () => {},
          }),
        )
        dispatch(CompanyActions.CompanyRequest({ per_page: 10, page: 1 }))
      })
      .catch((error) => {
        console.log(error)
        setVariant('danger')
        setError(true)
        setProgress(100)
      })
  }

  const handleCancel = () => {
    onClose()
  }

  const handleNextPagePosition = () => {
    carousel.goTo(position + 1)
    setPosition(position + 1)
  }

  const handleNextPage = (props) => {
    switch (position) {
      case 0:
        props.validateForm().then((validation) => props.setTouched(validation))
        validSchema.isValid({ amount: props.values.amount }).then((valid) => {
          if (valid) {
            const amount = props.values.amount
              .replace(/[\.^\R$\ ^\/]/gi, '')
              .replace(',', '.')

            if (amount <= 0) {
              dispatch(
                GeneralActions.setError({
                  error: 'Valor precisa ser maior que zero',
                }),
              )
              return
            }
            props.setFieldTouched('balance_after', true)
            props.setFieldValue(
              'balance_after',
              formatToReal(parseFloat(balance) - parseFloat(amount)),
            )
            props.setFieldTouched('balance_before', true)
            props.setFieldValue(
              'balance_before',
              formatToReal(parseFloat(balance)),
            )
            props.setFieldTouched('quantity_formated', true)
            props.setFieldValue(
              'quantity_formated',
              formatToReal(parseFloat(amount)),
            )
            handleNextPagePosition()
            return
          }
          dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
        })

        break
      case 1:
        handleWithdraw(props.values)
        handleNextPagePosition()
        break
      case 2:
        handleNextPagePosition()
        break
      default:
        break
    }
  }

  return (
    <Modal show={visible} onHide={onClose} size="sm" centered>
      <Formik
        initialValues={initialValues}
        validationSchema={validSchema}
        onSubmit={handleWithdraw}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Retirar valor de {company?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Carousel
                  showArrows={false}
                  enableSwipe={false}
                  ref={(ref) => (carousel = ref)}
                  renderPagination={(renderPropos) => {
                    return renderPagination(renderPropos, props, success)
                  }}
                >
                  <ContainerPage>
                    <div className="field">
                      <label>Valor</label>
                      <InputMask
                        autocomplete="off"
                        format="money"
                        type="string"
                        name="amount"
                        placeholder="Quantidade"
                        value={props.values.amount}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      ></InputMask>
                      <span>{props.touched.amount && props.errors.amount}</span>
                      Saldo atual:{formatToReal(balance)}
                    </div>
                  </ContainerPage>
                  <ContainerPage>
                    <div className="field">
                      <DisplayInfos
                        items={diplayValues.map((item) => {
                          item.value = props.values[item.key]
                          return item
                        })}
                      />
                    </div>
                  </ContainerPage>
                  <ContainerPage>
                    {variant == 'info' && 'Estamos processando sua retirada'}
                    <ProgressBar
                      variant={variant}
                      now={progress}
                      label={`${progress}%`}
                    />
                    {variant == 'danger' &&
                      'Houve um erro, favor tentar novamente.'}
                    {variant == 'success' &&
                      'Sua transação foi feita com sucesso'}
                  </ContainerPage>
                </Carousel>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <ContainerButtons>
                <ButtonDefault
                  title="Fechar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
                {!send && (
                  <ButtonDefault
                    title="Próximo"
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    width="170px;"
                    onClick={() => handleNextPage(props, handleWithdraw)}
                  />
                )}
              </ContainerButtons>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalRmAmount
