import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import {formatToReal} from "../../../../utils/format";
import * as Yup from "yup";

import {ContainerPage, ContainerPagination} from "../../ClientCreate/styles";
import {ButtonDefault} from "../../../Buttons";
import {useTheme} from "styled-components";
import {ButtonDownload, ContainerButtons} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import Alert from 'react-bootstrap/Alert';


const ModalTransaction = ({visible, order, onClose}) => {
    const balance = useSelector(state => state.account.balance);
    const [position, setPosition] = useState(0);
    var carousel = null;
    const theme = useTheme();
    const dispatch = useDispatch()




    const handleCancel = () => {
        onClose()
    };

    const handleNextPagePosition = () => {
        carousel.goTo(position + 1);
        setPosition(position + 1);
    }

    const getType = () => {
        switch (order?.operation_type){
            case "buy":
                return "Compra";
            case "sell":
                return "Venda";
            case "withdraw":
                return "Saque";
            case "deposit":
                return "Depósito";
        }
    }


    return (<Modal show={visible} onHide={onClose} size="sm" centered>
        <Modal.Header closeButton>
            <Modal.Title>Detalhes {order?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <ContainerPage>
                    <div className="field">
                        <label>Preço</label>
                        <input
                            type="text"
                            name="price"
                            placeholder="price"
                            value={formatToReal(parseFloat(order?.price))}
                            disabled
                        ></input>
                    </div>
                    <div className="field">
                        <label>Descrição</label>
                        <input
                            type="text"
                            placeholder="Quantidade"
                            value={order?.description}
                            disabled
                        ></input>
                    </div>
                    <div className="field">
                        <label>Tipo</label>
                        <input
                            type="text"
                            placeholder="Tipo"
                            value={getType()}
                            disabled
                        ></input>
                    </div>
                    <div className="field">
                        <label>Total</label>
                        <input
                            type="text"
                            placeholder="total"
                            value={formatToReal(parseFloat(order?.total_amount))}
                            disabled
                        ></input>

                    </div>
                    <br></br>
                    <div className="field">
                        {order?.status == "pending" && (<Alert key={"warning"} variant={"warning"}>Sua transação ainda está pendente</Alert>)}
                        {order?.status == "error" && (<Alert key={"danger"} variant={"danger"}>Sua deu erro e o valor devolvido para sua conta</Alert>)}
                        {order?.status == "done" && (<Alert key={"success"} variant={"success"}>Sua transação foi processada com sucesso</Alert>)}
                    </div>
                </ContainerPage>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <ContainerButtons>
                <ButtonDefault
                    title="Fechar"
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    width="170px;"
                    onClick={handleCancel}
                />

                <ButtonDownload
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    width="170px;"
                    target="_blank" href={order?.TxidUrl}>abrir blockchain</ButtonDownload>

            </ContainerButtons>
            {console.log(order)}
        </Modal.Footer>
    </Modal>)
}

export default ModalTransaction
