import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  flex-direction: row;
  margin-right:10px;
   margin-bottom: 20px;
     @media (max-width: 609px) {
    margin-right: 0;
  }
   .button{
        cursor: pointer;
  }
`;

export const ContainerSaldo = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 16px;
  }
`;

export const ShareContent = styled.div`
  flex: 2;
  justify-content: flex-end;
  text-align: right;
  .item {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Content = styled.div`
  flex: 14;
`;

export const Title = styled.h3`
  font-size: 18px;
  
`;

export const Item = styled.div`
  padding: 5px 0 5px 0;
  font-size: 14px;
`;
