const ErrorPTBR = {
  10007: 'token inválido',
  10008: 'usuário ou senha inválidos',
  10009: 'Usuário não ativado',
  10010: 'Cadastro em análise',
  10013: 'token inválido',
  10019: 'Esse email ja é cadastrado',
  10022: 'Documento do cliente existe',
  10042: 'Seu usuário ja foi finalizado',
  10044: 'Você precisa finalizar o cadastro',
  10045: 'Token inválido',
  20005: 'Token inválido',
  20011: 'Código inválido',
  20041: 'Problema no pagamento de depósito',
  20051: 'Você não tem saldo suficiente',
  20058: 'Linha Digitavel e CodBarras nulo ou vazio',
  20067: 'Valor inválido',
  20084: 'Esse banco não existe',
  20094: 'Você antingiu o limite mensal',
  20095: 'Você não atingiu o minimo',
  20096: 'Você antingiu o limite mensal de pix',
  20100: 'Você antingiu o limite mensal de ted',
  20101: 'Você antingiu o limite mensal de pagamento de contas',
  20102: 'Você antingiu o limite diário de pix',
  20103: 'Você antingiu o limite diário de ted',
  20103: 'Você antingiu o limite diário de pagamento de conta',
  10225: 'Você não tem mais slot para criar sub-conta',
  30033: 'Nesse momento estamos sem liquidez',
  10087: 'Você não pode fazer o mesmo valor em menos de 1 minuto',
  30063: 'Cliente não encontrado. Verifique o documento.'
}
const errors = {
  PT_BR: ErrorPTBR
}

const getError = (language, code) => {
  if (errors[language][code] === undefined) {
    return 'Algum problema aconteceu'
  } else {
    return errors[language][code]
  }
}

export default getError
