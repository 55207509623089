import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css"


// import Routes from "./routes";
import Routes from "./routes/index";
import GlobalStyles from "./styles/global";

import "./config/ReactotronConfig";
import { store, persistor } from "./store";
import ThemeProvider from "./styles/themeProvider";
import ToastProvider from "./components/Toast";

import { LoadingFullscreen } from "./components/Loading";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <LoadingFullscreen />
          <GlobalStyles />
          <Routes />
          <ToastProvider />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
