import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  ContainerPayments,
  PaymentDepositItem,
  ContainerMessage,
  ContainerSucess,
} from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as DepositActions from "../../../store/modules/deposit/actions";
import * as AccountActions from "../../../store/modules/account/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { InputMask } from "../../Inputs";
import PyamentIcon from "../../PyamentIcon";
import QRCode from "react-qr-code";
import Limit from "../../Limit";

const TransferSchema = Yup.object().shape({
  type: Yup.string().required("campo obrigatório"),
  amount: Yup.string().required("campo obrigatório"),
});

let checkStatus = null;

const initialValues = {
  type: "boleto",
  amount: "",
};

const DepositCreate = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);
  const [types, setType] = useState(["boleto"]);

  const { deposit, successPaid } = useSelector((state) => state.deposit);

  const { limits } = useSelector((state) => state.account);

  const handleCancel = () => {
    history.push("/deposito");
  };

  const renderType = (type) => {
    switch (type) {
      case "boleto":
        return (
          <>
            <a className="button" href={deposit.link_ref} target="_blank">
              Baixar boleto
            </a>
          </>
        );
    }
  };

  useEffect(() => {
    dispatch(AccountActions.ListLimits());
  }, [dispatch]);

  const handleSubmitForm = (values) => {
    const amount = values.amount.replace(/[\.^\R$\ ^\/]/gi, "");

    dispatch(
      DepositActions.newDeposit({
        type: values.type,
        amount: parseFloat(amount),
        callback: ({ deposit }) => {
          switch (deposit.type) {
            case "picpay":
              window.checkStatus = setInterval(() => {
                dispatch(DepositActions.getDepositStatus(deposit.id));
              }, 1500);
              break;
          }
          setSuccess(true);
        },
      })
    );
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={TransferSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <>
            {success ? (
              <ContainerMessage>
                <div className="container-success">
                  {successPaid ? (
                    <div className="container-icon-success">
                      <div className="content">
                        <i className="fa fa-check fa-2x"></i>
                      </div>
                      <div className="title">Depósito pago</div>
                    </div>
                  ) : (
                    <>
                      <div className="title">
                        {props.values.amount ? props.values.amount : ""}
                      </div>
                      <div className="icon">
                        <PyamentIcon
                          type={props.values.type}
                          size={100}
                          textSize={20}
                        />
                      </div>
                      <ContainerSucess>
                        {renderType(props.values.type)}
                      </ContainerSucess>
                    </>
                  )}
                </div>
              </ContainerMessage>
            ) : (
              <>
                <div className="field">
                  <InputMask
                    autocomplete="off"
                    autosuggest="none"
                    format="money"
                    // mask="/^\d(\.\d)*$/"
                    type="string"
                    name="amount"
                    placeholder="Valor"
                    value={props.values.amount}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></InputMask>
                  <span>{props.touched.amount && props.errors.amount}</span>
                </div>
                <div className="field">
                  {limits.length > 0 && props.values.type != "" && (
                    <Limit
                      volume={
                        limits.find(
                          (item) => item.name == `deposit-${props.values.type}`
                        ).volume
                      }
                      balance={
                        limits.find(
                          (item) => item.name == `deposit-${props.values.type}`
                        ).balance
                      }
                      amount={props.values.amount}
                      percent={
                        limits.find(
                          (item) => item.name == `deposit-${props.values.type}`
                        ).percent
                      }
                    />
                  )}
                </div>
                <div className="field">
                  <ContainerPayments>
                    {types.map((item, index) => (
                      <PaymentDepositItem
                        className={`${
                          item == props.values.type ? "active" : ""
                        }`}
                        onClick={() => {
                          props.setFieldValue("type", item);
                        }}
                      >
                        <PyamentIcon type={item} size={40} textSize={12} />
                      </PaymentDepositItem>
                    ))}
                  </ContainerPayments>
                  <span>{props.touched.type && props.errors.type}</span>
                </div>
                <div className="container-buttons">
                  <div className="horizontal">
                    <ButtonDefault
                      title="Criar"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="170px;"
                      onClick={props.handleSubmit}
                    />
                    <ButtonDefault
                      title="Cancelar"
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      width="170px;"
                      onClick={handleCancel}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Formik>
    </Container>
  );
};

export default DepositCreate;
