export function balanceRequest() {
  return { type: "@account/BALANCE_REQUEST" };
}

export function requestBalanceSucesss({ balance, balance_block }) {
  return {
    type: "@account/BALANCE_SUCCESS",
    payload: { balance, balance_block },
  };
}

export function resumeRequest() {
  return { type: "@account/RESUME_REQUEST" };
}

export function resumeRequestSucesss(data) {
  return {
    type: "@account/RESUME_SUCCESS",
    payload: { data },
  };
}

export function resumeInoutRequest() {
  return {
    type: "@account/RESUME_IN_OUT_REQUEST",
  };
}

export function ListLimits() {
  return {
    type: "@account/LIST_LIMIT",
  };
}
export function ListLimitsSucesss(data) {
  return {
    type: "@account/LIST_LIMIT_SUCCESS",
    payload: { data },
  };
}

export function resumeInoutSuccess(inout) {
  return {
    type: "@account/RESUME_IN_OUT_SUCCESS",
    payload: { inout },
  };
}

export function resumeMonthsRequest() {
  return {
    type: "@account/RESUME_MONTHS_REQUEST",
  };
}
export function resumeMonthsSuccess(months) {
  return {
    type: "@account/RESUME_MONTHS_SUCCESS",
    payload: { months },
  };
}

export function GetAccount() {
  return {
    type: "@account/GET_ACCOUNT",
    payload: {},
  };
}

export function GetAccountSuccess(account) {
  return {
    type: "@account/GET_ACCOUNT_SUCCESS",
    payload: { account },
  };
}



export function GetAccountInternal({ document, callback }) {
  return {
    type: "@account/GET_INTERNAL_ACCOUNT",
    payload: {
      document,
      callback,
    },
  };
}
