import styled from "styled-components";

export const Container = styled.div`
    width:100%;
    display: inline-block;
`;



export const ContainerItem = styled.div`
    margin:0 0 15px 0;
    display: inline-block;
    &.row{
      width:100%;
      padding: 0;
    }
    &.column{
      width:50%;
    }
`;


export const Label = styled.div`
  color:#A7A2A2; 
  font-weight: bold;
  padding:0;
`;


export const Value = styled.div`
  color:#7B7777;
  font-weight: bold;
  margin:5px 0 0 0;
  padding:0;
`;
