import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as GeneralActions from "../../store/modules/general/actions";
import { Container } from "./styles";
export default () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(0);
  const { error, message } = useSelector((state) => state.general);

  useEffect(() => {
    if (message || error) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [error, message]);

  const clean = () => {
    dispatch(GeneralActions.setError({ error: "" }));
    dispatch(GeneralActions.setMessage({ message: "" }));
  };

  return (
    <Container show={show} onClick={clean}>
      <div className="toast-message">{message || error}</div>
      <i className="material-icons md-36">close</i>
    </Container>
  );
};
