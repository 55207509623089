import styled, { keyframes, css } from 'styled-components'

import colors from '../../styles/colors'

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`

export const Container = styled.div`
  padding: 20px;
  animation: ${animationName} 0.8s;
  flex-flow: column;
  -webkit-flex-flow: column;
  width: 100%;
  display: block;
  .description {
      @media (max-width: 609px) {
        text-align: center;
      }
    }

  h1 {
    font-size: 18px;
    font-family: 'Roboto-Bold', sans-serif;
    color: ${colors.grayText};
    @media (max-width: 609px) {
      text-align: center;
    }
   
  }
  p {
    font-weight: 100;
    margin-bottom: 20px;
  }
`

export const ContainerBoxes = styled.div`
  flex-direction: row;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  flex: 1;
  @media (max-width: 609px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
`
