import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { ButtonDefault } from '../../Buttons'
import {
  Container,
  ContainerPage,
  ContainerPagination,
  ContainerButtons,
  ContainerProgressBar,
  ContainerPageSelect
} from './styles'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import Carousel from 'react-elastic-carousel'
import * as GeneralActions from '../../../store/modules/general/actions'
import * as InvoiceActions from '../../../store/modules/invoice/actions'
import * as MyClientActions from '../../../store/modules/myclient/actions'
import * as CryptoActions from '../../../store/modules/crypto/actions'

import DisplayInfos from '../../DisplayInfo'
import Modal from 'react-bootstrap/Modal'
import ProgressBar from 'react-bootstrap/ProgressBar'

const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success
) => {
  return (
    <ContainerPagination>
      {pages.map(page => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${
              success ? 'success' : ''
            }`}
            key={page}
            onClick={() => {
              if (success) handleNextPage(props)
            }}
          />
        )
      })}
    </ContainerPagination>
  )
}

const InvoiceSchema = Yup.object().shape({
  amount: Yup.number().required('Obrigatório'),
  currency: Yup.string().required('Obrigatório'),
  document: Yup.string().required('Obrigatório'),
  document_type: Yup.string().required('Obrigatório'),
  expiredAt: Yup.string().test(
      "is_before_yesterday",
      "insira uma data válida",
      (value) => {
          const yesterday = moment().subtract(1, "day");
          const selectedDate = moment(value, "YYYY-MM-DD").format();
          return moment(selectedDate).isAfter(yesterday);
      }
  ),
  description: Yup.string().required('Obrigatório'),

})

const initialValues = {
  amount: '',
  currency: '',
  document: '',
  document_type: '',
  expiredAt: new Date().toISOString().slice(0, 10),
  description: ''
}

const initialErrors = {
  amount: '-',
  currency: '-',
  document: '-',
  expiredAt: '-',
  description: '-'
}

const displayValues = [
  {
    key: 'currency',
    name: 'Moeda',
    value: '',
    position: 'column',
    type: 'currency'
  },
  {
    key: 'amount',
    name: 'Valor',
    value: '',
    position: 'column',
    type: 'string'
  },

  {
    key: 'document',
    name: 'Documento',
    value: '',
    position: 'column',
    type: 'string'
  },

  {
    key: 'document_type',
    name: 'Tipo de documento',
    value: '',
    position: 'column',
    type: 'string'
  },

  {
    key: 'name',
    name: 'Nome',
    value: '',
    position: 'row',
    type: 'string'
  },

  {
    key: 'description',
    name: 'Descrição',
    value: '',
    position: 'row',
    type: 'string'
  },
  {
    key: 'formated_date',
    name: 'Vence em',
    value: '',
    position: 'row',
    type: 'string'
  }
]

const InvoiceCreate = ({ visible, onClose, navigation }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [position, setPosition] = useState(0)
  const [success, setSuccess] = useState(false)

  var carousel = null

  const { progress } = useSelector(state => state.general)
  const { currencies, per_page, page } = useSelector(state => state.crypto)
  const { client } = useSelector(state => state.invoice)

  const listCurrencies = currencies.map(currency => {
    return {
      label: currency.name,
      value: currency.symbol
    }
  })

  const documentTypes = [
    {
      label: 'CPF',
      value: 'CPF'
    },
    {
      label: 'CNPJ',
      value: 'CNPJ'
    },
  ]

  const mask = (v) => {
    v = v.replace(/\D/g, "")
  
    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
  }

  useEffect(() => {
    dispatch(
      InvoiceActions.invoiceReceivableRequest({ per_page: per_page, page: 1 })
    )
    dispatch(
      InvoiceActions.invoicePayableRequest({ per_page: per_page, page: 1 })
    )
    dispatch(CryptoActions.GetCurrencies({ per_page: 100, page: 1 }))
  }, [dispatch])

  useEffect(() => {
    setPosition(0)
  }, [visible])

  useEffect(() => {
    if (carousel !== null) {
      carousel.goTo(position)
    }
  }, [position])

  const handleSubmitForm = values => {
    values.document = values.document.replace(/[^\w\s]/gi, '')
    let splittedDate = values.expiredAt.split('-')
    let date = new Date(splittedDate)
    date =
      date.getUTCFullYear() +
      '-' +
      ('00' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('00' + date.getUTCDate()).slice(-2) +
      ' ' +
      ('00' + date.getUTCHours()).slice(-2) +
      ':' +
      ('00' + date.getUTCMinutes()).slice(-2) +
      ':' +
      ('00' + date.getUTCSeconds()).slice(-2)
    let date_utc = date.slice(0, 10) + 'T' + date.slice(11, 19) + '.000Z'
    values.expiredAt = date_utc

    dispatch(InvoiceActions.newInvoice(values))
  }

  const handleCancel = () => {
    onClose()
  }

  const handlePrevPage = props => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }

  const handleNextPage = props => {
    switch (position) {
      case 0:
        const { amount, currency, expiredAt, document, document_type, description } =
          props.values

        props.validateForm().then(validation => props.setTouched(validation))

        InvoiceSchema.isValid({
          amount,
          currency,
          expiredAt,
          document,
          document_type,
          description
        }).then(valid => {
          if (valid) {
            props.setFieldValue(
              'formated_date',
              moment(expiredAt).format('DD/MM/YYYY')
              )
            dispatch(
              InvoiceActions.invoiceDocument(document, data => {
                if (data.status === 'error') return
                props.setFieldValue('name', data.name)
                props.setFieldValue('document', mask(document))
                setPosition(position + 1)
                return
              })
            )
            return
          }
          dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
        })
        break

      case 1:
        setPosition(position + 1)
        handleSubmitForm(props.values)
        break

      default:
        break
    }
  }

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">{'Fee Billing criada com sucesso'}</div>
          </div>
          <ButtonDefault
            title="Fechar"
            background={theme.color.white}
            color={theme.color.blackButton}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    )
  }

  return (
    <Modal show={visible} onHide={onClose} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>{'Criar nova Invoice'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={InvoiceSchema}
          onSubmit={handleSubmitForm}
          initialErrors={initialErrors}
        >
          {props => (
            <>
              <Carousel
                showArrows={false}
                ref={ref => (carousel = ref)}
                enableSwipe={false}
                renderPagination={renderPropos => {
                  return renderPagination(
                    renderPropos,
                    props,
                    handlePrevPage,
                    success
                  )
                }}
              >
                {/* inputs inital */}
                <ContainerPage>
                  <div className="field field-select">
                    <select
                      placeholder="Moeda"
                      name="currency"
                      value={props.values.currency}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="none" hidden>
                        selecione uma moeda
                      </option>

                      {listCurrencies.map(currency => {
                        return (
                          <option value={currency.value}>
                            {currency.label}
                          </option>
                        )
                      })}
                    </select>
                    <span>
                      {props.touched.currency && props.errors.currency}
                    </span>
                  </div>

                  <div className="field">
                    <input
                      type="number"
                      name="amount"
                      min="0"
                      placeholder="Valor"
                      value={props.values.amount}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></input>
                    <span>{props.touched.amount && props.errors.amount}</span>
                  </div>

                  <div className="field">
                    <input
                      type="text"
                      name="document"
                      placeholder="Documento"
                      value={mask(props.values.document)}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></input>
                    <span>
                      {props.touched.document && props.errors.document}
                    </span>
                  </div>

                  <div className="field field-select">
                    <select
                      name="document_type"
                      placeholder="Tipo de documento"
                      value={props.values.document_type}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="none" hidden>
                        tipo de documento
                      </option>

                      {documentTypes.map(type => {
                        return (
                          <option value={type.value}>
                            {type.label}
                          </option>
                        )
                      })}
                    </select>
                    <span>
                      {props.touched.document_type && props.errors.document_type}
                    </span>
                  </div>

                  <div className="field">
                    <label>Data de vencimento</label>
                    <input
                      type="date"
                      min={moment().format('DD/MM/YYYY')}
                      name="expiredAt"
                      value={props.values.expiredAt}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></input>
                    <span>
                      {props.touched.expiredAt && props.errors.expiredAt}
                    </span>
                  </div>

                  <div className="field">
                    <input
                      type="text"
                      name="description"
                      placeholder="Descrição"
                      value={props.values.description}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></input>
                    <span>
                      {props.touched.description && props.errors.description}
                    </span>
                  </div>
                </ContainerPage>

                <ContainerPage>
                  <DisplayInfos
                    items={displayValues.map(item => {
                      item.value = props.values[item.key]
                      return item
                    })}
                    />
                </ContainerPage>

                <ContainerPage>
                  <ContainerProgressBar>
                    <ProgressBar
                      variant={progress.variant}
                      now={progress.percentage}
                      label={`${progress.percentage}%`}
                    />
                    {progress.variant === 'success' && (
                      <div className="container-success">
                        <p>{'Invoice criada com sucesso'}</p>
                      </div>
                    )}

                    {progress.variant !== 'success' && (
                      <div className="container-success">
                        <p>{'Ocorreu um erro. Tente novamente'}</p>
                      </div>
                    )}
                  </ContainerProgressBar>
                </ContainerPage>
              </Carousel>

              <Modal.Footer>
                <ContainerButtons>
                  <ButtonDefault
                    title="Fechar"
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    width="120px;"
                    onClick={handleCancel}
                  />
                  {position === 0 && (
                    <ButtonDefault
                      title="Próximo"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="120px;"
                      onClick={() => handleNextPage(props, handleSubmitForm)}
                    />
                  )}
                  {position === 1 && (
                    <>
                      <ButtonDefault
                        title="Voltar"
                        background={theme.color.white}
                        color={theme.color.blackButton}
                        width="120px;"
                        onClick={handlePrevPage}
                      />
                      <ButtonDefault
                        title="Criar"
                        background={theme.color.blackButton}
                        color={theme.color.white}
                        width="120px;"
                        onClick={() => handleNextPage(props, handleSubmitForm)}
                      />
                    </>
                  )}
                </ContainerButtons>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default InvoiceCreate
