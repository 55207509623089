import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
} from './styles'
import {
  formatDate, formatToReal,

} from '../../../utils/format'
import * as CompanyActions from '../../../store/modules/company/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import '../../../assets/animations/animations.css'

import apiCustomer from "../../../services/apiCustomer"



export default function CompanyItem({index,item,handleInfoRightMenu}) {


  const [balance, setBalance] = useState(0)


  const theme = useTheme()


  useEffect(() => {
    apiCustomer
        .get(`/payment/partner/v1/web/customer/balance/${item.id}`)
        .then(({ data }) => {
          setBalance(data.balance)
        })
  }, [])





  return (
      <>
        <TransactionItem key={index}  onClick={() => {
          handleInfoRightMenu(item)
        }}>
          <div
              className="bar-container"

          >
            <div
                className="bar"
                style={{
                  backgroundColor: theme.color['in'] || '#fff',
                }}
            ></div>
          </div>
          <div className="info">
            <span className="title">{item.name}</span>
            <span className="subtitle"> {item.email}</span>
          </div>
          <div className={`price`}>
            {' '}
            <span className="clock">
                          {' '}
              {formatToReal(parseFloat(balance))}{' '}
                        </span>
          </div>
          <div className="action">
            <i className="material-icons icon">navigate_next</i>
          </div>
        </TransactionItem>
      </>
  )
}
