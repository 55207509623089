import React, {useEffect, useCallback, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Container, ContainerBox, NoteContainer, OuterBox, ContainerButtons,ButtonItem} from './styles'
import * as CompanyActions from '../../../store/modules/company/actions'
import DisplayInfos from './DisplayInfo'

import TransactionByCompany from '../../Finance/TransactionByCompany'
import ModalRmAmount from "./modalRmAmount";
import ModalDelete from "./modalDelete";
import ModalAddAmount from "./modalAddAmount";


export default function ClientDetail() {
  const theme = useTheme()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { company } = useSelector((state) => state.company)
    const [addAmountModal, setaddAmountModal] = useState(false)
    const [rmAmountModal, setrmAmountModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    dispatch(
      CompanyActions.CompanyGetDetail({ id: params.id, callback: () => {} }),
    )
  }, [dispatch])

  return (
    <Container>
      <h1>Sub-conta</h1>
      <div className="horizontal">
        <p>Detalhes da sub conta</p>
      </div>
      <OuterBox>
        <ContainerBox>
          {company.id != undefined && <DisplayInfos item={company} />}
            <br></br><br></br>
            <ContainerButtons>
                <ButtonItem  onClick={()=>setrmAmountModal(true)}>
                    <i className="material-icons">remove_circle</i>
                    Retirar valor
                </ButtonItem>
                <ButtonItem onClick={()=>setaddAmountModal(true)}>
                    <i className="material-icons">add_circle</i>
                    Enviar valor
                </ButtonItem>
                <ButtonItem onClick={()=>setDeleteModal(true)}>
                    <i className="material-icons">delete_sweep</i>
                    Deletar
                </ButtonItem>
            </ContainerButtons>
        </ContainerBox>

      </OuterBox>
    <TransactionByCompany client_id={parseInt(params.id)} client_type="customer" />
        <ModalRmAmount onClose={() => setrmAmountModal(false)} visible={rmAmountModal} company={company} />
        <ModalDelete onClose={() => setDeleteModal(false)} visible={deleteModal} company={company} />
        <ModalAddAmount onClose={() => setaddAmountModal(false)} visible={addAmountModal} company={company} />

     
    </Container>
  )
}
