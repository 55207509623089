import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
  margin-top: 50px;
  min-width: 100%;
  flex-flow: column;
  -webkit-flex-flow: column;

  position: relative;
  .content {
    max-width: 800px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${({ theme }) => theme.color.grayText};
    position: relative;
  }
  p {
    font-weight: 100;
  }
    
  .container-pay{
    flex-direction: row;
    margin: 12px 0;
    .display-pay{
      align-items: flex-start;
      width:33%;
      & div {
        align-items: flex-start;
      }
    }
  }
`;

export const Header = styled.div`
  flex-direction: row;
  flex-flow: row nowrap;
  -webkit-flex-flow: row nowrap;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  i {
    margin-left: 20px;
    margin-top: 5px;
    color: ${({ theme }) => theme.color.grayText};
  }
`;
