import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history'
import apiPayment from '../../../services/apiPayment'
import GetErrors from '../../../services/errors'
import * as DepositActions from './actions'
import * as GeneralActions from '../general/actions'
import moment from 'moment'

export function* depositsRequest(action) {
  try {
    const { per_page, page } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/deposits',
      {
        per_page,
        page,
      },
    )
    yield put(DepositActions.depositRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newDeposit(action) {
  try {
    const { amount, type, callback } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/deposit',
      {
        amount,
        type,
      },
    )
    yield put(DepositActions.depositRequest({ page: 1, per_page: 3 }))
    yield put(DepositActions.newDepositSuccess({ deposit: data }))
    if (callback) {
      callback({ deposit: data })
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* getDepositStatus(action) {
  try {
    const { id } = action.payload

    const { data } = yield call(
      apiPayment.get,
      `/payment/partner/v1/web/deposit/${id}`,
    )
    yield put(DepositActions.getDepositStatusSuccess({ deposit: data }))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    // yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* CancelDeposit(action) {
  try {
    const { id } = action.payload

    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.put,
      `/payment/partner/v1/web/deposit/${id}`,
    )
    yield put(DepositActions.depositRequest({ page: 1, per_page: 3 }))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    // yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* depositGet(action) {
  try {
    const { id,type } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    let url = `/payment/partner/v1/web/deposit/${id}`
    switch (type){
      case "customer":
        url = `/payment/partner/v1/web/customer/deposit/${id}`
        break;
      case "partner":
        url = `/payment/partner/v1/web/deposit/${id}`
        break;
    }
    const { data } = yield call(
      apiPayment.get,
      url,
    )
    yield put(DepositActions.Detail(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export default all([
  takeLatest('@deposit/DEPOSITS_REQUEST', depositsRequest),
  takeLatest('@deposit/NEW_DEPOSIT', newDeposit),
  takeLatest('@deposit/GET_DEPOSIT', getDepositStatus),
  takeLatest('@deposit/CANCEL_DEPOSIT', CancelDeposit),
  takeLatest('@deposit/DEPOSIT_GET', depositGet),
])
