import produce from "immer";

const INITIAL_STATE = {
  pixes: [],
  page: 1,
  pages: 1,
  total: 0,
};

export default function Transfer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@pix/LIST_REQUEST":
      return produce(state, (draft) => {
        draft.pixes = [];
        draft.page = 1;
        draft.pages = 0;
        draft.total = 0;
      });
    case "@pix/LIST_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.pixes = action.payload.data;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    default:
      return state;
  }
}
