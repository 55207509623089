import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import * as Yup from "yup";

import {ContainerPage} from "../../../../components/Cards/ClientCreate/styles";
import {ButtonDefault} from "../../../../components/Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons} from "./styles";
import {useSelector} from "react-redux";

import {
    PDFDownloadLink,
    Document,
    Page,
    Text,
    View,
    Image,
} from '@react-pdf/renderer'

import {formatCnpj, formatDate, formatDateHour, formatToReal} from "../../../../utils/format";
import {ButtonDownload} from "../styles";
import logoIcon from "../../../../assets/images/packman-white.png";
import Typography from "@mui/material/Typography";

const buyValidSchema = Yup.object().shape({});

const initialValues = {
    amount: "0", currency: ""
};


const initialErrors = {
    amount: "-", currency: "-"
};


const diplayValues = [{key: 'balance_before', name: 'Saldo atual', value: '', position: 'row'}, {
    key: 'quantity_formated',
    name: 'Quantidade',
    value: '',
    position: 'row'
}, {key: 'price', name: 'Preço', value: '', position: 'row'}, {
    key: 'total',
    name: 'Total',
    value: '',
    position: 'row'
}, {key: 'balance_after', name: 'Saldo após', value: '', position: 'row'}]


const TransferModal = ({visible, onClose}) => {
    const [position, setPosition] = useState(0);
    var carousel = null;
    const theme = useTheme();

    const account = useSelector((state) => state.account.account)

    const {transfer} = useSelector((state) => state.transfer)

    const DocumentTransfer = ({transfer, account}) => {
        return (
            <Document>
                <Page
                    size="A4"
                    style={{paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35}}
                >
                    <View>
                        <Image
                            style={{
                                height: 50,
                                width: 50,
                                backgroundColor: 'black',
                            }}
                            src={logoIcon}
                        />
                    </View>
                    <Text style={{fontSize: 24, textAlign: 'left', marginTop: 12}}>
                        Comprovante de Transferência
                    </Text>
                    <Text style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}>
                        {' '}
                        Valor:{' '}
                    </Text>
                    <Text style={{marginTop: 4, fontSize: 10}}>
                        {' '}
                        {formatToReal(parseFloat(transfer.amount))}{' '}
                    </Text>
                    <View>
                        <View
                            style={{borderBottom: '1px dashed black', paddingVertical: 4}}
                        >
                            <Text style={{marginTop: 12, fontWeight: '400', fontSize: 18}}>
                                {' '}
                                Conta Origem:{' '}
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    De:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {account.name}{' '}
                                </Text>
                            </View>
                            <View style={{marginLeft: 10}}>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Documento:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {formatCnpj(account.document)}
                                </Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Instituição:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {account.bank_name}{' '}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View
                            style={{borderBottom: '1px dashed black', paddingVertical: 4}}
                        >
                            <Text style={{marginTop: 12, fontWeight: '400', fontSize: 18}}>
                                {' '}
                                Conta Destino:{' '}
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    Para:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.name}{' '}
                                </Text>
                            </View>
                            <View style={{marginLeft: 10}}>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Documento:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.document}{' '}
                                </Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Instituição:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.bank_name}{' '}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View
                            style={{borderBottom: '1px dashed black', paddingVertical: 4}}
                        >
                            <Text style={{marginTop: 12, fontWeight: '400', fontSize: 18}}>
                                {' '}
                                Informações:{' '}
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    Tipo:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.type}{' '}
                                </Text>
                            </View>
                            <View style={{marginLeft: 10}}>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Data:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {formatDateHour(transfer.updatedAt)}
                                </Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    ID da transação:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.integration_id}{' '}
                                </Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        )
    }


    const handleCancel = () => {
        onClose()
    };

    const handleNextPagePosition = () => {
        carousel.goTo(position + 1);
        setPosition(position + 1);
    }


    return (<Modal show={visible} onHide={onClose} size="md" centered>
        <Modal.Header closeButton>
            <Modal.Title>Transfência {transfer?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <ContainerPage>
                    <div className="field">
                        <label>Tipo</label>
                        <input
                            type="text"
                            value={transfer.type}
                            disabled
                        ></input>
                    </div>
                    <div className="field">
                        <label>Data</label>
                        <input
                            type="text"
                            placeholder="Quantidade"
                            value={formatDate(transfer.updatedAt)}
                            disabled
                        ></input>
                    </div>
                    <div className="field">
                        <label>Nome do Destino:</label>
                        <input
                            type="text"
                            placeholder="Nome da Origem"
                            value={transfer.name}
                            disabled
                        ></input>
                    </div>
                    {transfer.document != '' && (
                        <div className="field">
                            <label>Documento</label>
                            <input
                                type="text"
                                placeholder="Documento"
                                value={transfer.document}
                                disabled
                            ></input>
                        </div>
                    )}
                    {transfer.bank_name != '' && (
                        <div className="field">
                            <label>Instituição</label>
                            <input
                                type="text"
                                placeholder="Instituição"
                                value={transfer.bank_name}
                                disabled
                            ></input>
                        </div>
                    )}
                    <div className="field">
                        <label>ID da transação</label>
                        <input
                            type="text"
                            placeholder="ID da transação"
                            value={transfer.integration_id}
                            disabled
                        ></input>
                    </div>
                    <br></br>
                    <div className="field">
                        <label>Valor</label>
                        <input
                            type="text"
                            placeholder="Valor"
                            value={formatToReal(parseFloat(transfer.amount))}
                            disabled
                        ></input>
                    </div>


                </ContainerPage>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <ContainerButtons>
                <ButtonDefault
                    title="Fechar"
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    width="170px;"
                    onClick={handleCancel}
                />
                {transfer.integration_id && (
                    <PDFDownloadLink
                        document={<DocumentTransfer transfer={transfer} account={account}/>}
                        fileName={`transfer_${transfer.integration_id}.pdf`}
                        className="button-pdf"
                    >
                        {({blob, url, loading, error}) =>
                            loading ? (
                                'Loading document...'
                            ) : (
                                <>Baixar</>
                            )
                        }
                    </PDFDownloadLink>
                )}
            </ContainerButtons>
        </Modal.Footer>
    </Modal>)
}

export default TransferModal
