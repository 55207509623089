export function MyClientsRequest({ per_page, page }) {
  return { type: "@myclient/LIST_REQUEST", payload: { per_page, page } };
}

export function MyClientsRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@myclient/LIST_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}


export function MyClientDetail({ myclient }) {
  return {
    type: "@myclient/DEFINE_DETAIL",
    payload: { myclient },
  };
}




export function newMyClient({
  name,
  email,
  phone,
  document,
  zip_code,
  address,
  neighborhood,
  city,
  state,
  number,
  complement,
  callback,
}) {
  return {
    type: "@myclient/NEW",
    payload: {
      name,
      email,
      phone,
      document,
      zip_code,
      address,
      neighborhood,
      city,
      state,
      number,
      complement,
      callback,
    },
  };
}


export function billingResumeSuccess({ totalResume }) {
  return {
    type: "@myclient/RESUME_BILLING_SUCCESS",
    payload: { totalResume }
  };
}


export function resumeBilling({
  callback,
  my_client_id,
}) {
  return {
    type: "@myclient/RESUME_BILLING",
    payload: {
      callback,
      my_client_id,
    }
  };
}