import { all, takeLatest, put, call, select } from "redux-saga/effects";
import history from "../../../services/history";
import apiCustomer from "../../../services/apiCostumer";
import GetErrors from "../../../services/errors";
import * as NfActions from "./actions";
import * as GeneralActions from "../general/actions";
import moment from "moment";

export function* nfsRequest(action) {
  try {
    const { per_page, page } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    const { data } = yield call(
      apiCustomer.post,
      "/nfs",
      { per_page, page },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(NfActions.nfRequestSuccess(data));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* newNf(action) {
  try {
    const { amount, type, callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    const { data } = yield call(
      apiCustomer.post,
      "/nf",
      {
        amount,
        type,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(NfActions.nfRequest({ page: 1, per_page: 3 }));
    yield put(NfActions.newNfSuccess({ nf: data }));
    if (callback) {
      callback({ nf: data });
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* CancelNf(action) {
  try {
    const { id } = action.payload;

    console.log(action.payload);
    yield put(GeneralActions.setLoading({ loading: true }));
    const { token } = yield select((state) => state.user);
    const { data } = yield call(
      apiCustomer.put,
      `/nf/${id}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(NfActions.nfRequest({ page: 1, per_page: 3 }));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    // yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeLatest("@nf/NFS_REQUEST", nfsRequest),
  takeLatest("@nf/NEW_NF", newNf),
  takeLatest("@nf/CANCEL_NF", CancelNf),
]);
