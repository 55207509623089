import {
  all,
  takeLatest,
  put,
  call,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects'

import {
  CreateAddressSuccess,
  GetCurrenciesSuccess,
  GetLedgers,
  GetLedgersSuccess,
  GetWallet,
  GetWalletSuccess,
  ListCryptoDepositSuccess,
  ListCryptoWithdrawSuccess,
  ListOrdersSuccess,
  NetworksListSuccess,
  SelectWallet,
  SelectWalletSuccess,
  WalletDefaultTotalSuccess,
  WithdrawSuccess,
} from './actions'
import history from '../../../services/history'

import apiCrypto from '../../../services/apiCrypto'


import * as AccountActions from '../../modules/account/actions'
import {balanceRequest} from "../../modules/account/actions";
import * as GeneralActions from "../general/actions";

export function* walletRequest(action) {
  const { callback } = action.payload
  try {
    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/exchange/wallets`,
        { per_page: 100, page: 1 },
    )
    const responseCurrency = yield call(
        apiCrypto.post,
        '/crypto/partner/v1/mobile/currencies',
        {
          per_page: 100,
          page: 1,
        },
    )

    let totalInReal = 0
    let totalUSD = 0

    let selectedCurrencyUsdt = responseCurrency.data.data.find(
        (item) => item.symbol_base == 'CUSD',
    )

    data.data.forEach((wallet) => {
      wallet.totalInReal = 0
      let selectedCurrency = responseCurrency.data.data.find(
          (item) => item.symbol == wallet.currency,
      )
      if (selectedCurrency != undefined) {
        wallet.totalInReal =
            parseFloat(wallet.balance) * parseFloat(selectedCurrency.price_sell_brl)
        totalInReal += wallet.totalInReal
        wallet.price_buy_brl = parseFloat(selectedCurrency.price_buy_brl)
        wallet.price_sell_brl = parseFloat(selectedCurrency.price_sell_brl)
        wallet.blockchain = selectedCurrency.blockchain_name;
      }
    })

    if (callback != null) {
      callback(data)
    }
    totalUSD = totalInReal / parseFloat(selectedCurrencyUsdt.price_sell_brl)

    yield put(WalletDefaultTotalSuccess({ totalInReal, totalUSD }))
    yield put(GetWalletSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* ledgersRequest(action) {
  const { per_page, page, callback } = action.payload
  try {
    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/ledgers`,
        { per_page, page },
    )
    yield put(GetLedgersSuccess(data))

    if (callback != null) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* currencyRequest(action) {
  try {
    const { per_page, page, callback } = action.payload
    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/currencies`,
        { per_page, page },
    )
    if (callback != null) {
      callback(data)
    }

    yield put(GetCurrenciesSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* buyRequest(action) {
  const { callback, amount, currency, price, type } = action.payload
  try {
    let priceFloat = parseFloat(price.replace(/[\.^\R$\ ^\/]/gi, '')
    .replace(',', '.'))

    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/exchange/buy`,
        { amount: parseFloat(amount), currency, price : priceFloat , type },
    )

    yield put(
        GetLedgers({
          callback: () => {},
          per_page: 5,
          page: 1,
        }),
    )

    yield put(
        GetWallet({
          callback: () => {},
        }),
    )
    yield put(AccountActions.balanceRequest({ callback: () => {} }))
    yield put(
        SelectWallet({
          callback: () => {},
          currency: currency,
        }),
    )

    if (callback != null) {
      data.status = "done"
      callback(data)
    }
  } catch (error) {
    let data = error.response?.data || '0'
    if (callback != null) {
      data.status = "error"
      callback(data)
    }
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* deleteRequest(action) {
  const { callback, id } = action.payload
  try {
    const { data } = yield call(
        apiCrypto.delete,
        `/crypto/partner/v1/mobile/exchange/buy/${id}`,
    )

    yield put(
        GetLedgers({
          callback: () => {},
          per_page: 5,
          page: 1,
        }),
    )

    yield put(
        GetWallet({
          callback: () => {},
        }),
    )
    yield put(AccountActions.balanceRequest({ callback: () => {} }))

    if (callback != null) {
      data.status = "done"
      callback(data)
    }
  } catch (error) {
    let data = error.response?.data || '0'
    if (callback != null) {
      data.status = "error"
      callback(data)
    }
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* sellRequest(action) {
  const { callback, amount, currency, price, type } = action.payload
  try {
    let priceFloat = parseFloat(price.replace(/[\.^\R$\ ^\/]/gi, '')
    .replace(',', '.'))
    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/exchange/sell`,
        { amount: parseFloat(amount), currency, price : priceFloat , type },
    )

    if (callback != null) {
      callback(data)
    }
    yield put(
        GetLedgers({
          callback: () => {},
          per_page: 5,
          page: 1,
        }),
    )

    yield put(
        GetWallet({
          callback: () => {},
        }),
    )
    yield put(AccountActions.balanceRequest({ callback: () => {} }))
    yield put(
        SelectWallet({
          callback: () => {},
          currency: currency,
        }),
    )
    if (callback != null) {
      data.status = "done"
      callback(data)
    }
  } catch (error) {
    let data = error.response?.data || '0'
    if (callback != null) {
      data.status = "error"
      callback(data)
    }
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* selectRequest(action) {
  const { currency, callback } = action.payload
  try {
    const { data } = yield call(
        apiCrypto.get,
        `/crypto/partner/v1/mobile/exchange/wallet/${currency}`,
    )
    let response = { wallet: data, callback: () => {} }
    yield put(SelectWalletSuccess(response))
    if (callback != null) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* networksRequest(action) {
  try {
    const { callback, per_page, page, query } = action.payload

    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/networks`,
        { per_page: per_page, page: page, query: query },
    )
    if (callback != null) {
      callback(data)
    }
    if (data.data.length > 0) {
      // put(cryptoActions.selectCryptoNetwork(response.data.data[0]));
    }

    yield put(NetworksListSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* withdrawRequest(action) {
  const { currency, amount, address, callback } = action.payload
  try {
    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/web/withdraw`,
        { currency, amount: parseFloat(amount), address },
    )

    yield put(WithdrawSuccess(data))
    yield put(
        GetLedgers({
          callback: () => {},
          per_page: 5,
          page: 1,
        }),
    )
    yield put(
        GetWallet({
          callback: () => {},
        }),
    )
    yield put(AccountActions.balanceRequest({ callback: () => {} }))
    yield put(
        SelectWallet({
          callback: () => {},
          currency: currency,
        }),
    )
    if (callback != null) {
      data.status = "done"
      callback(data)
    }
  } catch (error) {
    let data = error.response?.data || '0'
    if (callback != null) {
      data.status = "error"
      callback(data)
    }
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* ListDeposits(action) {
  try {
    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/deposits`,
        action.payload,
    )
    yield put(ListCryptoDepositSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* ListWithdraw(action) {
  try {
    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/web/withdraws`,
        action.payload,
    )
    yield put(ListCryptoWithdrawSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* CreateAddress(action) {
  try {
    const { currency, callback } = action.payload
    // console.log({type, amount, callback})
    const { data } = yield call(apiCrypto.post, '/crypto/partner/v1/mobile/address', {
      currency,
    })
    yield put(CreateAddressSuccess(data))
    if (callback != null) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  }
}


export function* ordersRequest(action) {
  try {
    const { callback, per_page, page, query } = action.payload

    const { data } = yield call(
        apiCrypto.post,
        `/crypto/partner/v1/mobile/exchanges`,
        { per_page: per_page, page: page, query: query },
    )
    if (callback != null) {
      callback(data)
    }

    yield put(ListOrdersSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export default all([
  takeEvery("@crypto/WALLET_REQUEST", walletRequest),
  takeEvery("@crypto/LEDGERS_REQUEST", ledgersRequest),
  takeEvery("@crypto/CURRENCY_REQUEST", currencyRequest),
  takeEvery("@crypto/BUY_CRYPTO", buyRequest),
  takeEvery("@crypto/WALLET_SELECT", selectRequest),
  takeEvery("@crypto/NETWORK_REQUEST", networksRequest),
  takeEvery("@crypto/WITHDRAW_REQUEST", withdrawRequest),
  takeEvery("@crypto/SELL_CRYPTO", sellRequest),
  takeEvery("@crypto/CRYPTO_DEPOSIT_LIST", ListDeposits),
  takeEvery("@crypto/CRYPTO_WITHDRAW_LIST", ListWithdraw),
  takeEvery("@crypto/CREATE_ADDRESS", CreateAddress),
  takeEvery("@crypto/ORDERS_LIST", ordersRequest),
  takeEvery("@crypto/DELETE_CRYPTO",deleteRequest)
])
