export function SuppliersRequest({ per_page, page }) {
  return { type: "@supplier/LIST_REQUEST", payload: { per_page, page } };
}

export function SuppliersRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@supplier/LIST_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}




export function newSupplier({
  name,
  email,
  phone,
  document,
  zip_code,
  address,
  neighborhood,
  city,
  state,
  number,
  complement,
  callback,
}) {
  return {
    type: "@supplier/NEW",
    payload: {
      name,
      email,
      phone,
      document,
      zip_code,
      address,
      neighborhood,
      city,
      state,
      number,
      complement,
      callback,
    },
  };
}



export function MySupplierDetail({ supplier }) {
  return {
    type: "@supplier/DEFINE_DETAIL",
    payload: { supplier },
  };
}


export function billingResumeSuccess({ totalResume }) {
  return {
    type: "@supplier/RESUME_BILLING_SUCCESS",
    payload: { totalResume }
  };
}


export function resumeBilling({
  callback,
  my_client_id,
}) {
  return {
    type: "@supplier/RESUME_BILLING",
    payload: {
      callback,
      my_client_id,
    }
  };
}