import styled from "styled-components";



export const Container = styled.div`
    width:100%;
    display: inline-block;
    margin:20px 0;
`;


export const ContainerLabel = styled.div`
    flex-direction: row; 
`;


export const ItemLabel = styled.div`
    flex:1;
    color:#747474;
    font-family: "Roboto-Light", sans-serif;
    font-size: 16px;
`;

export const ItemTotal = styled.div`
    flex:1;
    text-align: right;
    color:#747474;
    font-family: "Roboto-Light", sans-serif;
    font-size: 16px;
`;

export const Progress = styled.div`
    width:100%;
    height:16px;
    border 1px solid #707070;
    border-radius: 21px;
    margin:10px 0;
    background:#707070;
`;

export const ProgressContent = styled.div`
    height: 14px;
    background: #FFFFFF;
    border-radius: 21px 0px 0 21px;
`;