import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history'
import apiPayment from '../../../services/apiPayment'
import GetErrors from '../../../services/errors'
import * as InviteActions from './actions'
import * as GeneralActions from '../general/actions'
import moment from 'moment'

export function* InviteRequest(action) {
  try {
    const { per_page, page } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(apiPayment.post, '/partner/v1/invites', {
      per_page,
      page,
    })
    yield put(InviteActions.InviteRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newInvite(action) {
  try {
    const { callback } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } =  yield call(apiPayment.post, '/partner/v1/invite', action.payload)
    yield put(InviteActions.InviteRequest({ page: 1, per_page: 3 }))
    if (callback) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}



export default all([
  takeLatest('@invite/LIST_REQUEST', InviteRequest),
  takeLatest('@invite/NEW', newInvite),
])
