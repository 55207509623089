import styled from "styled-components";

export const Container = styled.div`
  width: 280px;
  min-height: 160px;
  background-color: ${({ theme }) => theme.color.blackButton};
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  -webkit-box-shadow: 5px 5px 25px -15px #000000;
  box-shadow: 5px 5px 25px -15px #000000;
  justify-content: space-between;
  margin: 20px;
`;
