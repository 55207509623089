import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { InputMask } from "../../../components/Inputs";
import { formatToReal, formatToRealDolar } from "../../../utils/format";
import { Formik } from "formik";
import * as Yup from "yup";

import Carousel from "react-elastic-carousel";
import {
  ContainerPage,
  ContainerPagination,
} from "../../../components/Cards/ClientCreate/styles";
import { ButtonDefault } from "../../../components/Buttons";
import { useTheme } from "styled-components";
import { ContainerButtons } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import * as CryptoActions from "../../../store/modules/crypto/actions";
import ProgressBar from "react-bootstrap/ProgressBar";
import Alert from "react-bootstrap/Alert";
const buyValidSchema = Yup.object().shape({});

const initialValues = {
  amount: "0",
  currency: "",
};

const initialErrors = {
  amount: "-",
  currency: "-",
};

const diplayValues = [
  { key: "balance_before", name: "Saldo atual", value: "", position: "row" },
  {
    key: "quantity_formated",
    name: "Quantidade",
    value: "",
    position: "row",
  },
  { key: "price", name: "Preço", value: "", position: "row" },
  {
    key: "total",
    name: "Total",
    value: "",
    position: "row",
  },
  { key: "balance_after", name: "Saldo após", value: "", position: "row" },
];

const renderPagination = ({pages, activePage, onClick}, success) => {
  return (<ContainerPagination>
    {pages.map((page) => {
      const isActivePage = activePage === page;
      return (<li
          className={`dot ${isActivePage ? "selected" : ""} ${success ? "success" : ""}`}
          key={page}
      />);
    })}
  </ContainerPagination>);
};


const ModalOrder = ({ visible, order, onClose }) => {
  const balance = useSelector((state) => state.account.balance);
  const [position, setPosition] = useState(0);
  var carousel = null;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [send, setSend] = useState(false);
  const [progress, setProgress] = useState(0);
  const [variant, setVariant] = useState("info");
  const { page, per_page } = useSelector((state) => state.crypto.orders);

  const handleCancel = () => {
    onClose();
  };

  const handleDeleteOrder = () => {
    handleNextPagePosition();
    setSend(true);
    dispatch(
        CryptoActions.DeleteRequest({
          id: order.id,
          callback: (data) => {
            if (data.status == "error") {
              setVariant("danger");
              setError(true);
            }
            if (data.status == "done") {
              dispatch(
                  //filter === id
                  CryptoActions.LisOrders({ per_page: per_page, page: page })
              );
              setVariant("success");
              setSuccess(true);
            }
            setProgress(100);
          },
        })
    );
  };
  const handleNextPagePosition = () => {
    carousel.goTo(position + 1);
    setPosition(position + 1);
  };

  useEffect(() => {
    setPosition(0);
    setSuccess(false);
    setError(false);
    setProgress(0);
    setVariant("info");
    setSend(false);
  }, [order]);

  return (
      <Modal show={visible} onHide={onClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes {order?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Carousel
                showArrows={false}
                enableSwipe={false}
                ref={(ref) => (carousel = ref)}
                renderPagination={(renderPropos) => {
                  return renderPagination(renderPropos, success);
                }}
            >
            <ContainerPage>
              <div className="field">
                <label>Preço</label>
                <input
                    type="text"
                    name="price"
                    placeholder="price"
                    value={formatToRealDolar(parseFloat(order?.price))}
                    disabled
                ></input>
              </div>
              <div className="field">
                <label>Quantidade</label>
                <input
                    type="text"
                    placeholder="Quantidade"
                    value={`${parseFloat(order?.amount).toFixed(2)}  ${
                        order?.currency
                    }`}
                    disabled
                ></input>
              </div>
              <div className="field">
                <label>Tipo</label>
                <input
                    type="text"
                    placeholder="Quantidade"
                    value={order?.action}
                    disabled
                ></input>
              </div>
              <div className="field">
                <label>Total</label>
                <input
                    type="text"
                    placeholder="Quantidade"
                    value={formatToReal(parseFloat(order?.total_amount))}
                    disabled
                ></input>
              </div>
              <br></br>
              <div className="field">
                {order?.status == "pending" && (
                    <Alert key={"warning"} variant={"warning"}>
                      Sua transação ainda está pendente
                    </Alert>
                )}
                {order?.status == "processing" && (
                    <Alert key={"warning"} variant={"warning"}>
                      Sua transação está sendo processada
                    </Alert>
                )}
                {order?.status == "error" && (
                    <Alert key={"danger"} variant={"danger"}>
                      {" "}
                      Ocorreu um erro na transação e o valor será devolvido para sua
                      conta.
                    </Alert>
                )}
                {order?.status == "done" && (
                    <Alert key={"success"} variant={"success"}>
                      Sua transação foi processada com sucesso
                    </Alert>
                )}
              </div>
            </ContainerPage>
            <ContainerPage>
              {variant == "info" && "Estamos processando sua transação"}
              <ProgressBar variant={variant} now={progress} label={`${progress}%`}/>
              {variant == "danger" && "Houve um erro, favor tentar novamente."}
              {variant == "success" && "Sua transação foi recebida e está sendo processada."}
            </ContainerPage>
            </Carousel>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <ContainerButtons>
            <ButtonDefault
                title="Fechar"
                background={theme.color.white}
                color={theme.color.blackButton}
                width="165px;"
                onClick={handleCancel}
            />
            {order?.type === "limit" && order.status == "processing"  && (
                <>
                  <ButtonDefault
                      title="Cancelar"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="165px;"
                      onClick={handleDeleteOrder}
                  />
                </>
            )}
          </ContainerButtons>
        </Modal.Footer>
      </Modal>
  );
};

export default ModalOrder;
