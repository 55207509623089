import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Wave, Card } from "./styles";

export default function NotFound() {
  const history = useHistory();
  return (
    <Container>
      <Card>
        <h1 onClick={() => history.push("/")}>Voltar</h1>
        <div>
          <div className="check-icon">
            <i className="fa fa-times fa-2x"></i>
          </div>
          <p>humm.. essa pagina não esta disponível</p>
        </div>
      </Card>
      <Wave>
        <div className="block"></div>
      </Wave>
    </Container>
  );
}
