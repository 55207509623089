import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import PropTypes from "prop-types";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { token } = useSelector(state => state.user);

  if (!!token) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object
  ]).isRequired
};
