import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Image, Button, Info,InfoImage,TextInfo } from './styles'
import * as UserActions from '../../../store/modules/user/actions'
import logoIcon from '../../../assets/images/pac-man.png'

export default function UserInformations() {
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.user)
  const logout = () => {
    dispatch(UserActions.logout())
  }
  return (
    <Container>
      <Info>
        <TextInfo>{data?.name}</TextInfo>
        <TextInfo>{data?.email}</TextInfo>
      </Info>
      <Button>
        <i className="material-icons md-24">notifications</i>
      </Button>
      <Button onClick={logout}>
        <i className="material-icons md-24">logout</i>
      </Button>
    </Container>
  )
}
