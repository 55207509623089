import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, ContainerInputs } from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as PixActions from "../../../store/modules/pix/actions";
import * as AccountActions from "../../../store/modules/account/actions";
import Limit from "../../Limit";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputMask } from "../../Inputs";
import * as GeneralActions from "../../../store/modules/general/actions";
import { setOnClipboard } from "../../../utils/clipboard";

const PixSchema = Yup.object().shape({
  // // value: Yup.string().required("campo obrigatório"),
  // type: Yup.string().required("campo obrigatório"),
});

const initialValues = {
  value: "",
  type: "SELECIONAR",
};

const TransferCreate = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [types] = useState(["SELECIONAR", "CNPJ", "EMAIL", "PHONE", "EVP"]);
  const [success, setSuccess] = useState(false);

  const [keyRef, setKey] = useState("");

  const { bank } = useSelector((state) => state.transfer);

  const { limits } = useSelector((state) => state.account);
  const company = useSelector((state) => state.user.company);

  const handleCancel = () => {
    history.push("/financeiro/pix");
  };

  const changeType = (prop, props) => {
    switch (prop.target.value) {
      case "CNPJ":
        props.setFieldValue("value", company.document);
        props.setFieldTouched("value", true);
        break;
      case "EMAIL":
        props.setFieldValue("value", company.email);
        props.setFieldTouched("value", true);
        break;
      default:
        props.setFieldValue("value", "");
        props.setFieldTouched("value", true);
        break;
    }
    props.setFieldValue("type", prop.target.value);
    props.setFieldTouched("type", true);
  };

  useEffect(() => {}, [dispatch]);

  const handleSubmitForm = (values) => {
    let value = "";
    switch (values.type) {
      case "PHONE":
        value = values.value.replace(/[^A-Z0-9]+/gi, "");
        break;
      default:
        value = values.value;
        break;
    }
    dispatch(
      PixActions.NewKey({
        ...values,
        value: value,
        callback: () => {
          setSuccess(true);
          setKey(value);
        },
      })
    );
  };
  const copy = (value) => {
    dispatch(GeneralActions.setMessage({ message: "chave copiado" }));
    setOnClipboard(value);
  };

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <p>Chave criado com sucesso</p>
          <ButtonDefault
            title="Copiar"
            background={theme.color.blackButton}
            color={theme.color.white}
            width="170px;"
            onClick={copy(keyRef)}
          />
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={PixSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <>
            <div className="field">
              <input
                type="text"
                name="label"
                placeholder="Label"
                value={props.values.label}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              ></input>
            </div>
            <ContainerInputs>
              <label htmlFor="document">Tipo</label>
              <select
                type="text"
                name="type"
                placeholder="Tipo"
                value={props.values.type}
                onChange={(prop) => changeType(prop, props)}
                onBlur={props.handleBlur}
              >
                {types.map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </select>
            </ContainerInputs>
            {props.values.type == "PHONE" ||
              (props.values.type == "CNPJ" && (
                <ContainerInputs>
                  <label htmlFor="phone">
                    {props.values.type == "PHONE" && (
                      <>
                        {" "}
                        <strong>Telefone</strong> com DDD
                      </>
                    )}
                    {props.values.type == "CNPJ" && (
                      <>
                        {" "}
                        <strong>Cnpj</strong> da empresa
                      </>
                    )}
                  </label>
                  <InputMask
                    id="value"
                    format={props.values.type.toLocaleLowerCase()}
                    type="text"
                    name="value"
                    autoCapitalize="none"
                    placeholder="Telefone"
                    placeholder=""
                    value={props.values.value}
                    disabled={props.values.type == "CNPJ"}
                  />
                </ContainerInputs>
              ))}
            <div className="container-buttons">
              <div className="horizontal">
                <ButtonDefault
                  title="Salvar"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={props.handleSubmit}
                />
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  width="170px;"
                  onClick={handleCancel}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default TransferCreate;
