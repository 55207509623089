import React, { useEffect, useState, useMemo } from 'react'
import {
  Container,
  ContainerSaldo,
  ContainerList,
  ContainerTotal,
  Item,
  ContainerButtons,
  ButtonItem,
} from './styles'
import { ButtonDefault } from '../../../components/Buttons'
import { setOnClipboard } from '../../../utils/clipboard'

import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import 'moment/locale/pt-br'
import QRCode from 'react-qr-code'
import { TextMoney } from '../HomeEntries/styles'

import ModalBuy from './modalBuy'
import ModalSell from './modalSell'
import ModalTransfer from './modalTransfer'
import * as CryptoActions from '../../../store/modules/crypto/actions'
import ModalTransactions from './ModalTransactions'
import { formatToRealDolar } from '../../../utils/format'

const DigitalMoney = ({
  navigation,
  symbol,
  balance,
  address,
  price_buy_brl,
  price_sell_brl,
  blockchain,
  counter
}) => {
  const theme = useTheme()
  const { data } = useSelector((state) => state.account.resume)
  const { partner } = useSelector((state) => state.user)
  const [buyModal, setBuyModal] = useState(false)
  const [sellModal, setSellModal] = useState(false)
  const [transferModal, setTransferModal] = useState(false)
  const [transactionsModal, setTransactionsModal] = useState(false)
  const dispatch = useDispatch()
  const [date, setDate] = useState(new Date())


  useEffect(() => {
    dispatch(
      CryptoActions.SelectWallet({
        callback: () => {},
        currency: symbol,
      }),
    )
  }, [])


  return (
    <>
      <ModalBuy
        visible={buyModal}
        onClose={() => {
          setBuyModal(false)
          setDate(new Date())
        }}
        currency={symbol}
        price={price_buy_brl}
        time={date}
        balance={balance}
        counter={counter}
      />
      <ModalSell
        visible={sellModal}
        onClose={() => {
          setSellModal(false)
          setDate(new Date())
        }}
        currency={symbol}
        price={price_sell_brl}
        time={date}
        balance={balance}
        counter={counter}
      />
      <ModalTransfer
        visible={transferModal}
        onClose={() => {
            setTransferModal(false)
            setDate(new Date())
        }}
        currency={symbol}
        blockchain={blockchain}
        balance={balance}
        time={date}
      />
      <ModalTransactions
        visible={transactionsModal}
        onClose={() => setTransactionsModal(false)}
        currency={symbol}
        balance={balance}
      />
      <Container>
        <ContainerSaldo>
          <h1>Carteira Digital {symbol}</h1>
        </ContainerSaldo>
        <p style={{ marginTop: 20, alignSelf: 'center' }}>
          {partner.crypto_deposit == 's' && (
            <>
              <br></br>
              Qrcode:<br></br>
              {address != '' && <QRCode value={address} />}
              <br></br>
              <br></br>
              Copiar endereço<br></br>
              <strong>{address}...</strong>
              <br></br>
              <ButtonDefault
                type="button"
                title="Copiar"
                background={theme.color.blackButton}
                color={theme.color.white}
                onClick={() => {
                  setOnClipboard(address)
                }}
              />
              <br></br>
            </>
          )}
          <h1>Saldo</h1>
          <TextMoney>
            {symbol} {balance}
          </TextMoney>
          <br></br>
          Preço atual: <strong>{formatToRealDolar(price_buy_brl)}</strong>
          <br></br>
          Cotação em <strong>tempo real</strong>
        </p>
        <ContainerButtons>
          {partner.crypto_buy == 's' && (
            <ButtonItem onClick={() => setBuyModal(true)}>
              <i className="material-icons">add_shopping_cart</i>
              Comprar
            </ButtonItem>
          )}

          {partner.crypto_sell == 's' && (
            <ButtonItem onClick={() => setSellModal(true)}>
              <i className="material-icons">shopping_cart</i>
              Vender
            </ButtonItem>
          )}
          {partner.crypto_withdraw == 's' && (
            <ButtonItem onClick={() => setTransferModal(true)}>
              <i className="material-icons">subdirectory_arrow_right</i>
              Transferir
            </ButtonItem>
          )}
          <ButtonItem onClick={() => setTransactionsModal(true)}>
            <i className="material-icons">sync_alt</i>
            Extrato
          </ButtonItem>
        </ContainerButtons>
      </Container>
    </>
  )
}

export default DigitalMoney
