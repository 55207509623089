import React from "react";
import { Container, ContainerItem, Item } from "./styles";
import PropTypes from "prop-types";

const resolveIcon = (type) => {
  switch (type) {
    case "picpay":
      return require(`../../assets/images/picpay.svg`);
    case "boleto":
      return require(`../../assets/images/boleto.svg`);
    case "pix":
      return require(`../../assets/images/pix-logo.svg`);
  }
};

function PyamentIcon({ type, size, textSize, className }) {
  return (
    <Container className={className}>
      <Item>
        <img src={resolveIcon(type)} style={{ height: `${size}px` }} />
        <div style={{ fontSize: `${textSize}px` }}>{type}</div>
      </Item>
    </Container>
  );
}

PyamentIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  textSize: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default PyamentIcon;
