export function registerUser({ person_type, user }) {
  return {
    type: "@register/REGISTER_USER",
    payload: { person_type, user },
  };
}

export function registerCompany({ person_type, company }) {
  return {
    type: "@register/REGISTER_COMPANY",
    payload: { person_type, company },
  };
}

export function registerAddress({ address }) {
  return {
    type: "@register/REGISTER_ADDRESS",
    payload: { address },
  };
}

export function registerPersonal({
  address: {
    zip_code,
    address_line,
    neighborhood,
    city,
    state,
    complement,
    building_number,
  },
  document_number,
  register_name,
  phone,
  document,
  birth_date,
  mother_name,
  email,
}) {
  return {
    type: "@register/REGISTER_PERSONAL",
    payload: {
      address: {
        zip_code,
        address_line,
        neighborhood,
        city,
        state,
        building_number,
        complement,
        building_number,
      },
      document_number,
      register_name,
      phone,
      document,
      birth_date,
      mother_name,
      email,
    },
  };
}

export function registerPersonalDocument({ front_document, back_document }) {
  return {
    type: "@register/REGISTER_PERSONAL_DOCUMENT",
    payload: { front_document, back_document },
  };
}

export function registerCompanyDocument({
  company_document,
  qsa_document,
  social_contract_document,
  yield_document,
}) {
  return {
    type: "@register/REGISTER_COMPANY_DOCUMENT",
    payload: {
      company_document,
      qsa_document,
      social_contract_document,
      yield_document,
    },
  };
}

export function checkCode({ code, callback }) {
  return { type: "@register/CHECK_CODE", payload: { code, callback } };
}

export function sendNewCode() {
  return { type: "@register/SEND_NEW_CODE" };
}
