export function depositRequest({ per_page, page }) {
  return { type: "@deposit/DEPOSITS_REQUEST", payload: { per_page, page } };
}

export function depositRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@deposit/DEPOSITS_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}
export function newDeposit({ amount, type, callback }) {
  return {
    type: "@deposit/NEW_DEPOSIT",
    payload: {
      amount,
      type,
      callback,
    },
  };
}

export function newDepositSuccess({ deposit }) {
  return {
    type: "@deposit/NEW_DEPOSIT_SUCCESS",
    payload: {
      deposit,
    },
  };
}
export function CancelDeposit(id) {
  return {
    type: "@deposit/CANCEL_DEPOSIT",
    payload: {
      id,
    },
  };
}

export function getDepositStatus(id) {
  return {
    type: "@deposit/GET_DEPOSIT",
    payload: {
      id,
    },
  };
}

export function getDepositStatusSuccess({ deposit }) {
  return {
    type: "@deposit/GET_DEPOSIT_SUCCESS",
    payload: {
      deposit,
    },
  };
}

export function Detail(deposit) {
  return {
    type: "@deposit/DEFINE_DETAIL",
    payload: { deposit },
  };
}

export function depositGet({ id, type }) {
  return {
    type: "@deposit/DEPOSIT_GET",
    payload: { id, type },
  };
}
