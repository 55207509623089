import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Container } from "./styles";
import wallet from "../../../assets/animations/wallet.gif";

export default function Fullscreen() {
  const { loading } = useSelector(state => state.general);
  const renderAnimation = useMemo(() => {
    if (loading) {
      return (
        <Container>
          <img src={wallet} />
        </Container>
      );
    }
    return <div></div>;
  }, [loading]);
  return renderAnimation;
}
