export function pendingBillingsRequest({ per_page, page }) {
  return {
    type: "@billing/PENDING_BILLING_REQUEST",
    payload: { per_page, page }
  };
}

export function billingsRequest({ per_page, page, query }) {
  return { type: "@billing/BILLING_REQUEST", payload: { per_page, page, query } };
}

export function billingsRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@billing/BILLING_REQUEST_SUCCESS",
    payload: { data, page, pages, total }
  };
}

export function newBilling({
  callback,
  installment_total,
  amount,
  my_client_id,
  first_due_date,
  title,
  instructions,
  payment_types
}) {
  return {
    type: "@billing/NEW_BILLING",
    payload: {
      callback,
      installment_total,
      amount,
      my_client_id,
      first_due_date,
      title,
      instructions,
      payment_types
    }
  };
}



export function newBillingSuccess({ billings }) {
  return {
    type: "@billing/NEW_BILLING_SUCCESS",
    payload: { billings }
  };
}