import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../../components/Buttons";
import { Container, ContainerBox, NoteContainer, OuterBox } from "./styles";
import { formatToReal } from "../../../utils/format";
import * as SupplierActions from "../../../store/modules/supplier/actions";
import * as BillingActions from "../../../store/modules/billing/actions";
import * as TransferActions from "../../../store/modules/transfer/actions";

import Paginator from "../../../components/Paginator";
import DisplayInfos from "../../../components/DisplayInfo"


const diplayValues = [
  { key: "name", name: "Nome", value: "", position: "row" },
  { key: "email", name: "Email", value: "", position: "row" },
  { key: "phone", name: "Telefone", value: "", position: "row" },
  { key: "document", name: "document", value: "", position: "row" },
  { key: "zip_code", name: "Cep", value: "", position: "row" },
  { key: "address", name: "Logradouro", value: "", position: "column" },
  { key: "neighborhood", name: "Bairro", value: "", position: "column" },
  { key: "city", name: "Cidade", value: "", position: "column" },
  { key: "state", name: "Estado", value: "", position: "column" },
  { key: "number", name: "Número", value: "", position: "column" },
  { key: "complement", name: "Complemento", value: "", position: "column" },
];



export default function SupplierDetail() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { supplier, totalResume } = useSelector(
    (state) => state.supplier
  );

  const { transfers, page, pages, total, accounts } = useSelector(
    (state) => state.transfer
  );


  useEffect(() => {
    if (supplier.name == undefined) {
      history.push("/crm/fornecedor")
    }
    dispatch(SupplierActions.resumeBilling({ my_client_id: supplier.id }));
    dispatch(TransferActions.transfersRequest({ per_page: 20, page: 1, query: { supplier_id: supplier.id } }));
    dispatch(TransferActions.accountsRequest({ per_page: 20, page: 1, query: { supplier_id: supplier.id } }));
  }, [dispatch, supplier]);

  const handlePagePress = useCallback((page) => {
    dispatch(TransferActions.transfersRequest({ per_page: 20, page, query: { supplier_id: supplier.id } }));
  });

  const handleNewAccountPress = () => {
    history.push("/crm/fornecedor/conta")
  };



  const handleAccountPagePress = useCallback((page) => {
    dispatch(TransferActions.accountsRequest({ per_page: 20, page, query: { supplier_id: supplier.id } }));
  });


  return (
    <Container>
      <h1>Fornecedor</h1>
      <div className="horizontal">
        <p>Detalhes do fornecedor</p>
      </div>
      <OuterBox>
        <ContainerBox>
          <DisplayInfos items={diplayValues.map(item => {
            item.value = supplier[item.key];
            return item;
          })} />
        </ContainerBox>
      </OuterBox>
      <div>
        <h1>Transferência</h1>
        <div className="horizontal">
          <p>Transferências feitas para o fornecedor</p>
        </div>
        {!transfers.length && <p>nenhuma transferência realizada</p>}
        {!!transfers.length && (
          <>
            <table>
              <thead>
                <tr className="t-header">
                  <th>ID</th>
                  <th>Total</th>
                  <th>Tipo</th>
                  <th>Documento</th>
                  <th>Status</th>
                  <th>Açoes</th>
                </tr>
              </thead>
              <tbody>
                {transfers.map((b) => (
                  <tr key={b.id}>
                    <td style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: "5px",
                          backgroundColor: theme.color[b.status] || "#fff",
                          height: "100%",
                        }}
                      ></div>
                      {b.id}
                    </td>
                    <td>{formatToReal(b.amount)}</td>
                    <td>{b.type}</td>
                    <td>{b.document}</td>
                    <td>{b.status}</td>
                    <td>
                      <i className="fas fa-exclamation-circle"></i>
                      <i className="fas fa-pen-square"></i>
                      <i className="fas fa-trash"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginator
              page={page}
              pages={pages}
              total={total}
              callback={handlePagePress}
            />
          </>
        )}
      </div>
      <div>
        <h1>Contas bancárias</h1>
        <div className="horizontal">
          <p>Lista de contas bancárias</p>
          <ButtonDefault
            type="button"
            title="Novo"
            background={theme.color.green}
            color={theme.color.white}
            width="250px"
            height="50px"
            onClick={handleNewAccountPress}
          />
        </div>
        {!accounts.data.length && <p>nenhuma conta cadastrada</p>}
        {!!accounts.data.length && (
          <>
            <table>
              <thead>
                <tr className="t-header">
                  <th>ID</th>
                  <th>Agência</th>
                  <th>Conta</th>
                  <th>Banco</th>
                  <th>Documento</th>
                  <th>Açoes</th>
                </tr>
              </thead>
              <tbody>
                {accounts.data.map((b) => (
                  <tr key={b.id}>
                    <td style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: "5px",
                          backgroundColor: theme.color[b.status] || "#fff",
                          height: "100%",
                        }}
                      ></div>
                      {b.id}
                    </td>
                    <td>{b.branch}</td>
                    <td>{b.account}</td>
                    <td>{b.bank_number} - {b.bank_name}</td>
                    <td>{b.document}</td>
                    <td>
                      {/* <i className="fas fa-exclamation-circle"></i>
                      <i className="fas fa-pen-square"></i>
                      <i className="fas fa-trash"></i> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginator
              page={page}
              pages={pages}
              total={total}
              callback={handleAccountPagePress}
            />
          </>
        )}
      </div>
    </Container>
  );
}
