import React, { useState, useEffect } from "react";
// import InputMask from "react-input-mask";
import { InputMask } from "../../../components/Inputs";
import { validate as ValidarCPF } from "gerador-validador-cpf";
import apiCustomer from "../../../services/apiCustomer";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Container,
  Page,
  ContainerInputs,
  HorizontalContainer,
} from "../styles";

import { HeaderBackImage } from "../../../components/Headers";
import { ButtonDefault } from "../../../components/Buttons";
import { registerUser } from "../../../store/modules/register/actions";

const initialValues = {
  name: "",
  email: "",
  document: "",
  password: "",
  password_confirmation: "",
};

const initialErrors = {
  name: "-",
  email: "-",
  document: "-",
  password: "-",
  password_confirmation: "-",
};

const UserSchema = Yup.object().shape({
  name: Yup.string().required("campo obrigatório"),
  email: Yup.string()
    .email("deve ser um email válido")
    .required("campo obrigatório"),
  document: Yup.string()
    .min(14, "minimo de 11 caracteres")
    .required("campo obrigatório")
    .test("is-cpf", "CPF está sendo usado", (value = "0") => {
      if (value && value.length >= 13) {
        let valueFormat = value.replace(/\D/g, "");
        return apiCustomer
          .get(`/check/user/document/${valueFormat}`)
          .then((result) => result.data)
          .then((data) => !data.exists);
      }
    })
    .test("is-valid", "Esse cpf é inválido", (value = "0") => {
      return ValidarCPF(value);
    }),
  password: Yup.string()
    .required("campo obrigatório")
    .min(8, "minimo de 8 caracteres"),
  password_confirmation: Yup.string()
    .required("campo obrigatório")
    .min(8, "minimo de 8 caracteres")
    .test("passwords-match", "As senhas devem corresponder", function (value) {
      return this.parent.password === value;
    }),
});

export default function SignIn() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);

  function submitForm(values) {
    const document = values.document.replace(/[\.^\-^\/]/gi, "");
    dispatch(
      registerUser({ person_type: "company", user: { ...values, document } })
    );
  }

  const handleToSet = (value) => {
    setCurrent(value);
  };

  const validInput = ({ input, props }) => {
    if (props.errors[input]) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Container>
      <section className="container-small">
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          initialErrors={initialErrors}
          initialTouched={initialValues}
          validationSchema={UserSchema}
        >
          {(props) => (
            <>
              {/* NOME */}
              {current === 0 && (
                <Page>
                  <HeaderBackImage onClick={() => history.goBack()} />
                  <ContainerInputs>
                    <label htmlFor="name">
                      <strong>Nome do Titular</strong> Administrador da conta
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>{props.touched.name && props.errors.name}</span>
                  </ContainerInputs>
                  {validInput({ input: "name", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(1)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(1)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => history.goBack()}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* EMAIL [1]*/}
              {current === 1 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(0)} />
                  <ContainerInputs>
                    <label htmlFor="email">
                      <strong>email do Titular</strong> Adminsitrador da conta
                    </label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>{props.touched.email && props.errors.email}</span>
                  </ContainerInputs>

                  {validInput({ input: "email", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  )}

                  <ButtonDefault
                    onClick={() => handleToSet(0)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* DOCUMENTO [2]*/}
              {current === 2 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(0)} />
                  <ContainerInputs>
                    <label htmlFor="document">
                      <strong>Documento do</strong> Adminsitrador da conta
                    </label>
                    <InputMask
                      id="document"
                      format="cpf"
                      type="text"
                      name="document"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.document}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {props.touched.document && props.errors.document}
                    </span>
                  </ContainerInputs>
                  {validInput({ input: "document", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* PASSWORD [3] */}
              {current === 3 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(0)} />
                  <ContainerInputs>
                    <label htmlFor="password">
                      <strong>Senha do Titular</strong> Adminsitrador da conta
                    </label>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {props.touched.password && props.errors.password}
                    </span>
                  </ContainerInputs>

                  {validInput({ input: "password", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(4)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(4)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(2)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* PASSWORD_CONFIRMATION [4] */}

              {current === 4 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(0)} />
                  <ContainerInputs>
                    <label htmlFor="password_confirmation">
                      <strong>Confirmação da senha </strong> Adminsitrador da
                      conta
                    </label>
                    <input
                      id="password_confirmation"
                      type="password"
                      name="password_confirmation"
                      autoCapitalize="none"
                      placeholder=""
                      value={props.values.password_confirmation}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span>
                      {props.touched.password_confirmation &&
                        props.errors.password_confirmation}
                    </span>
                  </ContainerInputs>

                  {validInput({ input: "password_confirmation", props }) ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(5)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(5)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(3)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* CHECK INFORMATION [5] */}

              {current === 5 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(0)} />
                  <ContainerInputs>
                    <label htmlFor="password_confirmation">
                      <strong>{props.values.name.split(" ")[0]}</strong>{" "}
                      verifique as informações e confirme
                    </label>
                    <br />

                    <label htmlFor="password_confirmation">
                      nome
                      <br />
                      <strong>{props.values.name}</strong>
                    </label>
                    <br />
                    <label htmlFor="password_confirmation">
                      email
                      <br />
                      <strong>{props.values.email}</strong>
                    </label>
                    <br />
                    <label htmlFor="password_confirmation">
                      documento
                      <br />
                      <strong>{props.values.document}</strong>
                    </label>
                    <br />
                  </ContainerInputs>

                  <ButtonDefault
                    type="submit"
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Próximo"
                    onClick={props.handleSubmit}
                  />
                  <ButtonDefault
                    onClick={() => handleToSet(3)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}
            </>
          )}
        </Formik>
      </section>
    </Container>
  );
}
