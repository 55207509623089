import React, {useEffect, useState, useMemo, useCallback} from 'react'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import {formatDate, formatHour, formatToReal} from "../../../../utils/format";
import {ButtonDefault} from "../../../Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons} from "../styles";
import {useDispatch, useSelector} from "react-redux";
import * as CryptoActions from "../../../../store/modules/crypto/actions";
import * as AccountActions from "../../../../store/modules/account/actions";
import {ContainerItens, Content, DateLabel, TransactionItem} from "./styles";
import ModalTransaction from "./modalTransaction";
import Pagination from "@material-ui/lab/Pagination";




const ModalTransactions = ({visible, onClose, currency, price}) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const {data, page, pages, per_page, total} = useSelector(
        (state) => state.crypto.ledgers,
    )
    const [orderItens, setOrderItens] = useState([])

    const [orderItem, setOrderItem] = useState()

    const [orderModal, setOrderModal] = useState(false)

    useEffect(() => {
        dispatch(AccountActions.balanceRequest())
        dispatch(AccountActions.GetAccount())
    }, [dispatch])
    useEffect(() => {
        if (data.length > 0) {
            let dataFormated = []
            data.forEach((item) => {
                let checktIndex = dataFormated.findIndex(
                    (el) => el.key == formatDate(item.createdAt),
                )
                if (checktIndex > -1) {
                    dataFormated[checktIndex].data.push(item)
                } else {
                    dataFormated.push({key: formatDate(item.createdAt), data: [item]})
                }
            })

            setOrderItens(dataFormated)
        }
    }, [data])

    useEffect(() => {
        dispatch(CryptoActions.GetLedgers({per_page: per_page, page: 1}))
    }, [dispatch])

    const handlePagePress = useCallback((event, value) => {
        dispatch(
            CryptoActions.GetLedgers({per_page: per_page, page: value}),
        )
    })

    const selectOrder = (item) => {
        setOrderItem(item)
        setOrderModal(true);
    }


    const handleCancel = () => {
        onClose()
    };


    return (<Modal show={visible} onHide={onClose} size="lg" centered dialogClassName={"modal-custom-transactions"}>
        <>
            <Modal.Header closeButton>
                <Modal.Title>Extrato {currency}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <ContainerItens>
                        <ModalTransaction visible={orderModal} onClose={() => setOrderModal(false)} order={orderItem}/>
                        <Container>
                            <Content>
                                <div className="table">
                                    {!orderItens.length && <p>Você não tem ordens pendentes</p>}
                                    {!!orderItens.length && (
                                        <>
                                            {orderItens.map((item, index) => (
                                                <>
                                                    <DateLabel key={index}>{item.key}</DateLabel>
                                                    {item.data.map((b, indexData) => (
                                                        <TransactionItem key={indexData}>
                                                            <div className="bar-container">
                                                                <div
                                                                    className="bar"
                                                                    style={{
                                                                        backgroundColor: theme.color[b.operation_direct] || '#fff',
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div className="info">
                                                                <span
                                                                    className="title">    {parseFloat(b.amount).toFixed(2)} {b.currency}</span>
                                                                <span
                                                                    className="subtitle">
                                                                    {b.operation_type == "buy" && "Compra"}
                                                                    {b.operation_type == "sell" && "Venda"}
                                                                    {b.operation_type == "withdraw" && "Saque"}
                                                                    {b.operation_type == "deposit" && "Depósito"}
                                                                </span>
                                                            </div>
                                                            <div className={`price`}>
                                                                {' '}
                                                                <span className={`amount ${b.operation_direct}`}>
                                                                {formatToReal(parseFloat(b.total_amount))}
                                                              </span>
                                                                <span className="clock">
                                                                {' '}
                                                                    {formatHour(b.updatedAt)}{' '}
                                                              </span>
                                                            </div>
                                                            <div
                                                                className="action"
                                                                onClick={() => {
                                                                    selectOrder(b)
                                                                }}
                                                            >
                                                                <i className="material-icons icon">navigate_next</i>
                                                            </div>
                                                        </TransactionItem>
                                                    ))}
                                                </>
                                            ))}
                                            <Pagination
                                                count={pages}
                                                variant="outlined"
                                                shape="rounded"
                                                page={page}
                                                onChange={handlePagePress}
                                            />
                                        </>
                                    )}
                                </div>
                            </Content>
                        </Container>
                    </ContainerItens>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <ContainerButtons>
                    <ButtonDefault
                        title="Fechar"
                        background={theme.color.white}
                        color={theme.color.blackButton}
                        width="170px;"
                        onClick={handleCancel}
                    />

                </ContainerButtons>
            </Modal.Footer>
        </>
    </Modal>)
}

export default ModalTransactions
