import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  min-height: 320px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  max-width: 410px;
  input {
  }
  .horizontal {
    flex: 1;
    margin-bottom: 0px !important;
    align-items: flex-end !important;
    justify-content: flex-end;
  }
  .field {
    flex-direction: row;
    .input-ref {
      flex-direction: column;
      flex: 1;
      label {
        padding: 10px;
      }
    }
    .button {
      justify-content: center;
      align-items: center;
      label {
        padding: 10px;
        height: 36px;
      }
      &:hover {
        cursor: pointer;
      }
      .material-icons {
        font-size: 16px;
        padding: 2px;
      }
    }
  }
`;
