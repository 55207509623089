import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as RegisterActions from "../../../store/modules/register/actions";
import { useDispatch } from "react-redux";
import { Container, Wave, Card } from "./styles";
import ReactCodeInput from "react-code-input";

export default function Confirmation() {
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmitCode = (code) => {
    console.log(code);
    dispatch(
      RegisterActions.checkCode({ code, callback: () => setSuccess(true) })
    );
  };

  const handleNewCodeRequest = () => {
    dispatch(RegisterActions.sendNewCode());
  };
  return (
    <Container>
      <Card>
        <h1 onClick={() => history.push("/")}>Voltar</h1>
        {!success ? (
          <div>
            <ReactCodeInput
              disabled={false}
              type="text"
              fields={6}
              pattern={/[0-9]/}
              onChange={(v) => {
                if (v.length === 6) {
                  handleSubmitCode(v);
                }
              }}
            />

            <p>Digite o código de segurança que você recebeu no email</p>
            <p
              onClick={handleNewCodeRequest}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              reenviar código
            </p>
          </div>
        ) : (
          <div>
            <div className="check-icon">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <p>Código confirmado</p>
          </div>
        )}
      </Card>
      <Wave>
        <div className="block"></div>
      </Wave>
    </Container>
  );
}
