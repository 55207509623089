import { all, takeLatest, put, call, select } from "redux-saga/effects";
import history from "../../../services/history";
import apiPayment from "../../../services/apiPayment";
import GetErrors from "../../../services/errors";
import * as PixActions from "./actions";
import * as GeneralActions from "../general/actions";
import moment from "moment";

export function* listRequest(action) {
  try {
    const { per_page, page, query } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));

    const { data } = yield call(apiPayment.post, "/payment/partner/v1/web/pixes", {
      per_page,
      page,
      query,
    });
    yield put(PixActions.listRequestSuccess(data));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* NewKey(action) {
  try {
    const { callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    yield call(apiPayment.post, "/payment/partner/v1/web/pix", action.payload);

    yield put(PixActions.listRequest({ page: 1, per_page: 3 }));
    if (callback) {
      callback();
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* GetExternalKey(action) {
  try {
    const { callback, key } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiPayment.get, `/payment/partner/v1/web/pix/key/${key}`);

    yield put(PixActions.GetExternalKeySuccess(data));
    if (callback) {
      callback(data);
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeLatest("@pix/LIST_REQUEST", listRequest),
  takeLatest("@pix/NEW_KEY", NewKey),
  takeLatest("@pix/GET_EXTERNAL_KEY", GetExternalKey),
]);
