import styled from "styled-components";

export const Container = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.div`
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: ${({ theme, current }) =>
    current ? theme.color.blue : theme.color.blackText};
  margin: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  :hover {
    z-index: 100;
    -webkit-box-shadow: 5px 5px 20px -8px #000000;
    box-shadow: 5px 5px 20px -8px #000000;
  }
`;
