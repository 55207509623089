export function transactionsRequest({ per_page, page, query, order_by }) {
  return { type: "@transaction/TRANSACTIONS_REQUEST", payload: { per_page, page,query,order_by } };
}

export function transactionsRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@transaction/TRANSACTIONS_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}
