import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "../../../src/store/modules/account/actions";
import { TablePendingChanges } from "../../components/Table";
import { Container, ContainerBoxes } from "./styles";

import Transaction from "../Finance/Transaction"

import {
    CardHomeEntries,
    CardHomeCategories,
    HomeAccount,
    CardHomePix,
    CarsHomeMenus,
} from "../../components/Cards";

export default function Home() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AccountActions.ListLimits());
        dispatch(AccountActions.GetAccount());
        // dispatch(AccountActions.resumeRequest());
        // dispatch(AccountActions.resumeInoutRequest());
        // dispatch(AccountActions.resumeMonthsRequest());
    }, []);

    return (
        <Container>
            <h1>Seus dados Pix</h1>
            <p className="description">Resumo da sua conta como saldo e outros.</p>
            <ContainerBoxes>
                <CardHomeEntries />
                <CardHomePix />
            </ContainerBoxes>
            <p></p>
            <h1>Sua conta</h1>
            <p></p>
            <ContainerBoxes>
                <HomeAccount />
                <CarsHomeMenus/>
            </ContainerBoxes>
            <p></p>
            {/*<h1>Resumo da conta</h1>*/}
            {/*<p  className="description">Resumo da sua conta como saldo e outros.</p>*/}
            <ContainerBoxes>
                <Transaction/>

                {/*<CardHomeCategories />*/}
                {/* <TablePendingChanges /> */}
            </ContainerBoxes>
        </Container>
    );
}
