import React from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { SupplierCreate } from "../../../components/Cards";
import { Container } from "./styles";

export default function Billing() {
  const history = useHistory();

  return (
    <Container>
      <h1>Cadastro de Fornecedor</h1>
      <p>Cadastrar novo fornecedor</p>
      <div className="horizontal"></div>
      <SupplierCreate />
    </Container>
  );
}
