import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';

import {ContainerPage} from "../../../../components/Cards/ClientCreate/styles";
import {ButtonDefault} from "../../../../components/Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons, Container, ContainerDiv} from "./styles";
import { useSelector} from "react-redux";

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from '@react-pdf/renderer'

import {formatCnpj, formatDate, formatDateHour,formatToReal} from "../../../../utils/format";
import logoIcon from "../../../../assets/images/packman-white.png";
import LogoPaggo from '../../../../assets/images/logo-black.png'


const DepositModal = ({visible, onClose}) => {
  const theme = useTheme();

  const account = useSelector((state) => state.account.account)

  const { deposit } = useSelector((state) => state.deposit)

  const DocumentDeposit = ({ deposit, account }) => {
    return (
        <Document>
          <Page
              size="A4"
              style={{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 }}
          >
            <View>
            <Image
                            style={{
                                height: 50,
                                width: 200,
                            }}
                            src={LogoPaggo}
                        />
            </View>
            <Text style={{ fontSize: 24, textAlign: 'left', marginTop: 12 }}>
              Comprovante de Transferência
            </Text>
            <Text style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}>
              {' '}
              Valor:{' '}
            </Text>
            <Text style={{ marginTop: 4, fontSize: 10 }}>
              {' '}
              {formatToReal(parseFloat(deposit.amount))}{' '}
            </Text>
            <View>
              <View
                  style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
              >
                <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                  {' '}
                  Conta Origem:{' '}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    De:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {deposit.sender_name}{' '}
                  </Text>
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Documento:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {deposit.sender_document}{' '}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Instituição:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {deposit.bank_name}{' '}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View
                  style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
              >
                <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                  {' '}
                  Conta Destino:{' '}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    Para:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {account.name}{' '}
                  </Text>
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Documento:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {formatCnpj(account.document)}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Instituição:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {account.bank_name}{' '}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View
                  style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
              >
                <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                  {' '}
                  Informações:{' '}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    Tipo:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {deposit.type}{' '}
                  </Text>
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Data:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {formatDateHour(deposit.updatedAt)}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    ID da transação:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {deposit.integration_id}{' '}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
    )
  }


  const handleCancel = () => {
    onClose()
  };


  return (<Modal show={visible} onHide={onClose} size="md" centered>  

    <Modal.Body>
            <Container>
                <ContainerDiv>
                    <div className='header-modal'>
                <img
                            // style={{
                            //     height: 50,
                            //     width: 200,
                            // }}
                            src={LogoPaggo}
                        />
                        </div>
                        </ContainerDiv>

                <ContainerDiv>
                    <div className='subcontainer-modal'>
                        <p className='title'>PAGADOR</p>
                        <p className='subtitle'>{deposit.sender_name}</p>
                        <p className='document'>{deposit.sender_document}</p>

                        <div className='info'>
                        <p className='title-2'>Banco</p>
                        <p className='subtitle-2'>{deposit.bank_name} - {deposit.brank_number} </p>
                    </div>

                    <div className='info'>
                        <p className='title-2'>Agência</p>
                        <p className='subtitle-2'>{deposit.sender_branch}</p>
                    </div>

                    <div className='info'>
                        <p className='title-2'>Conta</p>
                        <p className='subtitle-2'>{deposit.sender_number}</p>
                    </div>

                    </div>
                </ContainerDiv>

                <ContainerDiv>
                    <div className='subcontainer-modal'>
                    <p className='title'>BENEFICIÁRIO</p>
                    <p className='subtitle'>{account.name}</p>
                    <p className='value'>{formatToReal(parseFloat(deposit.amount))}</p>

                      <div className='info'>
                          <p className='title-2'>Banco</p>
                          <p className='subtitle-2'> {account.bank_name} - {account.brank_number}</p>
                      </div>
  
                      <div className='info'>
                          <p className='title-2'>Agência</p>
                          <p className='subtitle-2'>{account.branch}</p>
                      </div>
  
                      <div className='info'>
                          <p className='title-2'>Conta</p>
                          <p className='subtitle-2'>{account.number}</p>
                      </div>
                    </div>
                </ContainerDiv>
                <div>
                    <p className='autentic-p'>Autenticação</p>
                    <span className='autentic-span '>{deposit.integration_id}</span>
                </div>
            </Container>
        </Modal.Body>
    <Modal.Footer>
      <ContainerButtons>
        <ButtonDefault
            title="Fechar"
            background={theme.color.white}
            color={theme.color.blackButton}
            width="170px;"
            onClick={handleCancel}
        />
        {deposit.id > 0 && (
            <PDFDownloadLink
                document={<DocumentDeposit deposit={deposit} account={account} />}
                fileName={`deposit_${deposit.integration_id}.pdf`}
                className="button-pdf"
            >
              {({ blob, url, loading, error }) =>
                  loading ? (
                      'Loading document...'
                  ) : (
                      <>Baixar</>
                  )
              }
            </PDFDownloadLink>
        )}
      </ContainerButtons>
    </Modal.Footer>
  </Modal>)
}

export default DepositModal
