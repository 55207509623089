export function apikeysRequest({ per_page, page }) {
  return { type: "@apikey/APIKEY_REQUEST", payload: { per_page, page } };
}

export function apikeysRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@apikey/APIKEY_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}

export function newApikeyRequest({ grant_type, callback }) {
  return {
    type: "@apikey/NEW_APIKEY_REQUEST",
    payload: { grant_type, callback },
  };
}

export function deleteApikeyRequest({ id, callback }) {
  return {
    type: "@apikey/DELETE_APIKEY_REQUEST",
    payload: { id, callback },
  };
}

export function newApikeySuccess({ client_id, client_secret }) {
  return {
    type: "@apikey/NEW_APIKEY_SUCESS",
    payload: { client_id, client_secret },
  };
}
