import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as TransferActions from "../../../store/modules/transfer/actions";
import * as SupplierActions from "../../../store/modules/supplier/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {Typeahead} from 'react-bootstrap-typeahead';
import { InputMask } from "../../Inputs";
import { cpfIsValid } from "../../../utils/check";

const DepositSchema = Yup.object().shape({
  account: Yup.string().required("campo obrigatório"),
  branch: Yup.string().required("campo obrigatório"),
  bank_number: Yup.string().required("campo obrigatório"),
  name: Yup.string().required("campo obrigatório"),
  // document: Yup.string()
  //   .min(14, "minimo de 11 caracteres")
  //   .required("campo obrigatório")
  //   // .test("is-valid-cpf", "CPF inválido", (value = "0") => {
  //   //   if (value && value.length >= 13) {
  //   //     let valueFormat = value.replace(/\D/g, "");
  //   //     return cpfIsValid(valueFormat);
  //   //   }
  //   // }),
});

const initialValues = {
  account: "",
  branch: "",
  bank_number: "",
  document: "",
  name: "",
  supplier_id:0,
};

const TransferCreate = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);

  const { bank } = useSelector(
    (state) => state.transfer
  );

  const { data } = useSelector(
    (state) => state.supplier
  );

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(TransferActions.banksRequest({ per_page: 500, page: 1 }));
    dispatch(SupplierActions.SuppliersRequest({ per_page: 500, page: 1 }));
  }, [dispatch]);

  const handleSubmitForm = (values) => {
    const document = values.document.replace(/[\.^\-^\/]/gi, "");
    console.log(document);
    dispatch(
      TransferActions.newAccount({
        ...values,
        document,
        account: values.account,
        branch: values.branch,
        callback: () => setSuccess(true),
      })
    );
  };

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <p>Conta criada com sucesso</p>
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={DepositSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <>
          <div className="field">
              <Typeahead
                id="supplier"
                labelKey={(options) => `${options.name} - ${options.document}`}
                options={data}
                filterBy={['name', 'document']}
                ignoreDiacritics={false}
                emptyLabel="Não existe"
                onChange={(selected) => {
                  props.setFieldValue("supplier_id",selected[0].id)
                  props.setFieldValue("name",selected[0].name)
                  props.setFieldValue("document",selected[0].document)

                }}
                multiple={false}
              />
              <span>
                {props.touched.supplier_id && props.errors.supplier_id}
              </span>
            </div>
            <div className="field">
            <Typeahead
                id="bank"
                labelKey={(options) => `${options.bank_number} - ${options.bank_name}`}
                options={bank.data}
                filterBy={['bank_number', 'bank_name']}
                ignoreDiacritics={false}
                emptyLabel="Não existe"
                onChange={(selected) => {
                  props.setFieldValue("bank_number",selected[0].bank_number)
                }}
                multiple={false}
              />
              <span>
                {props.touched.bank_number && props.errors.bank_number}
              </span>
            </div>
            <div className="horizontal">
              <div className="field">
                <input
                  type="text"
                  name="branch"
                  placeholder="Agência"
                  value={props.values.branch}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                ></input>
                <span>{props.touched.branch && props.errors.branch}</span>
              </div>
              <div className="fieldNoMargin">
                <input
                  type="text"
                  name="account"
                  placeholder="Conta"
                  value={props.values.account}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                ></input>
                <span>{props.touched.account && props.errors.account}</span>
              </div>
            </div>
            <div className="field">
              <input
                type="text"
                name="name"
                placeholder="Nome"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              ></input>
              <span>{props.touched.name && props.errors.name}</span>
            </div>
            <div className="field">
              <InputMask
                format="cpf"
                type="text"
                name="document"
                placeholder="Documento do titular"
                value={props.values.document}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              ></InputMask>
              <span>{props.touched.document && props.errors.document}</span>
            </div>
            <div className="container-buttons">
              <div className="horizontal">
                <ButtonDefault
                  title="Salvar"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={props.handleSubmit}
                />
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default TransferCreate;
