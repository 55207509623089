import styled from "styled-components";

import colors from "../../../styles/colors";

export const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  
`;


export const Content = styled.div`
   flex: 14;
    .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${colors.grayText};
  }
  p {
    font-weight: 100;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  
`;
export const Item = styled.div`
  width:184px;
  text-align: center;
  border-radius: 18px;
  background: white;
  padding: 20px 0 57px 0;



  .title{
    font-family: "Roboto-Bold", sans-serif;
    color:#747474;
    font-size:15px;
    margin-bottom: 20px;
  }
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  .icon{
    i{
      font-size:70px;
      color:#747474;
    }
  }
  &:hover{
    cursor: pointer;
    opacity:1;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  }
  opacity:0.7;
  margin:16px;
`;





export const ContainerItem = styled.div`
  margin: 29px 0;
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 536px) {
    justify-content: center !important;
  }
`;
