import React from "react";

import { Container, Icon, Image } from "./styles";
import logo from "../../../assets/images/logo-black@2x.png";
export default function BackImage({ onClick }) {
  return (
    <Container onClick={onClick}>
      <Icon>
        <i className="material-icons md-36">keyboard_arrow_left</i>
      </Icon>
      <Image src={logo} alt="logomarca do banco genesis" />
    </Container>
  );
}
