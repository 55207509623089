import produce from 'immer'

const INITIAL_STATE = {
  data: [],
  page: 1,
  pages: 1,
  total: 0,
  company: {
    balance:"",
  },
  per_page: 9,
  transactions:{
    data: [],
    page: 1,
    pages: 1,
    total: 0,
    per_page: 10,
  }
}

export default function MyClient(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@company/LIST_REQUEST_SUCCESS':
      return produce(state, (draft) => {
        draft.data = action.payload.data
        draft.page = action.payload.page
        draft.pages = action.payload.pages
        draft.total = action.payload.total
      })
    case '@company/DEFINE_DETAIL':
      return produce(state, (draft) => {
        draft.company = action.payload.company
      })
    case '@company/LIST_TRASACTIONS_SUCCESS':
      return produce(state, (draft) => {
        draft.transactions.data = action.payload.data
        draft.transactions.page = action.payload.page
        draft.transactions.pages = action.payload.pages
        draft.transactions.total = action.payload.total
      })
    default:
      return state
  }
}
