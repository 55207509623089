import React from "react";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";

import ReactInputDateMask from "react-input-date-mask";

let currencyBRL = {
  prefix: "R$ ",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: null,
};

let currencyBRLdecimal = {
  prefix: "R$ ",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 4,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: null,
};

let currencyCrypto = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: "",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: null,
};

let currencyBRLDolar = {
  prefix: "R$ ",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 4,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: null,
};



export default function Mask(props) {
  const { format } = props;
  if (format === "money") {
    const mask = createNumberMask({ ...currencyBRL });
    return <MaskedInput {...props} mask={mask} />;
  }

  if (format === "decimal") {
    const mask = createNumberMask({ ...currencyBRLdecimal });
    return <MaskedInput {...props} mask={mask} />;
  }

  if (format === "dolar") {
    const mask = createNumberMask({ ...currencyBRLDolar });
    return <MaskedInput {...props} mask={mask} />;
  }

  if (format === "crypto") {
    const mask = createNumberMask({ ...currencyCrypto });
    return <MaskedInput {...props} mask={mask} />;
  }
  if (format === "cpf") {
    return <MaskedInput {...props} mask={cpf} />;
  }
  if (format === "cnpj") {
    return <MaskedInput {...props} mask={cnpj} />;
  }
  if (format === "cep") {
    return <MaskedInput {...props} mask={cep} />;
  }
  if (format === "phone") {
    return <MaskedInput {...props} mask={phone} />;
  }
  if (format === "date") {
    return <MaskedInput {...props} mask={date} />;
  }
}


const date = [/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

const phone = [
  "(",
  /[1-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const cpf = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  "-",
  /[0-9]/,
  /[0-9]/,
];

const cnpj = [
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  "/",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  "-",
  /[0-9]/,
  /[0-9]/,
];

const cep = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  "-",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
