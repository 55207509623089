import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../../components/Buttons";
import { Container } from "./styles";
import { formatToReal } from "../../../utils/format";
import * as SupplierActions from "../../../store/modules/supplier/actions";
import Paginator from "../../../components/Paginator";

export default function Billing() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, page, pages, total } = useSelector(
    (state) => state.supplier
  );


  console.log({ data, page, pages, total })
  useEffect(() => {
    dispatch(SupplierActions.SuppliersRequest({ per_page: 3, page: 1 }));
  }, [dispatch]);

  const handlePagePress = useCallback((page) => {
    dispatch(SupplierActions.SuppliersRequest({ per_page: 3, page }));
  });

  const handleDetail = (supplier) => {
    dispatch(SupplierActions.MySupplierDetail({ supplier }));
    history.push(`/crm/fornecedor/detalhe/${supplier.id}`)
  }


  const handleNewPress = () => {
    history.push("/crm/fornecedor/new")
  };

  return (
    <Container>
      <h1>Fornecedores</h1>
      <div className="horizontal">
        <p>Lista de fornecedores</p>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      {!data.length && <p>nenhum fornecedor cadastrado</p>}
      {!!data.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Nome</th>
                <th>Documento</th>
                <th>Email</th>
                <th></th>
                <th>Açoes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((b) => (
                <tr key={b.id}>
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        backgroundColor: theme.color[b.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {b.id}
                  </td>
                  <td>{b.name}</td>
                  <td>{b.document}</td>
                  <td>{b.email}</td>
                  <td></td>
                  <td>
                     <i onClick={()=>handleDetail(b)} className="fas fa-exclamation-circle"></i>
                    <i className="fas fa-pen-square"></i>
                    <i className="fas fa-trash"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            page={page}
            pages={pages}
            total={total}
            callback={handlePagePress}
          />
        </>
      )}
    </Container>
  );
}
