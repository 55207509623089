import { all, takeLatest, put, call, select } from "redux-saga/effects";
import history from "../../../services/history";
import apiCustomer from "../../../services/apiCostumer";
import GetErrors from "../../../services/errors";
import * as ApikeyActions from "./actions";
import * as GeneralActions from "../general/actions";

export function* apikeyRequest(action) {
  try {
    const { per_page, page } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiCustomer.post, "/web/apikeys", {
      per_page,
      page,
    });
    yield put(ApikeyActions.apikeysRequestSuccess(data));
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* NewApikeyRequest(action) {
  try {
    const { grant_type, callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiCustomer.post, "/web/apikey", {
      grant_type,
    });
    yield put(ApikeyActions.newApikeySuccess(data));
    if (callback) {
      callback();
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export function* DeleteApikeyRequest(action) {
  try {
    const { id, callback } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiCustomer.delete, "/web/apikey/" + id);
    yield put(ApikeyActions.apikeysRequest({ page: 1, per_page: 5 }));
    if (callback) {
      callback();
    }
  } catch (error) {
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeLatest("@apikey/APIKEY_REQUEST", apikeyRequest),
  takeLatest("@apikey/NEW_APIKEY_REQUEST", NewApikeyRequest),
  takeLatest("@apikey/DELETE_APIKEY_REQUEST", DeleteApikeyRequest),
]);
