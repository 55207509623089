import React, { useState, useEffect, CSSProperties } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  ContainerPage,
  ContainerPagination,
  PaymentBillingItem,
  ContainerPayments,
} from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Carousel from "react-elastic-carousel";
import apiCep from "../../../services/apiCEP.js";
import * as GeneralActions from "../../../store/modules/general/actions";
import PyamentIcon from "../../PyamentIcon";
import * as BillingActions from "../../../store/modules/billing/actions";
import * as MyClientActions from "../../../store/modules/myclient/actions";
import * as AccountActions from "../../../store/modules/account/actions";
import DisplayInfos from "../../DisplayInfo";
import { formatToReal } from "../../../utils/format";
import { Typeahead } from "react-bootstrap-typeahead";
import { setOnClipboard } from "../../../utils/clipboard";
import Limit from "../../Limit";

const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success
) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page;
        return (
          <li
            className={`dot ${isActivePage ? "selected" : ""} ${
              success ? "success" : ""
            }`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props);
            }}
          />
        );
      })}
    </ContainerPagination>
  );
};

const BillingSchema = Yup.object().shape({
  installment_total: Yup.string().required("campo obrigatório"),
  amount: Yup.string().required("campo obrigatório"),
  my_client_id: Yup.number().required("campo obrigatório"),
  first_due_date: Yup.string()
    .required("campo obrigatório")
    .test("is_before_yesterday", "deve inserir uma data válida", (value) => {
      const yesterday = moment().subtract(1, "day");
      const selectedDate = moment(value, "YYYY-MM-DD").format();
      return moment(selectedDate).isAfter(yesterday);
    }),
  title: Yup.string().required("campo obrigatório"),
  instructions: Yup.string().required("campo obrigatório"),
  payment_types: Yup.array().test(
    "one_more",
    "algum valor deve ser escolhido",
    (values) => values.length > 0
  ),
});
const initialValues = {
  installment_total: "",
  amount: "",
  my_client_id: "",
  first_due_date: "",
  formated_first_due_date: "",
  formated_last_due_date: "",
  title: "",
  instructions: "",
  payment_types: [],
  total: 0,
  client_name: "",
  client_email: "",
  client_document: "",
};

const initialErrors = {
  installment_total: "-",
  amount: "-",
  my_client_id: "-",
  first_due_date: "-",
  title: "-",
  instructions: "-",
  payment_types: "-",
};

const diplayValues = [
  {
    key: "client_name",
    name: "Nome o do Cliente",
    value: "",
    position: "row",
    type: "string",
  },
  {
    key: "client_email",
    name: "Email do cliente",
    value: "",
    position: "row",
    type: "string",
  },
  {
    key: "client_document",
    name: "Documento do cliente",
    value: "",
    position: "row",
    type: "string",
  },
  {
    key: "payment_types",
    name: "Tipo de pagamentos aceito",
    value: [],
    position: "row",
    type: "render",
    render: (items) => (
      <div className="container-pay">
        {items.map((item, index) => (
          <PyamentIcon
            key={index}
            className="display-pay"
            type={item}
            size={40}
            textSize={12}
          />
        ))}
      </div>
    ),
  },
  {
    key: "installment_total",
    name: "Total de parcelas",
    value: "",
    position: "column",
    type: "string",
  },
  {
    key: "amount",
    name: "Valor das parcelas",
    value: "",
    position: "column",
    type: "money",
  },
  {
    key: "formated_first_due_date",
    name: "Inicio da cobrança",
    value: "",
    position: "column",
    type: "string",
  },
  {
    key: "formated_last_due_date",
    name: "Final da cobrança",
    value: "",
    position: "column",
    type: "string",
  },
  {
    key: "total",
    name: "Total",
    value: "",
    position: "row",
    type: "money",
  },
];

const BillingCreate = ({ navigation }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [position, setPosition] = useState(0);

  const { limits } = useSelector((state) => state.account);

  var carousel = null;

  const [success, setSuccess] = useState(false);
  const [types, setType] = useState(["boleto", "pix"]);

  const { billingsCreated } = useSelector((state) => state.billing);

  const myclientState = useSelector((state) => state.myclient);

  useEffect(() => {
    dispatch(MyClientActions.MyClientsRequest({ per_page: 100, page: 1 }));
    dispatch(AccountActions.ListLimits());
  }, [dispatch]);

  const handleCepPress = (props) => {
    const { zip_code } = props.values;
    let cep = zip_code.replace(/\D/g, "");
    if (cep.length < 8) {
      dispatch(GeneralActions.setError({ error: "Cep inválido" }));
      return;
    }
    dispatch(GeneralActions.setLoading({ loading: true }));
    apiCep
      .getAddress(cep)
      .then((result) => result.data)
      .then((result) => {
        props.setFieldValue("address", result.logradouro);
        props.setFieldTouched("address", true);
        props.setFieldValue("neighborhood", result.bairro);
        props.setFieldTouched("neighborhood", true);
        props.setFieldValue("city", result.localidade);
        props.setFieldTouched("city", true);
        props.setFieldValue("state", result.uf);
        props.setFieldTouched("state", true);
        props.setFieldTouched("complement", true);
        carousel.goTo(position + 1);
        setPosition(position + 1);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(GeneralActions.setError("CEP inválido, tente outro"));
        }
      })
      .finally(() => {
        dispatch(GeneralActions.setLoading({ loading: false }));
      });
  };

  var listRef = null;

  const handleCancel = () => {
    history.push("/financeiro/cobranca");
  };

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1);
    setPosition(position - 1);
  };
  const handleNextPage = (props) => {
    switch (position) {
      case 0:
        const {
          installment_total,
          amount,
          my_client_id,
          first_due_date,
          title,
          instructions,
          payment_types,
        } = props.values;
        props.validateForm().then((validation) => props.setTouched(validation));
        BillingSchema.isValid({
          installment_total,
          amount,
          my_client_id,
          first_due_date,
          title,
          instructions,
          payment_types,
        }).then((valid) => {
          if (valid) {
            props.setFieldValue("total", amount * installment_total);
            props.setFieldValue(
              "formated_first_due_date",
              moment(first_due_date).format("DD/MM/YYYY")
            );
            props.setFieldValue(
              "formated_last_due_date",
              moment().add(installment_total, "day").format("DD/MM/YYYY")
            );
            carousel.goTo(position + 1);
            setPosition(position + 1);
            return;
          }
          dispatch(GeneralActions.setError({ error: "Dados inválidos" }));
        });
        break;
      case 1:
        handleSubmitForm(props.values);
        break;
    }
  };

  const checkItem = (props, item) => {
    let exists = props.values.payment_types.find(
      (itemType) => itemType == item
    );
    var items = props.values.payment_types;
    if (!!exists) {
      items = props.values.payment_types.filter((itemType) => itemType != item);
    } else {
      items.push(item);
    }
    props.setFieldValue("payment_types", items);
  };

  const handleSubmitForm = (values) => {
    dispatch(
      BillingActions.newBilling({
        ...values,
        callback: () => {
          setSuccess(true);
        },
      })
    );
  };
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={BillingSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            {success ? (
              <div className="container-success">
                <div className="total">
                  Total: {formatToReal(props.values.total)}
                </div>
                <div className="container-pay">
                  {props.values.payment_types.map((item, index) => (
                    <PyamentIcon
                      key={index}
                      className="display-pay"
                      type={item}
                      size={40}
                      textSize={12}
                    />
                  ))}
                </div>
                {!billingsCreated.length && <p>nenhuma cobrança pendente</p>}
                {!!billingsCreated.length && (
                  <table>
                    <thead>
                      <tr className="t-header">
                        <th>Parcela</th>
                        <th>Total</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billingsCreated.map((b) => (
                        <tr key={b.id}>
                          <td>{`${b.installment} / ${b.installment_total}`}</td>
                          <td>{formatToReal(b.total)}</td>
                          <td
                            className="share"
                            onClick={() => {
                              dispatch(
                                GeneralActions.setMessage({
                                  message: "link copiado",
                                })
                              );
                              setOnClipboard(b.link_ref);
                            }}
                          >
                            Compartilhar
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <div className="container-buttons">
                  <ButtonDefault
                    title="Voltar"
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    width="170px;"
                    onClick={handleCancel}
                  />
                </div>
              </div>
            ) : (
              <>
                <Carousel
                  showArrows={false}
                  ref={(ref) => (carousel = ref)}
                  renderPagination={(renderPropos) => {
                    return renderPagination(
                      renderPropos,
                      props,
                      handlePrevPage,
                      success
                    );
                  }}
                >
                  <ContainerPage>
                    <div className="field">
                      <Typeahead
                        id={"client"}
                        placeholder="Cliente"
                        labelKey={(options) =>
                          `${options.name.substring(0, 26)}... - ${
                            options.document
                          }`
                        }
                        options={myclientState.data}
                        filterBy={["document", "name"]}
                        ignoreDiacritics={false}
                        emptyLabel="Não existe"
                        onChange={(selected) => {
                          if (selected) {
                            props.setFieldValue("my_client_id", selected[0].id);
                            props.setFieldValue(
                              "client_name",
                              selected[0].name
                            );
                            props.setFieldValue(
                              "client_email",
                              selected[0].email
                            );
                            props.setFieldValue(
                              "client_document",
                              selected[0].document
                            );
                          }
                        }}
                        multiple={false}
                      />
                      <span>
                        {props.touched.my_client_id &&
                          props.errors.my_client_id}
                      </span>
                    </div>
                    <div className="field">
                      <input
                        type="text"
                        name="title"
                        placeholder="titulo da cobrança"
                        value={props.values.title}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      ></input>
                      <span>{props.touched.title && props.errors.title}</span>
                    </div>
                    <div className="field">
                      <input
                        type="text"
                        name="instructions"
                        placeholder="Descrição"
                        value={props.values.instructions}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      ></input>
                      <span>
                        {props.touched.instructions &&
                          props.errors.instructions}
                      </span>
                    </div>
                    <div className="horizontal">
                      <div className="field">
                        <input
                          type="number"
                          name="installment_total"
                          placeholder="Pacelas"
                          value={props.values.installment_total}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        ></input>
                        <span>
                          {props.touched.installment_total &&
                            props.errors.installment_total}
                        </span>
                      </div>
                      <div className="field">
                        <input
                          type="number"
                          name="amount"
                          placeholder="Valor da parcela"
                          value={props.values.amount}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        ></input>
                        <span>
                          {props.touched.amount && props.errors.amount}
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <input
                        type="text"
                        name="amount"
                        placeholder="Total"
                        value={formatToReal(
                          props.values.amount * props.values.installment_total
                        )}
                        disabled
                      ></input>
                    </div>
                    <div className="field">
                      <input
                        type="date"
                        min={moment().format("DD/MM/YYYY")}
                        name="first_due_date"
                        placeholder="valor da prestação"
                        value={props.values.first_due_date}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      ></input>
                      <span>
                        {props.touched.first_due_date &&
                          props.errors.first_due_date}
                      </span>
                    </div>
                    <div className="field">
                      {limits.length > 0 && (
                        <Limit
                          volume={
                            limits.find((item) => item.name == "billing").volume
                          }
                          balance={
                            limits.find((item) => item.name == "billing")
                              .balance
                          }
                          amount={formatToReal(
                            props.values.amount * props.values.installment_total
                          )}
                          percent={
                            limits.find((item) => item.name == "billing")
                              .percent
                          }
                        />
                      )}
                    </div>
                    <div className="field">
                      <ContainerPayments>
                        {types.map((item, index) => (
                          <PaymentBillingItem
                            className={`${
                              props.values.payment_types.find(
                                (itemType) => itemType == item
                              )
                                ? "active"
                                : ""
                            }`}
                            onClick={() => checkItem(props, item)}
                          >
                            <PyamentIcon type={item} size={40} textSize={12} />
                          </PaymentBillingItem>
                        ))}
                      </ContainerPayments>
                      <span>
                        {props.touched.payment_types &&
                          props.errors.payment_types}
                      </span>
                    </div>
                  </ContainerPage>
                  <ContainerPage>
                    {position == 1 && (
                      <DisplayInfos
                        items={diplayValues.map((item) => {
                          item.value = props.values[item.key];
                          return item;
                        })}
                      />
                    )}
                  </ContainerPage>
                </Carousel>
                <div className="container-buttons">
                  <div className="horizontal">
                    <ButtonDefault
                      title="Cancelar"
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      width="170px;"
                      onClick={handleCancel}
                    />
                    <ButtonDefault
                      title="Próximo"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="170px;"
                      onClick={() => handleNextPage(props, handleSubmitForm)}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Formik>
    </Container>
  );
};

export default BillingCreate;
