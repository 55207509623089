import React from "react";

import { Container } from "./styles";

import PropTypes from "prop-types";

function Button({
  color,
  background,
  disabled,
  title,
  onClick,
  width,
  height,
  type
}) {
  return (
    <Container
      type={type}
      onClick={onClick}
      disabled={disabled}
      color={color}
      background={background}
      width={width}
      height={height}
    >
      {title}
    </Container>
  );
}

Button.propTypes = {
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Button;
