import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
} from './styles'
import {
  formatDate,

} from '../../../utils/format'
import * as CompanyActions from '../../../store/modules/company/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import '../../../assets/animations/animations.css'


import CompanyItem from "./item"


export default function Transaction() {


  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, page, pages, per_page, total } = useSelector(
    (state) => state.company,
  )


  useEffect(() => {
    dispatch(AccountActions.balanceRequest())
  }, [dispatch])

  useEffect(() => {
    dispatch(CompanyActions.CompanyRequest({ per_page: per_page, page: 1 }))
  }, [dispatch])

  const handlePagePress = useCallback((event, value) => {
    dispatch(CompanyActions.CompanyRequest({ per_page: per_page, page: value }))
  })

  const handleNewPress = () => {
    history.push('/crm/sub-conta/novo')
  }


  const handleInfoRightMenu = (info) => {
    history.push(`/crm/sub-conta/detalhe/${info.id}`)
  }

  return (
    <ContainerItens>
      <Container>
        <h1>Sub-contas</h1>
        <div className="horizontal">
          <p>Lista de sub-contas</p>
          <ButtonDefault
            type="button"
            title="Novo"
            background={theme.color.green}
            color={theme.color.white}
            width="250px"
            height="50px"
            onClick={handleNewPress}
          />
        </div>

        <Content>
          <div className="table">
            {!data.length && <p>nenhuma transferência realizada</p>}
            {!!data.length && (
              <>
                {data.map((item, index) => (
                  <CompanyItem item={item} index={index} handleInfoRightMenu={handleInfoRightMenu} />
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
        </Content>
      </Container>
    </ContainerItens>
  )
}
