export function invoicePayableRequest({ per_page, page }) {
  return {
    type: '@invoice/INVOICE_PAYABLE_REQUEST',
    payload: { per_page, page }
  }
}

export function invoiceReceivableRequest({ per_page, page }) {
  return {
    type: '@invoice/INVOICE_RECEIVABLE_REQUEST',
    payload: { per_page, page }
  }
}

export function invoicePayableRequestSuccess({ data, page, pages, total }) {
  return {
    type: '@invoice/INVOICE_PAYABLE_REQUEST_SUCCESS',
    payload: { data, page, pages, total }
  }
}

export function invoiceReceivableRequestSuccess({ data, page, pages, total }) {
  return {
    type: '@invoice/INVOICE_RECEIVABLE_REQUEST_SUCCESS',
    payload: { data, page, pages, total }
  }
}

export function newInvoice({
  amount,
  currency,
  document,
  description,
  expiredAt,
  callback
}) {
  return {
    type: '@invoice/NEW_INVOICE',
    payload: {
      amount,
      currency,
      document,
      description,
      expiredAt,
      callback
    }
  }
}

export function newInvoiceSuccess({ invoice }) {
  return {
    type: '@invoice/NEW_INVOICE_SUCCESS',
    payload: {
      invoice
    }
  }
}

export function invoiceDetail({ invoice }) {
  return {
    type: '@invoice/DEFINE_DETAIL',
    payload: { invoice }
  }
}

export function acceptInvoice(req) {
  return {
    type: '@invoice/ACCEPT_INVOICE',
    payload: req
  }
}

export function CancelInvoice(id) {
  return {
    type: '@invoice/CANCEL_INVOICE',
    payload: {
      id
    }
  }
}

export function invoiceDocument(document, callback) {
  return {
    type: '@invoice/INVOICE_DOCUMENT',
    payload: {
      document,
      callback
    }
  }
}

export function invoiceDocumentSuccess(data) {
  return {
    type: '@invoice/DOCUMENT_REQUEST_SUCCESS',
    payload: {
      data
    }
  }
}
