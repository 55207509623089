import styled from "styled-components";

export const Form = styled.form`
  padding: 16px 0px;

  legend {
    font-size: 16px;
  }
`

export const SelectArea = styled.div`

display: flex;
flex-direction: row;
gap: 20px;

.item {
  flex-direction: row;

  input {
    width: auto;
  }

  label {
    margin-left: 8px;
  }
}
`

export const Container = styled.div`
  background-color: #fff;
  padding: 20px 0;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 400px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  text-align: center;
  
`;

export const ContainerSaldo = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 16px;
  }
`;

export const ContainerList = styled.div`
  margin-top: 20px;
`;

export const ContainerTotal = styled.div`
  flex-flow: row nowrap;
  -webkit-flex-flow: row nowrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  height: 180px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.white2};
  color: ${({ color }) => color};

  h2 {
    font-size: 16px;
    font-weight: 100 !important;
  }

  p {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Item = styled.div`
  padding: 10px;
  background-color: ${({ color, theme }) => color || theme.gray};
  border-radius: 10px;
  padding-bottom: 15px;
  margin-top: -15px;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  h2 {
    font-size: 14px;
    font-weight: 100 !important;
    color: #fff;
  }

  p {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
`;


export const ContainerButtons = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    color: #848484;
    width: 100%;
    justify-content: space-around;
   padding: 0 10px;
}
`

export const ButtonItem = styled.div`
    flex-direction: column!important;
    padding: 5px;
    width: 25%;
    height: 50px;
    &:hover{
    -webkit-box-shadow: inset 0px 0px 5px 4px rgba(0,0,0,0.13); 
    box-shadow: inset 0px 0px 5px 4px rgba(0,0,0,0.13);
      cursor: pointer;
         color: #494949;
    }
    
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    display: flex;
}
`
