import React, { useMemo, useEffect } from "react";
import {
  Container,
  ContainerSaldo,
  ContainerTop,
  ContainerBarInfo,
  Div,
  TextMoney,
  Text,
  ContainerList,
  ContainerBarBox,
  BarBox,
  BarContent
} from "./styles";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { formatToReal } from "../../../utils/format";
import * as AccountActions from "../../../store/modules/account/actions";

import moment from "moment";
import "moment/locale/pt-br";

const HomeEntries = ({ navigation }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const balance = useSelector(state => state.account.balance);
  const inout = useSelector(state => state.account.inout);
  const months = useSelector(state => state.account.months);

  useEffect(() => {
    dispatch(AccountActions.resumeInoutRequest());
    dispatch(AccountActions.balanceRequest());
    dispatch(AccountActions.resumeMonthsRequest());
  }, []);

  const monthsMemo = useMemo(() => {
    let m = {
      in: [],
      out: [],
      balance: []
    };
    if (!months.in) {
      return months;
    }
    months.in.forEach((i, index) => {
      let highest = 0;

      if (i.amount > months.out[index].amount) {
        highest = i.amount;
      } else {
        highest = months.out[index].amount;
      }
      if (months.balance[index].amount > highest) {
        highest = months.balance[index].amount;
      }
      const d = i.month_formated.split("-");
      const shortDate = moment(`${d[2]}-${d[1]}-${d[0]}`).format("MMM");
      m.in[index] = {
        ...months.in[index],
        percent: (i.amount * 100) / highest,
        shortDate
      };
      m.out[index] = {
        ...months.out[index],
        percent: (months.out[index].amount * 100) / highest,
        shortDate
      };
      m.balance[index] = {
        ...months.balance[index],
        percent: (months.balance[index].amount * 100) / highest,
        shortDate
      };
    });
    return m;
  }, [months]);

  return (
    <Container>
      <ContainerSaldo>
        <h1>Saldo</h1>
        <TextMoney>{formatToReal(balance)}</TextMoney>
        {inout && (
          <ContainerTop>
            <Div>
              <h1>Entradas</h1>
              <TextMoney color={theme.color.green}>
                {formatToReal(inout.input)}
              </TextMoney>
            </Div>
            <Div>
              <h1>Saídas</h1>
              <TextMoney color={theme.color.red}>
                {formatToReal(inout.output)}
              </TextMoney>
            </Div>
          </ContainerTop>
        )}
      </ContainerSaldo>
      <ContainerList>
        {monthsMemo?.in?.map((item, index) => (
          <ContainerBarInfo key={index}>
            <ContainerBarBox>
              <BarBox>
                <BarContent
                  color={theme.color.red}
                  height={`${monthsMemo.out[index].percent || "0"}%`}
                ></BarContent>
              </BarBox>
              <BarBox>
                <BarContent
                  color={theme.color.green}
                  height={`${monthsMemo.in[index].percent || "0"}%`}
                ></BarContent>
              </BarBox>
              <BarBox>
                <BarContent
                  color={theme.color.blackButton}
                  height={`${monthsMemo.balance[index].percent || "0"}%`}
                ></BarContent>
              </BarBox>
            </ContainerBarBox>
            <Text>{monthsMemo.in[index].shortDate}</Text>
          </ContainerBarInfo>
        ))}
      </ContainerList>
    </Container>
  );
};

export default HomeEntries;
