import React, { useState, useEffect, CSSProperties } from "react";
import { useHistory } from "react-router-dom";
import { Container, ContainerPage, ContainerPagination } from "./styles";
import { ButtonDefault } from "../../Buttons";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as PayBillActions from "../../../store/modules/paybill/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import { InputMask } from "../../Inputs";
import { cpfIsValid } from "../../../utils/check";
import Carousel from 'react-elastic-carousel'
import apiCep from "../../../services/apiCEP.js";
import * as GeneralActions from "../../../store/modules/general/actions";

import DisplayInfos from "../../DisplayInfo"

const renderPagination = ({ pages, activePage, onClick }, props, handleNextPage, success) => {
  return (
    <ContainerPagination>
      {pages.map(page => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${success ? 'success' : ''}`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props)

            }}
          />
        );


      })}
    </ContainerPagination>
  )
}

const PayBillValidSchema = Yup.object().shape({
  digitable_line: Yup.string().required("campo obrigatório"),

})

const initialValues = {
  digitable_line: ""
};

const initialErrors = {
  digitable_line: "-"
};

const diplayValues = [
  {
    key: "digitable_line",
    name: "Código de boleto",
    value: "",
    position: "row",
    type: "string"
  },
  {
    key: "settleDate",
    name: "Emissão",
    value: "",
    position: "column",
    type: "date"
  },
  {
    key: "dueDate",
    name: "Vencimento",
    value: "",
    position: "column",
    type: "date"
  },
  {
    key: "issuer_name",
    name: "Cedente",
    value: "",
    position: "row",
    type: "string"
  },
  {
    key: "payer_name",
    name: "Favorecido",
    value: "",
    position: "row",
    type: "string"
  },
  {
    key: "amount",
    name: "Valor",
    value: "",
    position: "row",
    type: "money"
  },
];

const PayBillCreate = ({ time }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [position, setPosition] = useState(0);

  var carousel = null

  const [success, setSuccess] = useState(false);

  const { paybillCheck, checked } = useSelector(
    (state) => state.paybill
  );


  useEffect(() => {

    setPosition(0)

  }, [time]);


  var listRef = null

  const handleCancel = () => {
    history.push("/financeiro/movimentacao");
  };

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }
  const checkBoleto = (props) => {
    const { digitable_line } = props.values
    props.validateForm().then(validation => props.setTouched(validation))
    PayBillValidSchema
      .isValid({ digitable_line })
      .then((valid) => {
        console.log(valid)
        if (valid) {
          dispatch(
            PayBillActions.payBillCheckRequest({
              ...{ digitable_line },
              callback: () => {

              }
            })
          );

          return
        }
        dispatch(GeneralActions.setError({ error: "Dados inválidos" }));
      })
  };

  const create = (props) => {
    const { digitable_line } = props.values
    props.validateForm().then(validation => props.setTouched(validation))
    PayBillValidSchema
      .isValid({ digitable_line })
      .then((valid) => {
        if (valid) {
          dispatch(
            PayBillActions.newPayBill({
              ...{ digitable_line },
              callback: () => {
                setSuccess(true);
              }
            })
          );

          return
        }
        dispatch(GeneralActions.setError({ error: "Dados inválidos" }));
      })
  };

  const handleSubmitForm = (values) => {
    const document = values.document.replace(/[\.^\-^\/]/gi, "");
    dispatch(
      PayBillActions.newPayBill({
        ...values,
        document,
        callback: () => {
          setSuccess(true);
        }
      })
    );
  };

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">
             Pagamento agendado
               </div>
          </div>
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={PayBillValidSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}

      >
        {(props) => (
          <>
            {(!checked) ? (
              <>
                <ContainerPage>
                  <div className="field digitable">
                    <input
                      type="text"
                      name="digitable_line"
                      placeholder="Código do boleto"
                      value={props.values.digitable_line}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></input>
                    <span>{props.touched.digitable_line && props.errors.digitable_line}</span>
                  </div>
                </ContainerPage>
                <div className="container-buttons">
                  <div className="horizontal">
                    <ButtonDefault
                      title="Cancelar"
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      width="170px;"
                      onClick={handleCancel}
                    />
                    <ButtonDefault
                      title="Próximo"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="170px;"
                      onClick={() => checkBoleto(props)}
                    />

                  </div>
                </div>
              </>
            ) : (
                <>
                  <ContainerPage>
                    <DisplayInfos items={diplayValues.map(item => {
                      item.value = paybillCheck[item.key]
                      return item;
                    })} />
                  </ContainerPage>
                  <div className="container-buttons">
                    <div className="horizontal">
                      <ButtonDefault
                        title="Cancelar"
                        background={theme.color.white}
                        color={theme.color.blackButton}
                        width="170px;"
                        onClick={handleCancel}
                      />
                      <ButtonDefault
                        title="Próximo"
                        background={theme.color.blackButton}
                        color={theme.color.white}
                        width="170px;"
                        onClick={() => create(props)}
                      />

                    </div>
                  </div>
                </>
              )}
          </>
        )}
      </Formik>
    </Container>
  );
};

export default PayBillCreate;
