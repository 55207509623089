import styled, { keyframes } from "styled-components";

import colors from "../../../styles/colors";

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 900px;
  padding: 20px;
  animation: ${animationName} 0.8s;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${colors.grayText};
  }
  p {
    font-weight: 100;
  }
  .progress {
    display: block;
    width: 100%;
    height: 12px;
    border-radius: 12px;
    border: #BEBEBE solid 1px;
    .inner{
      width: 7%;
      display: block;
      height: 11px;
      background: #07EA93;
      border-radius: 12px;
    }
    .title {
      position: absolute;
      top: -2px;
      left: 43%;
      font-size: 10px;
  }

  }
`;

export const FeeBillingItem = styled.div`
  background-color: white;
  flex-direction: row;
  margin-bottom: 8px;
  height: 120px;

  .bar-container {
    position: relative;
    width: 10px;
    flex: 0.3;
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
    }
  }

  .info {
    flex: 0.5;
    justify-content: center;
    .name {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }
     .icon {
      font-size: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .info-2 {
    justify-content: center;
    flex: 1;
    text-align: center;
    padding: 5px 10px 5px 5px;
    flex-direction: column;

    .title {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }

    .subtitle {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      /* text-transform: uppercase; */
      color: #797878;
    }
  }

  .action {
    flex: 0.5;
    justify-content: center;
    align-items: center;
    .icon-2 {
      font-size: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`
export const OuterBox = styled.div`
  display: flex;
  flex-direction: row;
`

export const ContainerBox = styled.div`
  padding: 20px;
  background-color: #fff;
  min-height: 381px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  max-width: 410px;
  width: 100%;
  span {
    font-size: 10px;
    position: absolute;
    bottom: -15px;
  }
  input {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
    :focus {
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
    }
  }
  .fieldNoMargin {
    min-width: 40%;
    padding: 0px !important;
  }
  .horizontal {
    width: 100%;
    margin-bottom: 0px !important;
    align-items: flex-end !important;
    justify-content: space-between;
  }
  .horizontal-flex-start {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    input {
      margin: 0px !important;
      padding: 0px;
    }
    label {
      width: 100%;
      padding-left: 20px;
      padding-bottom: 3px;
    }
  }
  .container-buttons {
    margin-top: 20px;
  }

  .container-success {
    flex: 1;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 20px;
      text-align: center;
      margin-top: 20%;
    }
    button {
      margin: 0px;
    }
  }
  .rec.rec-swipable-null {
    justify-content: center;
  }
  .container-icon-success {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    & .content {
      background: #16bf57;
      width: 95px;
      height: 95px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      i {
        color: white;
      }
    }
    & .title {
      font-size: 32px;
      text-align: center;
      margin-top: 20px;
    }
  }
`
export const ContainerPage = styled.div`
  label {
    padding: 10px 20px;
    color: #707070;
  }

  .radio-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100px;
    left: 10px;
  }

  .period-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 376px;
    left: 10px;
  }
`

export const ContainerPagination = styled.div`
  display: block;
  max-width: 410px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
  & .dot {
    width: 29px;
    height: 29px;
    margin: 10px;
    border: #707070 solid 1px;
    border-radius: 20px;
    &.selected {
      background: #000000;
    }
  }
`
export const ContainerButtons = styled.div`
  margin-top: 20px;
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
export const ContainerProgressBar = styled.div`
  padding: 20px;
  background-color: #ffffff;
  min-height: 381px;
  width: 100%;
`
export const ContainerPageSelect = styled.div`
  min-height: 400px;

  .css-1pcexqc-container {
    position: relative;
  }

  .css-1jllj6i-control {
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  
  .css-1g6gooi {
    margin: 0 24px;
   }

 .css-1pcexqc-container {
  width: 431px;
 }

 .css-1qprcsu-option {
  input {
    width: auto;
  }
 }

`