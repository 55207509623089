import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../../components/Buttons";
import { Container } from "./styles";
import { formatToReal } from "../../../utils/format";
import * as PixActions from "../../../store/modules/pix/actions";
import Paginator from "../../../components/Paginator";
import { setOnClipboard } from "../../../utils/clipboard";
import * as GeneralActions from "../../../store/modules/general/actions";

export default function Transfer() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pixes, page, pages, total } = useSelector((state) => state.pix);

  useEffect(() => {
    dispatch(PixActions.listRequest({ per_page: 3, page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    console.log(pixes);
  }, [pixes]);
  const handlePagePress = useCallback((page) => {
    dispatch(PixActions.listRequest({ per_page: 3, page }));
  });

  const handleNewPress = () => {
    history.push("/financeiro/pix/novo");
  };

  const copy = (b) => {
    dispatch(GeneralActions.setMessage({ message: "chave copiado" }));
    setOnClipboard(b.value);
  };

  return (
    <Container>
      <h1>Pix</h1>
      <div className="horizontal">
        <p>Lista de chaves</p>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      {!pixes.length && <p>nenhuma chave cadastrada</p>}
      {!!pixes.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Label</th>
                <th>Tipo</th>
                <th>Valor</th>
                <th>Açoes</th>
              </tr>
            </thead>
            <tbody>
              {pixes.map((b) => (
                <tr key={b.id}>
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        backgroundColor: theme.color[b.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {b.id}
                  </td>
                  <td>{b.label}</td>
                  <td>{b.type}</td>
                  <td>{b.value}</td>
                  <td className="actions">
                    {/* <div className="button">
                      <i className="material-icons">warning</i>
                    </div> */}
                    <div className="button" onClick={() => copy(b)}>
                      <i className="material-icons">content_copy</i>
                    </div>
                    {/* <div className="button" title="cancelar">
                      <i className="material-icons">delete</i>
                    </div> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            page={page}
            pages={pages}
            total={total}
            callback={handlePagePress}
          />
        </>
      )}
    </Container>
  );
}
