import styled, { keyframes } from "styled-components";

import colors from "../../styles/colors";

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 900px;
  padding: 20px;
  animation: ${animationName} 0.8s;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${colors.grayText};
  }
  p {
    font-weight: 100;
  }
`;

export const SelectContainer = styled.div`
  display: inline-block;
`;

export const SelectContent = styled.div`
  &.hide {
    display: none;
  }
`;
export const SelectArrow = styled.div``;
export const Selected = styled.div`
  font-size: 23px;
  font-family: "Roboto-Bold", sans-serif;
  color: ${colors.grayText};
  display: inline-block;
`;

export const SelectItem = styled.div`
  font-size: 23px;
  font-family: "Roboto-Bold", sans-serif;
  color: ${colors.grayText};
`;

export const ContainerItem = styled.div`
  margin: 29px 0;
  display: flex;
  flex-direction: row;
`;

export const Item = styled.div`
  justify-content: center;
  align-items: center;
  width: 184px;
  text-align: center;
  border-radius: 18px;
  background: white;
  padding: 20px 0 57px 0;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  .title {
    font-family: "Roboto-Bold", sans-serif;
    color: #747474;
    font-size: 15px;
    margin-bottom: 20px;
  }
  i {
    color: #747474;
    font-size: 40px;
  }
  &:hover {
    cursor: pointer;
  }
  margin: 0 16px;
`;
