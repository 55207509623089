import React from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { CardTransferCreate } from "../../../../components/Cards";
import { Container } from "./styles";

export default function NewTransfer() {
  const history = useHistory();

  return (
    <Container>
      <h1>Transferência</h1>
      <p>Voce pode realizar um TED/PIX</p>
      <div className="horizontal"></div>
      <CardTransferCreate />
    </Container>
  );
}
