import { all, takeLatest, put, call, select } from "redux-saga/effects";
import apiPayment from "../../../services/apiPayment";
import * as GeneralActions from "../general/actions";
import {
  requestBalanceSucesss,
  resumeRequestSucesss,
  resumeInoutSuccess,
  resumeMonthsSuccess,
  ListLimitsSucesss,
  GetAccountSuccess,
} from "./actions";
import * as PixActions from "../pix/actions";
import GetErrors from "../../../services/errors";

export function* getBalance() {
  try {
    const { data } = yield call(apiPayment.get, "/payment/partner/v1/web/balance");
    yield put(requestBalanceSucesss(data));
  } catch (error) {

    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* resumeTransactions() {
  try {
    const response = yield call(apiPayment.get, "/payment/partner/v1/web/transactions/resume");
    const data = response.data;
    yield put(resumeRequestSucesss(data));
  } catch (error) {

    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* resumeInoutRequest() {
  try {
    const response = yield call(apiPayment.get, "/payment/partner/v1/web/transactions/resume/inout");
    const data = response.data;
    yield put(resumeInoutSuccess(data));
  } catch (error) {

    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* GetAccount() {
  try {
    const response = yield call(apiPayment.get, "/payment/partner/v1/web/account");
    const data = response.data;
    yield put(GetAccountSuccess(data));
  } catch (error) {

    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}



export function* GetAccountInternal(action) {
  try {
    let { callback, document } = action.payload;
    yield put(GeneralActions.setLoading({ loading: true }));
    document = document.replace(/[^A-Z0-9]+/ig, "");
    const { data } = yield call(apiPayment.get, `/payment/partner/v1/web/account/document/${document}`);
    if (callback) {
      callback(data);
    }
  } catch (error) {
  console.log(error)
    let code = "0";
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code;
    }
    const errorMessage = GetErrors("PT_BR", code);
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}


export function* ListLimits() {
  try {
    const response = yield call(apiPayment.get, "/payment/partner/v1/web/transaction/limits");
    const data = response.data;
    yield put(ListLimitsSucesss(data));
  } catch (error) {

    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export function* resumeMonthsRequest() {
  try {
    const response = yield call(apiPayment.get, "/payment/partner/v1/web/transactions/resume/months");
    const data = response.data;
    yield put(resumeMonthsSuccess(data));
  } catch (error) {

    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
  }
}

export default all([
  takeLatest("@account/BALANCE_REQUEST", getBalance),
  takeLatest("@account/GET_INTERNAL_ACCOUNT", GetAccountInternal),
  takeLatest("@account/RESUME_REQUEST", resumeTransactions),
  takeLatest("@account/RESUME_IN_OUT_REQUEST", resumeInoutRequest),
  takeLatest("@account/RESUME_MONTHS_REQUEST", resumeMonthsRequest),
  takeLatest("@account/LIST_LIMIT", ListLimits),
  takeLatest("@account/GET_ACCOUNT", GetAccount),
]);
