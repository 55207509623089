import produce from "immer";

const INITIAL_STATE = {};

export default function register(state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}
