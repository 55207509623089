import produce from 'immer'



const defaultWallet = {
  balance: '0',
  currency: '',
  totalInReal: 0,
}

const defaultLedgerSate = {
  data: [],
  page: 0,
  total: 0,
  pages: 0,
  per_page: 5,
}

const defaultCryptoDepositSate = {
  list: [],
  page: 0,
  total: 0,
  pages: 0,
  per_page: 5,
}

const defaultCryptoWithdrawSate = {
  list: [],
  page: 0,
  total: 0,
  pages: 0,
  per_page: 5,
}

const defaultOrder = {
  list: [],
  page: 0,
  total: 0,
  pages: 0,
  per_page: 5,
}

const initialState = {
  wallets: [],
  per_page: 0,
  page: 0,
  total: 0,
  pages: 0,
  totalInReal: 0,
  totalUSD: 0,
  currencies: [],
  wallet: defaultWallet,
  ledgers: defaultLedgerSate,
  orders: defaultOrder,
  crypto_deposit_state: defaultCryptoDepositSate,
  crypto_withdraw_state: defaultCryptoWithdrawSate,
  networks: [],
  address: {
    currency: '',
    address: '',
  },
}
export default function state(state = initialState, action) {
  switch (action.type) {
    case "@crypto/WALLET_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        // console.log(action.payload.data)
        draft.wallets = action.payload.data
        draft.per_page = action.payload.per_page
        draft.page = action.payload.page
      })
    case "@crypto/ORDER_LIST_SUCCESS":
      return produce(state, (draft) => {
        draft.orders.list = action.payload.data
        draft.orders.page = action.payload.page
        draft.orders.total = action.payload.total
        draft.orders.pages = action.payload.pages
      })
    case "@crypto/CRYPTO_DEPOSIT_LIST_SUCCESS":
      return produce(state, (draft) => {

        draft.crypto_deposit_state.list = action.payload.data
        draft.crypto_deposit_state.page = action.payload.page
        draft.crypto_deposit_state.total = action.payload.total
        draft.crypto_deposit_state.pages = action.payload.pages
      })
    case "@crypto/CRYPTO_WITHDRAW_LIST_SUCCESS":
      return produce(state, (draft) => {
        draft.crypto_withdraw_state.list = action.payload.data
        draft.crypto_withdraw_state.page = action.payload.page
        draft.crypto_withdraw_state.total = action.payload.total
        draft.crypto_withdraw_state.pages = action.payload.pages
      })
    case "@crypto/LEDGERS_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.ledgers.data = action.payload.data
        draft.ledgers.page = action.payload.page
        draft.ledgers.total = action.payload.total
        draft.ledgers.pages = action.payload.pages
      })
    case "@crypto/WALLET_SELECT_SUCCESS":
      return produce(state, (draft) => {
        draft.wallet = action.payload.wallet
      })
    case "@crypto/CURRENCY_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.currencies = action.payload.data
      })
    case "@crypto/BUY_CRYPTO_SUCCESS":
      return produce(state, (draft) => {
        draft.order = action.payload.order
      })
    // case "@crypto/DELETE_CRYPTO_SUCCESS":
    //   return produce(state, (draft) => {
    //     draft.order = action.payload.order
    //   })
    case "@crypto/NETWORK_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.networks = action.payload.data
      })
    case "@crypto/CREATE_ADDRESS_SUCCESS":
      return produce(state, (draft) => {
        draft.address = action.payload;
      });

    default:
      return state
  }
}
