import produce from 'immer'

const INITIAL_STATE = {
  invoice: {},
  data_payable: [],
  page: 1,
  pages: 1,
  total: 0,
  page_payable: 1,
  pages_payable: 1,
  total_payable: 0,
  data_receivable: [],
  page_receivable: 1,
  pages_receivable: 1,
  total_receivable: 0,
  successPaid: false,
  client: {}
}

export default function Invoice(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@invoice/INVOICE_PAYABLE_REQUEST_SUCCESS':
      return produce(state, draft => {
        draft.data_payable = action.payload.data
        draft.page_payable = action.payload.page
        draft.pages_payable = action.payload.pages
        draft.total_payable = action.payload.total
      })
    case '@invoice/INVOICE_RECEIVABLE_REQUEST_SUCCESS':
      return produce(state, draft => {
        draft.data_receivable = action.payload.data
        draft.page_receivable = action.payload.page
        draft.pages_receivable = action.payload.pages
        draft.total_receivable = action.payload.total
      })
    // case "@invoice/INVOICE_DOCUMENT":
    //   return produce(state, (draft) => {
    //     draft.document = action.payload.document;
    //   });
    case '@invoice/DOCUMENT_REQUEST_SUCCESS':
      return produce(state, draft => {
        draft.client = action.payload.data
      })
    case '@invoice/DEFINE_DETAIL':
      return produce(state, draft => {
        draft.invoice = action.payload.invoice
      })
    default:
      return state
  }
}
