import React, { useState } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Container,
  Page,
  ContainerInputs,
  ContainerHorizontal,
  SmallLabel,
  Label,
  ButtonClose,
} from "../styles";

import Thumb from "./previewThumb";
import { HeaderBackImage } from "../../../components/Headers";
import { ButtonDefault } from "../../../components/Buttons";
import { registerCompanyDocument } from "../../../store/modules/register/actions";

const initialValues = {
  company_document: [],
  qsa_document: [],
  yield_document: [],
  social_contract_document: [],
};

const initialErrors = {
  company_document: "-",
  qsa_document: "-",
  yield_document: "-",
  social_contract_document: "-",
};

const DocumentSchema = Yup.object().shape({
  company_document: Yup.array().of(Yup.string()),
  qsa_document: Yup.array().of(Yup.string()),
  yield_document: Yup.array().of(Yup.string()),
  social_contract_document: Yup.array().of(Yup.string()),
});

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function SignIn() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(-1);

  async function submitForm(values) {
    const company_document = await Promise.all(
      values.company_document.map(
        async (v) =>
          await toBase64(v).then((v) =>
            v.replace(/image\/jpeg|image\/pdf/gi, "pdf")
          )
      )
    );
    const qsa_document = await Promise.all(
      values.qsa_document.map(
        async (v) =>
          await toBase64(v).then((v) =>
            v.replace(/image\/jpeg|image\/pdf/gi, "pdf")
          )
      )
    );
    const yield_document = await Promise.all(
      values.yield_document.map(
        async (v) =>
          await toBase64(v).then((v) =>
            v.replace(/image\/jpeg|image\/pdf/gi, "pdf")
          )
      )
    );
    const social_contract_document = await Promise.all(
      values.social_contract_document.map(
        async (v) =>
          await toBase64(v).then((v) =>
            v.replace(/image\/jpeg|image\/pdf/gi, "pdf")
          )
      )
    );
    dispatch(
      registerCompanyDocument({
        company_document: company_document[0],
        qsa_document: qsa_document[0],
        social_contract_document: social_contract_document[0],
        yield_document: yield_document[0],
      })
    );
  }

  const handleToSet = (value) => {
    setCurrent(value);
  };

  const validInput = ({ input, props }) => {
    if (props.errors[input]) {
      return false;
    } else {
      return true;
    }
  };

  const openSelectImage = async ({ event, index, inputName, props }) => {
    props.setFieldValue(`${inputName}[${index}]`, event.currentTarget.files[0]);
    event.target.value = "";
  };

  const handleRemoveImage = ({ name, index, props }) => {
    const newValues = props.values[name].filter((v, i) => i != index);
    props.setFieldValue(name, newValues);
  };

  return (
    <Container>
      <section className="container-small">
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          initialErrors={initialErrors}
          initialTouched={initialValues}
          validationSchema={DocumentSchema}
        >
          {(props) => (
            <>
              {/* MESSAGE [-1] */}
              {current === -1 && (
                <Page>
                  <HeaderBackImage onClick={() => history.goBack()} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      Quase lá, agora cadastraremos os{" "}
                      <strong>documentos da empresa</strong>
                    </label>
                  </ContainerInputs>
                  <ButtonDefault
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Próximo"
                    onClick={() => handleToSet(0)}
                  />
                </Page>
              )}

              {/* documentos do usuário [0] */}
              {current === 0 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Documentos da empresa.</strong>
                      <br></br>
                      <p>Cartão CNPJ</p>
                    </label>
                    <ContainerHorizontal style={{ flexDirection: "row" }}>
                      {props.values.company_document.length == 0 ? (
                        <>
                          <SmallLabel
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            htmlFor={`default_file_0`}
                            className="label-file label-open"
                          >
                            <i className="material-icons">
                              photo_size_select_small
                            </i>
                          </SmallLabel>
                          <input
                            id={`default_file_0`}
                            type="file"
                            accept=".pdf"
                            autoCapitalize="none"
                            placeholder=""
                            onChange={(event) =>
                              openSelectImage({
                                event,
                                inputName: "company_document",
                                index: props.values.company_document.length,
                                props,
                              })
                            }
                          />
                        </>
                      ) : (
                        <div key={0}>
                          <Label style={{ cursor: "initial" }}>
                            <ButtonClose
                              onClick={() =>
                                handleRemoveImage({
                                  name: "company_document",
                                  index: 0,
                                  props,
                                })
                              }
                            >
                              <i className="material-icons">clear</i>
                            </ButtonClose>
                            <i
                              className="material-icons"
                              style={{ fontSize: "100px" }}
                            >
                              picture_as_pdf
                            </i>
                          </Label>
                        </div>
                      )}
                    </ContainerHorizontal>
                    <span>
                      {props.touched.company_document &&
                        props.errors.company_document}
                    </span>
                  </ContainerInputs>
                  {props.values.company_document.length ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(1)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(1)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(-1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* documentos da companhia qsa_document [1] */}
              {current === 1 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Documentos da empresa.</strong>
                      <br></br>
                      <p>Consulta Quadro de Sócios e Administradores - QSA.</p>
                    </label>
                    <ContainerHorizontal style={{ flexDirection: "row" }}>
                      {props.values.qsa_document.length == 0 ? (
                        <>
                          <SmallLabel
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            htmlFor={`default_file_1`}
                            className="label-file label-open"
                          >
                            <i className="material-icons">
                              photo_size_select_small
                            </i>
                          </SmallLabel>
                          <input
                            id={`default_file_1`}
                            type="file"
                            accept=".pdf"
                            autoCapitalize="none"
                            placeholder=""
                            onChange={(event) =>
                              openSelectImage({
                                event,
                                inputName: "qsa_document",
                                index: props.values.qsa_document.length,
                                props,
                              })
                            }
                          />
                        </>
                      ) : (
                        <div key={0}>
                          <Label style={{ cursor: "initial" }}>
                            <ButtonClose
                              onClick={() =>
                                handleRemoveImage({
                                  name: "qsa_document",
                                  index: 0,
                                  props,
                                })
                              }
                            >
                              <i className="material-icons">clear</i>
                            </ButtonClose>
                            <i
                              className="material-icons"
                              style={{ fontSize: "100px" }}
                            >
                              picture_as_pdf
                            </i>
                          </Label>
                        </div>
                      )}
                    </ContainerHorizontal>
                    <span>
                      {props.touched.qsa_document && props.errors.qsa_document}
                    </span>
                  </ContainerInputs>
                  {props.values.qsa_document.length ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(0)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* COMPROVANTE DE RESIDENCIA yield_document [2] */}
              {current === 2 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Documentos da empresa.</strong>
                      <br></br>
                      <p>
                        Último Informe de Rendimentos e/ou Balanço Social/DRE
                        e/ou Declaração de Faturamento últimos 12 meses.
                      </p>
                    </label>
                    <ContainerHorizontal style={{ flexDirection: "row" }}>
                      {props.values.yield_document.length == 0 ? (
                        <>
                          <SmallLabel
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            htmlFor={`default_file_2`}
                            className="label-file label-open"
                          >
                            <i className="material-icons">
                              photo_size_select_small
                            </i>
                          </SmallLabel>
                          <input
                            id={`default_file_2`}
                            type="file"
                            accept=".pdf"
                            autoCapitalize="none"
                            placeholder=""
                            onChange={(event) =>
                              openSelectImage({
                                event,
                                inputName: "yield_document",
                                index: props.values.yield_document.length,
                                props,
                              })
                            }
                          />
                        </>
                      ) : (
                        <div key={0}>
                          <Label style={{ cursor: "initial" }}>
                            <ButtonClose
                              onClick={() =>
                                handleRemoveImage({
                                  name: "yield_document",
                                  index: 0,
                                  props,
                                })
                              }
                            >
                              <i className="material-icons">clear</i>
                            </ButtonClose>
                            <i
                              className="material-icons"
                              style={{ fontSize: "100px" }}
                            >
                              picture_as_pdf
                            </i>
                          </Label>
                        </div>
                      )}
                    </ContainerHorizontal>
                    <span>
                      {props.touched.yield_document &&
                        props.errors.yield_document}
                    </span>
                  </ContainerInputs>
                  {props.values.yield_document.length ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(3)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* COMPROVANTE DE RESIDENCIA social_contract_document [3] */}
              {current === 3 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Documentos da empresa.</strong>
                      <br></br>
                      <p> Contrato Social e última Alteração Contratual</p>
                    </label>
                    <ContainerHorizontal style={{ flexDirection: "row" }}>
                      {props.values.social_contract_document.length == 0 ? (
                        <>
                          <SmallLabel
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            htmlFor={`default_file_2`}
                            className="label-file label-open"
                          >
                            <i className="material-icons">
                              photo_size_select_small
                            </i>
                          </SmallLabel>
                          <input
                            id={`default_file_2`}
                            type="file"
                            accept=".pdf"
                            autoCapitalize="none"
                            placeholder=""
                            onChange={(event) =>
                              openSelectImage({
                                event,
                                inputName: "social_contract_document",
                                index:
                                  props.values.social_contract_document.length,
                                props,
                              })
                            }
                          />
                        </>
                      ) : (
                        <div key={0}>
                          <Label style={{ cursor: "initial" }}>
                            <ButtonClose
                              onClick={() =>
                                handleRemoveImage({
                                  name: "social_contract_document",
                                  index: 0,
                                  props,
                                })
                              }
                            >
                              <i className="material-icons">clear</i>
                            </ButtonClose>
                            <i
                              className="material-icons"
                              style={{ fontSize: "100px" }}
                            >
                              picture_as_pdf
                            </i>
                          </Label>
                        </div>
                      )}
                    </ContainerHorizontal>
                    <span>
                      {props.touched.social_contract_document &&
                        props.errors.social_contract_document}
                    </span>
                  </ContainerInputs>
                  {props.values.social_contract_document.length ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(4)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(4)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* CHECK [4] */}
              {current === 4 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Agora enviaremos tudo.</strong>
                      <br />
                      Após o envio, seu cadastro irá para análise. Sendo
                      aprovado você receberá um email.
                    </label>
                  </ContainerInputs>
                  <ButtonDefault
                    type="submit"
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Confirmar"
                    onClick={props.handleSubmit}
                  />
                </Page>
              )}
            </>
          )}
        </Formik>
      </section>
    </Container>
  );
}
